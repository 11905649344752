import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(phoneNumber) {
    if (phoneNumber) {
      const cleanNum = phoneNumber
        .toString(2)
        .replace(/\+/g, '')
        .replace(/\s/g, '')
        .split('-')
        .join('');
      var formattedNum = cleanNum.match(/.{1,2}/g).join(' ');
      return formattedNum;
    }
  }
}
