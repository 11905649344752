import {PageMetaResolver} from "@spartacus/core";
import {CustomLoginBreadcrumbsResolver} from "./custom-login-breadcrumbs.resolver";

export const CustomPageMetaProviders = [

  {
    provide: PageMetaResolver,
    useExisting: CustomLoginBreadcrumbsResolver,
    multi: true,
  },
]
