<!--details sectuion-->
<section class="container payMethod-Container" *ngIf="urlPath==='reservation-overview'">

  <section class="row">
    <section class="col-md-12 col-lg-12 col-xl-12">
      <span class="payment-header">{{'orderConfirmation.details'|cxTranslate}}</span>
    </section>
    <section class="col-6 col-md-6 ">
      <section class="col-md-12 details-Labels">
        <span class="billing-Addr-Header">{{'orderConfirmation.purchaseOrderNumber'|cxTranslate}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="">{{purchaseOrderNumber}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="billing-Addr-Header">{{'orderConfirmation.orderDate'|cxTranslate}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="">{{orderDate | slice:0:10 | date : 'dd/MM/yyyy' }}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="billing-Addr-Header">{{'orderConfirmation.status'|cxTranslate}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="">{{status}}</span>
      </section>
    </section>

    <section class="col-6 col-md-6">
      <section class="container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 ship-To">
            <span class="billing-Addr-Header">{{'orderConfirmation.shipTo'|cxTranslate}}</span>
          </section>
          <!-- <section class="col-12 col-md-12 col-lg-12 col-xl-12">
            <span>{{ "orderConfirmation.accountNo" | cxTranslate }}:
              {{ selectedData?.unitID ?
                selectedData?.unitID : "" }}</span>
          </section> -->
          <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
            <span class="">{{selectedData?.companyName ? selectedData?.companyName : '' }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>
              {{ selectedData?.line2 ? selectedData?.line2 != "000000" ? selectedData?.line2 : "" : "" }}
              {{ selectedData?.line1 ? selectedData?.line1 : "" }}
            </span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>
              {{ selectedData?.postalCode ? selectedData.postalCode : "" }}
              {{ selectedData?.town ? selectedData.town : "" }}
              <!--              {{ selectedData?.region?.isocodeShort ? selectedData?.region?.isocodeShort : "" }}-->
            </span>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
<!--details sectuion end-->
<!--patment end-->
<section class="container">
  <section class="row">
    <section class="col-md-12 col-lg-12 col-xl-12">
      <section class="col-12 col-sm-12 col-md-12 payment-header">
        {{'orderConfirmation.reservationContact'|cxTranslate}}
      </section>
      <section class="col-md-12 col-lg-12 col-xl-12 reservation-Msg-Div">
        <span class="reservation-Msg">{{'selectVaccines.contactMsg'|cxTranslate}}
        </span>
      </section>
    </section>
  </section>
  <!--contact details-->
  <section class="row contact-Main-Row">
    <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 reseName-Div">
      <span class="rese-Name">{{'selectVaccines.name'|cxTranslate}}</span>
    </section>
    <section class="
          col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8
          reseName-Value-Div
        ">
      <span class="" *ngIf="contactAddress?.firstName">{{contactAddress?.firstName | titlecase}}
        {{contactAddress?.lastName ?
        (contactAddress?.lastName | titlecase) : ''}} </span>
    </section>
    <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 reseEmail-Div">
      <span class="rese-Email">{{'orderConfirmation.email'|cxTranslate}}</span>
    </section>
    <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 reseEmail-Value-Div">
      <a href="{{'mailto:'+(contactAddress?.email)}}" class="email-Value">{{
        contactAddress?.email}}</a>
    </section>
    <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resName-Div">
      <span class="res-Name" *ngIf="placedByAsm else NA">{{
        "selectVaccines.asmPlacedBy" | cxTranslate
        }}</span>
      <ng-template #NA>
        <span class="res-Name">{{
          "selectVaccines.placedBy" | cxTranslate
          }}</span>
      </ng-template>
    </section>
    <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resName-Value-Div">
      <span class="">{{userName}}</span> <br>
      <span class="" *ngIf="placedByAsm">({{
        "selectVaccines.asmBy" | cxTranslate
        }} {{placedByAsm}})</span>
    </section>
  </section>
  <!--address-->
  <section class="row addr-Div">
    <section class="col-md-6 col-lg-12 col-xl-6 billing-Div">
      <section class="container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 billing-Addr-Div">
            <span class="billing-Addr-Header">{{'addToCartItems.billingAddress'|cxTranslate}}</span>
          </section>
          <!-- <section class="col-12 col-md-12 col-lg-12 col-xl-12">
            <span>{{ "orderConfirmation.accountNo" | cxTranslate }}:
              {{ billingAddress?.unitID ?
              billingAddress?.unitID : "" }}</span>
          </section> -->
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{billingAddress?.companyName ? billingAddress?.companyName : '' }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>
              {{ billingAddress?.line2 ? billingAddress?.line2 != "000000" ? billingAddress?.line2 : "" : "" }}
              {{ billingAddress?.line1 ? billingAddress?.line1 : "" }}
            </span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>
              {{ billingAddress?.postalCode ? billingAddress.postalCode : "" }}
              {{ billingAddress?.town ? billingAddress.town : "" }}
              <!--              {{ billingAddress?.region?.isocodeShort ? billingAddress?.region?.isocodeShort : "" }}-->
            </span>
          </section>
        </section>
      </section>
    </section>
    <section class="col-md-6 col-lg-12 col-xl-6 billing-Div">
      <section class="container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 sold-Addr-Div">
            <span class="sold-Addr-Header">{{'orderConfirmation.soldTo'|cxTranslate}}</span>
          </section>
          <!-- <section class="col-12 col-md-12 col-lg-12 col-xl-12">
            <span>{{ "orderConfirmation.accountNo" | cxTranslate }}:
              {{ soldToAddress?.unitID ?
              soldToAddress?.unitID : "" }}</span>
          </section> -->
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{soldToAddress?.companyName ? soldToAddress?.companyName : '' }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>
              {{ soldToAddress?.line2 ? soldToAddress?.line2 != "000000" ? soldToAddress?.line2 : "" : "" }}
              {{ soldToAddress?.line1 ? soldToAddress?.line1 : "" }}
            </span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>
              {{ soldToAddress?.postalCode ? soldToAddress.postalCode : "" }}
              {{ soldToAddress?.town ? soldToAddress.town : "" }}
              <!--              {{ soldToAddress?.region?.isocodeShort ? soldToAddress?.region?.isocodeShort : "" }}-->
            </span>
          </section>
        </section>
      </section>
    </section>
    <section class="col-md-12 manage-Bill-Div">
      <a target="_blank" (click)="billingAccount()">
        <span class="manage-Bill-info">{{'orderConfirmation.manageBillInfo'|cxTranslate}}</span>
      </a>
    </section>
  </section>
</section>