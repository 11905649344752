import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-confirmation-information',
  templateUrl: './account-confirmation-information.component.html',
  styleUrls: ['./account-confirmation-information.component.scss'],
})
export class AccountConfirmationInformationComponent implements OnInit {
  accountData: any;
  compName = 'AccountRegistrationConfirmationBannerComponent';
  creditInvoice: string;
  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.creditInvoice = 'invoiceTable';
    this.accountData = JSON.parse(
      localStorage.getItem('accountConfirmationData')
    );
    this.cdr.detectChanges();
  }
}
