import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as config from '../../config/app.config';
import { BaseService } from '../../services/base.service';
@Injectable({
  providedIn: 'root',
})
export class AddCardService {
  public cardDetails = new BehaviorSubject<any>(false);
  public cardAdded = new BehaviorSubject<any>(false);

  constructor(private readonly baseService: BaseService) {}

  public addCard(cardToken) {
    return this.baseService.post(config.ADD_CARD, cardToken);
  }
}
