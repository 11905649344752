import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomAddToCartService } from '../custom-add-to-cart.service';

@Component({
  selector: 'app-custom-add-to-cart',
  templateUrl: './custom-add-to-cart.component.html',
  styleUrls: ['./custom-add-to-cart.component.scss']
})
export class CustomAddToCartComponent implements OnInit {
  templatePage:any;
  childCarts:boolean;
  cartId:any;
  userAccountgetData:any;
  cartItems:any;
  constructor(private readonly pageDate: CmsService,
    private readonly addToCart: CustomAddToCartService,
    private readonly user: UserAccountFacade,
    private readonly cdr: ChangeDetectorRef) {
    this.childCarts=false;
   }

  ngOnInit(): void {
    this.pageDate.getCurrentPage().subscribe((res) => {
      if(res){
        this.templatePage=res?.template;
      }
    });
  }


}
