<div class="row account-overview-header">
  <div class="col-xs-4 col-sm-5 col-md-7">
    <h3 class="reservation-details">
      {{ "accountOverview.FluReservations.title" | cxTranslate }}
    </h3>
  </div>
</div>

<section class="col-12 col-xs-12 col-sm-7 col-md-12">
  <section class="row line-bottom">
    <section class="col-4 col-md-6 mini-card">
      <section class="three-columns-line" *ngIf = "displayActiveSeasons">
        <img src="../../assets/icons/calendar.svg" alt="calendar" />
        <h2 *ngIf="!fluResevartion">
          {{ "accountOverview.FluContent.activeSeasonsopenPrefix" | cxTranslate }}
          {{ activeSeasons }}
          {{ "accountOverview.FluContent.activeSeasonsopen" | cxTranslate }}
        </h2>
        <h2 *ngIf="fluResevartion">
          {{ "accountOverview.FluContent.reservationPrefix" | cxTranslate }}
          {{ activeSeasons }} &nbsp;
          {{ "accountOverview.FluContent.reservation" | cxTranslate }}
          {{ reservationId }}
        </h2>
        <p *ngIf="!fluResevartion">
          {{ "accountOverview.FluContent.makeyour" | cxTranslate }}
          {{ endDate | date: "longDate" }}
        </p>
        <p *ngIf="fluResevartion">
          {{ "accountOverview.FluContent.viewDetails" | cxTranslate }}
        </p>
        <button
          type="button"
          class="btn btn-default ad-blue-button ad-confirm-button text-button-initial"
          (click)="onInfClick()"
        >
          {{ fluResevartion ? viewBtnText : createBtnText }}
        </button>
      </section>
      <section *ngIf = "!displayActiveSeasons" >
      <p class = "text-center season-inactive">
        {{ "accountOverview.FluContent.noactiveSeasonMsg" | cxTranslate }}
      </p>
      <p *ngIf="fluResevartion" class = "text-center">
        {{ "accountOverview.FluContent.noactiveSeasonYesReservation" | cxTranslate }}
        <a  (click)="navigatetoDb()" class = "navigatetoDb-link">{{ "accountOverview.FluContent.here" | cxTranslate }}</a>
      </p>
      </section>
    </section>
    <section class="col-5 col-md-6 banner6">
      <app-flu-dashboard-banner
        [bannerComponent]="compNameAdBanner6"
      ></app-flu-dashboard-banner>
    </section>
  </section>
</section>
