import { Injectable } from '@angular/core';
import {
  CmsService,
  Page,
  PageMetaResolver,
  PageTitleResolver,
  PageType, TranslationService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomLoginBreadcrumbsResolver
  extends PageMetaResolver
  implements PageTitleResolver
{
  constructor(
    protected cms: CmsService,
    protected translationService: TranslationService
  ) {
    super();
    this.pageType = PageType.CONTENT_PAGE;
    this.pageTemplate = 'AccountPageTemplate';
    this.pageUid = 'ForgotPassword';
  }
  protected page$: Observable<Page> = this.cms.getCurrentPage();

  protected title$: Observable<string | undefined> = this.page$.pipe(
    switchMap((page) => {
      return this.translationService
        .translate('resetPassword.createPasswordTitle')
        .pipe(
          map((textHome) => {
            const isCustom = new URL(window.location.href).searchParams.get(
              'registration'
            );
            return isCustom ? textHome : page.title;
          })
        );
    })
  );

  resolveTitle(): Observable<string> {
    return this.title$;
  }
}
