import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { IndirectCheckoutComponent } from 'src/app/shared/modules/indirect-checkout/indirect-checkout.component';
import { DataLayerService } from '../../shared/services/data-layer.service';
import { CustomAddToCartService } from '../custom-add-to-cart.service';

@Component({
  selector: 'app-indirect-cart-checkout',
  templateUrl: './indirect-cart-checkout.component.html',
  styleUrls: ['./indirect-cart-checkout.component.scss'],
})
export class IndirectCartCheckoutComponent implements OnInit, OnDestroy {
  @ViewChild('p2') public savePopover: NgbPopover;
  @ViewChild(IndirectCheckoutComponent) checkoutDetails;
  cartDetails: any[];
  stdCart = true;
  usergetDataObj: any;
  getuserData: any;
  searchedClientList: any;
  selectedData: any;
  listSelectedData: any;
  userAccountgetData: any;
  saveCartName: string;
  errorMsg: string;
  cartId: string;
  productEntries: any = [];
  saveCartSub: Subscription;
  cartItemsSub: Subscription;
  userAccountSub: Subscription;
  invalidNameSub: Subscription;
  showDisclaimer: boolean = true;
  constructor(
    private readonly addToCart: CustomAddToCartService,
    readonly user: UserAccountFacade,
    private readonly cdr: ChangeDetectorRef,
    private dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res && res != null) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
      }
    });
    this.cdr.detectChanges();
    this.cartItemsSub = this.addToCart.cartItems$.subscribe((cartData: any) => {
      if (cartData) {
        let indirectCartDetails = [];
        this.productEntries = [];
        indirectCartDetails = cartData?.childCarts;
        this.cartId = cartData?.code;
        if (indirectCartDetails) {
          indirectCartDetails = indirectCartDetails.filter(
            (item) => item.cartType === 'INDIRECT'
          );
          for (const cartDetails of indirectCartDetails) {
            for (const entries of cartDetails.entries) {
              this.productEntries.push(entries);
            }
          }
          this.cdr.detectChanges();
        }
      }
    });
  }

  closeDisclaimerAlert() {
    this.showDisclaimer = false;
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg = '';
    this.saveCartName = this.selectedData?.companyName;
  }
  getUserAccountData(userId): void {
    this.userAccountSub = this.addToCart.userAccountDetails.subscribe(
      (userDataget) => {
        if (userDataget) {
          this.getuserData = userDataget.res.shippingAddresses;
          this.searchedClientList = userDataget.res.shippingAddresses;
          this.selectedData = this.getuserData[0];
          this.saveCartName = this.selectedData?.companyName;
        }
      }
    );
    this.cdr.detectChanges();
  }
  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCart.saveCartDetails(this.saveCartName, this.cartId);
      this.invalidNameSub = this.addToCart.invalidCartName$.subscribe((res) => {
        if (res) {
          for (const error of res?.error?.errors) {
            this.errorMsg = error?.message;
          }
        }
      });
      this.saveCartSub = this.addToCart.saveCartSuccess$.subscribe((res) => {
        if (res) {
          if (this.savePopover) {
            this.savePopover.close();
          }
          this.errorMsg = '';
          this.saveCartName = this.selectedData?.companyName;
        }
      });
    } else {
      if (this.saveCartName) {
        this.errorMsg = 'Save cart name is not valid.';
      } else {
        this.errorMsg = 'Cart name cannot be blank';
      }
    }
  }
  exportXls() {
    const cartProducts = [];
    for (const entries of this.productEntries) {
      cartProducts.push({
        'Customer Name': this.checkoutDetails?.orderContact?.name,
        Date: new Date().toLocaleDateString(),
        'Product Name': entries?.product?.name,
        'Product CIP Code': entries?.product?.cipCode,
        Quantity: entries?.quantity,
        'Delivery Address':
          this.selectedData?.companyName +
          ' (' +
          this.selectedData?.unitID +
          ') ' +
          (this.selectedData?.line1 ? this.selectedData?.line1 + ', ' : '') +
          (this.selectedData?.line2 ? this.selectedData?.line2 + ', ' : '') +
          (this.selectedData?.town ? this.selectedData?.town + ', ' : '') +
          (this.selectedData?.region?.isocodeShort
            ? this.selectedData?.region?.isocodeShort + ' '
            : '') +
          (this.selectedData?.postalCode ?? ''),
        'Unit Price':
          entries?.basePrice?.value + ' ' + entries?.basePrice?.currencyIso,
        Subtotal:
          entries?.totalPrice?.value + ' ' + entries?.totalPrice?.currencyIso,
        'PO Number': this.checkoutDetails?.orderNo,
      });
    }
    const excelDataLayer = {
      localizedDownloadedMedia: {
        name: 'checkout.xlsx',
      },
      downloadedMediaType: 'xlsx',
    };
    this.dataLayerService.downloadDataLayer(excelDataLayer);
    this.addToCart.downloadXls(cartProducts, 'Checkout');
  }
  omit_special_char(event) {
    this.errorMsg = '';
    const reg = /^[^`@~$%\^&+={}|[\]\\<>/]*$/;
    return new RegExp(reg).test(event.key);
  }
  ngOnDestroy() {
    this.cartItemsSub?.unsubscribe();
    this.saveCartSub?.unsubscribe();
    this.invalidNameSub?.unsubscribe();
    this.userAccountSub?.unsubscribe();
  }
}
