import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FluDashboardBannerComponent } from './flu-dashboard-banner/flu-dashboard-banner.component';



@NgModule({
  declarations: [FluDashboardBannerComponent],
  exports:[FluDashboardBannerComponent],
  imports: [
    CommonModule
  ]
})
export class FluDashboardBannerModule { }
