<section class="tools-dialog">
  <div class="modal-header">
    <span
      class="close-banner"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
    </span>
  </div>
  <div class="modal-body">
    <section>
      <img [src]="image" alt="" class="flu-poster" />
    </section>
  </div>
</section>
