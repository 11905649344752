import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthRedirectService,
  AuthService,
  AuthStorageService, GlobalMessageService, GlobalMessageType,

  OCC_USER_ID_CURRENT,
  RoutingService,
  StateWithClientAuth,
  UserIdService
} from '@spartacus/core';
import { VscaOAuthLibWrapperService } from '../service/vsca-oauth-lib-wrapper.service';
import {Router} from '@angular/router';
import {AsyncSubject, BehaviorSubject, Subject} from 'rxjs';

/**
 * Auth service for normal user authentication.
 * Use to check auth status, login/logout with different OAuth flows.
 */
@Injectable({
  providedIn: 'root',
})
export class VscaAuthService extends AuthService {
  constructor(
    protected store: Store<StateWithClientAuth>,
    protected userIdService: UserIdService,
    protected authStorageService: AuthStorageService,
    protected authRedirectService: AuthRedirectService,
    protected routingService: RoutingService,
    protected oAuthLibWrapperService: VscaOAuthLibWrapperService,
    protected globalMessage: GlobalMessageService,
  ) {
    super(
      store,
      userIdService,
      oAuthLibWrapperService,
      authStorageService,
      authRedirectService,
      routingService
    );
  }

  userAuthDetails: any;

  /**
   * Loads a new user token with Resource Owner Password Flow.
   * @param userId
   * @param password
   */
  async loginWithCredentials(userId: string, password: string): Promise<void> {
    try {
      await this.oAuthLibWrapperService.vscaAuthorizeWithPasswordFlowForbaseSiteId(
        userId,
        password,
        'fr'
      )
        .then(() => {
          // OCC specific user id handling. Customize when implementing different backend
          this.userIdService.setUserId(OCC_USER_ID_CURRENT);
          this.store.dispatch(new AuthActions.Login());
          this.authRedirectService.redirect();
        })
        .catch((e) => {
        if (e?.error?.error_description === 'from_akamai'){
          this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
          this.userAuthDetails = {userId, password};
          this.routingService.goByUrl('/customer-register');
        }
      });
    } catch {}
  }
}
