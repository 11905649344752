import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { UserAccountFacade } from "@spartacus/user/account/root";

@Injectable({
  providedIn: 'root',
})
export class OpenStatementsService {
  public reloadComponent = new Subject<boolean>();
  public loadStatements = new Subject<any>();
  public userAccountIdDetails = new Subject<any>();

  constructor(
    private readonly baseService: BaseService,
    private readonly userAccountFacade: UserAccountFacade
  ) {}

  public getStatements(params): Observable<any> {
    return this.baseService.get(config.GET_OPEN_STATEMENTS, params);
  }

  public getAllStatements(params): Observable<any> {
    return this.baseService.get(config.GET_ALL_STATEMENTS_DATA_RANGE, params);
  }
  public getAccountId() {
    this.userAccountFacade.get().subscribe((user: any) => {
      if (user) {
        this.baseService.get(`/users/${user.userName}/b2bUnits`).subscribe((account) => {
          if (account !== null && account !== undefined && account?.length !== 0) {
            this.userAccountIdDetails.next(account);
          }
        })
      }
    });
  }
}
