import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  public faqList = new BehaviorSubject<null>(null);
  faqList$ = this.faqList.asObservable();

  constructor(
    public readonly baseService: BaseService,
  ) { }

  getFAQ(){
    this.baseService.get(config.FAQ).subscribe((res) => {
      this.faqList.next(res);
    });
  }
}
