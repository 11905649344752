import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CmsService, OccEndpointsService } from '@spartacus/core';

@Component({
  selector: 'app-flu-dashboard-banner',
  templateUrl: './flu-dashboard-banner.component.html',
  styleUrls: ['./flu-dashboard-banner.component.scss'],
})
export class FluDashboardBannerComponent implements OnInit {
  cmsResponse: any = '';
  baseUrl = this.occEndPoint.getBaseUrl({
    baseUrl: true,
    prefix: false,
    baseSite: false,
  });
  @Input() bannerComponent;
  imageUrl: any;
  redirectionLink: any;
  constructor(
    private readonly cmsData: CmsService,
    private readonly occEndPoint: OccEndpointsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.cmsData.getComponentData(this.bannerComponent).subscribe((res) => {
      if (res !== undefined) {
        this.cmsResponse = res;
        this.imageUrl = this.cmsResponse?.media?.url
          ? this.validateDomainNameExistence(this.cmsResponse?.media?.url)
            ? this.cmsResponse.media.url
            : this.baseUrl + this.cmsResponse.media.url
          : '';
        this.redirectionLink = this.cmsResponse?.urlLink
          ? this.validateDomainNameExistence(this.cmsResponse?.urlLink)
            ? this.cmsResponse?.urlLink
            : this.baseUrl + this.cmsResponse?.urlLink
          : '';
        this.cd.detectChanges();
      }
    });
  }

  validateDomainNameExistence(url: string) {
    return url?.indexOf('https://') === 0 || url?.indexOf('www.') === 0;
  }
}
