import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  GlobalMessageService,
  GlobalMessageType,
  ProductReferenceService,
  ProductService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { CustomPdpService } from '../custom-pdp.service';
@Component({
  selector: 'app-custom-pdp',
  templateUrl: './custom-pdp.component.html',
  styleUrls: ['./custom-pdp.component.scss'],
})
export class CustomPdpComponent implements OnInit, OnDestroy {
  sessionStorage = sessionStorage;
  productCode: string;
  productDetails: any;
  monographLink: any;
  monograph: any;
  iscarousel = false;
  buttonName = 'Login to order';
  panelVolumeExpanded = false;
  panelProductExpanded = false;
  userDetails: any;
  relatedProducts = [];
  relatedProductsFormatted: any;

  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  description: any;
  cartId: any;
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinite: true,
    arrows: true,
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 1100, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };
  displaySection = false;
  itemAddedQuantity: any;
  cartItems: any;
  slickInit(e): void {}
  breakpoint(e): void {}
  afterChange(e): void {}
  beforeChange(e): void {}
  cartDetailsSub: Subscription;
  userSub: Subscription;
  cartItemsSub: Subscription;
  productCodeSub: Subscription;
  createdCartSub: Subscription;
  relatedProdSub: Subscription;
  wrongQuantity: boolean = false;
  constructor(
    private readonly productSearch: ProductService,
    private readonly route: ActivatedRoute,
    private readonly routerservice: RoutingService,
    private readonly user: UserAccountFacade,
    private readonly pdpService: CustomPdpService,
    private readonly referenceService: ProductReferenceService,
    private readonly cdr: ChangeDetectorRef,
    private readonly routingService: RoutingService,
    private readonly modalService: NgbModal,
    private readonly sanitizer: DomSanitizer,
    private readonly dataLayerService: DataLayerService,
    private readonly translation: TranslationService,
    private globalMessage: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
      this.populatePDP();
    });
  }
  populatePDP() {
    this.productDetails = [];
    this.productCode = this.route.snapshot.params.productCode;
    this.pdpService.getPDPCategory(this.productCode);
    this.userSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (this.userDetails && this.userDetails !== null) {
        this.pdpService.getCarts(this.userDetails?.userName);
      }
    });
    this.cartItemsSub = this.pdpService.cartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems.filter(
          (item) => item?.saveTime === undefined
        );
      }
    });
    this.productCodeSub = this.pdpService.productCode$.subscribe((res) => {
      if (res) {
        this.dataLayerService.viewItemDataLayer(res, 'standard process');
        this.displaySection = res.hasOwnProperty('code');
        this.productDetails = res;
        this.description = this.sanitizer.bypassSecurityTrustHtml(
          this.productDetails.description
        );
        this.monograph = this.productDetails?.productDocuments;
        if (this.monograph) {
          for (const link of this.monograph?.productDocument) {
            this.monographLink = this.sanitizer.bypassSecurityTrustResourceUrl(
              link.url
            );
          }
        }
        if (this.userDetails !== undefined) {
          if (this.productDetails.flu === true || this.isIndirect()) {
            this.buttonName = 'Reserve now';
          } else {
            this.buttonName = 'Add to cart';
          }
        } else {
          this.buttonName = 'Login to order';
        }
      }
      this.cdr.detectChanges();
    });
  }
  addToCart(productCode, popover): void {
    if (this.buttonName === 'Reserve now') {
      if (this.isIndirect()) {
        this.navigateToIndirectCatalog();
      }
      else {
        this.navigateToFlu();
      }
    } else if (this.buttonName === 'Add to cart') {
      const inputElement = document.getElementById(
        `prodQuantity${productCode}`
      ) as HTMLInputElement;
      const params = {
        product: {
          code: productCode,
        },
        quantity: inputElement.value,
      };
      this.itemAddedQuantity = inputElement.value;
      if (this.cartItems && this.cartItems?.length === 0) {
        this.pdpService.createCart(this.userDetails?.userName);
        this.createdCartSub = this.pdpService.createdCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.pdpService.getCarts(this.userDetails?.userName);
            this.UpdateCartDetails(params, popover);
          }
        });
      } else {
        if (this.cartId === undefined) {
          this.cartItems =
            this.cartItems && this.cartItems?.length > 0
              ? this.cartItems[this.cartItems?.length - 1]
              : [];
          this.cartId = this.cartItems?.code;
        }
        this.UpdateCartDetails(params, popover);
      }
    }
  }
  UpdateCartDetails(params, popover): void {
    this.pdpService.updateCart(
      params,
      this.userDetails?.userName,
      this.cartId,
      params.quantity
    );
    this.cartDetailsSub = this.pdpService.cartDetailsUpdate$.subscribe(
      (res) => {
        if (res) {
          popover.toggle();
          setTimeout(() => popover.close(), 2500);
        }
      }
    );
  }
  performQuantityComputation(
    product,
    operator,
    idData,
    stepQuantity,
    minQuantity,
    maxQuantity
  ): void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.wrongQuantity = false;
    try {
      ProductUtils.alterQuantity(
        operator,
        idData,
        stepQuantity,
        minQuantity,
        maxQuantity,
        'cart'
      );
    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
  }

  isIndirect(): boolean {
    return this.productDetails?.productType &&
      this.productDetails?.productType === 'INDIRECT';
  }

  navigateToIndirectCatalog(): void {
    this.routingService.goByUrl('/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
  }

  goToLogin() {
    this.routingService.goByUrl('/login');
  }
  openImage(content) {
    this.modalService.open(content, {
      windowClass: 'carousel-modal',
    });
  }
  navigateToFlu() {
    this.routerservice.goByUrl('/influenza/dashboard');
  }
  ngOnDestroy() {
    this.pdpService.productCode.next(null);
    this.displaySection = false;
    this.cartDetailsSub?.unsubscribe();
    this.userSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.productCodeSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.relatedProdSub?.unsubscribe();
  }
}
