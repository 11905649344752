
    <!--Failure screen-->
    <div
      *ngIf="showUserDetail === false"
      class="
        col-xs-12 col-sm-12 col-md-12
        Registration-split
        recovery-split
        border-align
      "
    >
      <section *ngIf="showAnswerMessage === false && !deletedAccountB2B">
        <h3 class="login-help-title">
          {{ "passwordRecoveryFailure.assistanceTitleAnswer" | cxTranslate }}
        </h3>
        <span class="security-Notify">{{
          "passwordRecoveryFailure.assistanceMessageAnswer" | cxTranslate
          }}</span>
      </section>
      <section *ngIf="userInfo?.forgotPassword && showAnswerMessage !== false && !deletedAccountB2B">
        <h3 class="login-help-title">
          {{ "passwordRecoveryFailure.assistanceTitleNew" | cxTranslate }}
        </h3>
        <span class="security-Notify">{{
          "passwordRecoveryFailure.assistanceMessageNew" | cxTranslate
          }}</span>
      </section>
      <section *ngIf="userInfo?.forgotUsername && showAnswerMessage !== false && !deletedAccountB2B">
          <h3 class="login-help-title">
            {{ "passwordRecoveryFailure.assistanceTitle" | cxTranslate }}
          </h3>
          <span class="security-Notify">{{
            "passwordRecoveryFailure.assistanceMessage" | cxTranslate
            }}</span>
      </section>
      <section *ngIf="deletedAccountB2B">
        <h3 class="login-help-title">
          {{ "passwordRecoveryFailure.assistanceTitleDeletedB2B" | cxTranslate }}
        </h3>
        <span class="security-Notify">{{
          "passwordRecoveryFailure.assistanceMessageDeletedB2B" | cxTranslate
          }}</span>
      </section>

      <div class="col-xs-12 col-sm-8 col-md-12 p0">
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "passwordRecoveryFailure.emailAddress" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userInfo?.email }}</span>
        </div>
      </div>
      <br />
    </div>
    <!--Success screen-->
    <div
      *ngIf="showUserDetail"
      class="
        col-xs-12 col-sm-12 col-md-12
        Registration-split
        recovery-split
        border-align
      "
    >
      <h3 class="login-help-title" *ngIf="userData?.forgotUsername">
        {{ "passwordRecoveryFailure.emailInfoMessage" | cxTranslate }}
      </h3>
      <h3 class="login-help-title" *ngIf="userData?.forgotPassword">
        {{ "passwordRecoveryFailure.passwordLinkInfoMessage" | cxTranslate }}
      </h3>
      <div class="col-xs-12 col-sm-8 col-md-8 p0">
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "passwordRecoveryFailure.emailAddress" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userData.email }}</span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "passwordRecoveryFailure.accountNumber" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userData.accountNumber }}</span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "passwordRecoveryFailure.name" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userData.name }}</span>
        </div>
      </div>
      <hr class="Fields-container-line" />
      <span class="recovery-common-txt"
        >{{ "passwordRecoveryFailure.spamMessageInfo" | cxTranslate
        }}<br /><br />{{
          "passwordRecoveryFailure.assistanceNumber" | cxTranslate
        }}</span
      >
    </div>
