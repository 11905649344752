import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocationStripComponent } from './location-strip.component';


@NgModule({
  declarations: [LocationStripComponent],
  imports: [
    CommonModule,
  ],
  exports: [LocationStripComponent],
  providers: []
})
export class LocationStripModule { }

