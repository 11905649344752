import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-security-information',
  templateUrl: './security-information.html',
})
export class SecurityInformation implements OnInit {
  @Input() formDetails: any;

  ngOnInit(): void {}
}
