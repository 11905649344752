import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  ProductAdapter,
  provideDefaultConfig,
} from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CustomProdPgModule } from '../custom-prod-pg/custom-prod-pg.module';
import { MainPipeModule } from '../shared/pipes/mainpipe.module';
import { CustomOccProductAdapter } from './custom-pdp.adapter';
import { CustomPdpConfig } from './custom-pdp.config';
import { CustomPdpComponent } from './custom-pdp/custom-pdp.component';

@NgModule({
  declarations: [CustomPdpComponent],
  imports: [
    CommonModule,
    MediaModule,
    I18nModule,
    SlickCarouselModule,
    NgbModule,
    CustomProdPgModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductIntroComponent: {
          component: CustomPdpComponent,
        },
      },
    } as CmsConfig),
    MainPipeModule,
  ],
  providers: [
    { provide: ProductAdapter, useClass: CustomOccProductAdapter },
    provideDefaultConfig(CustomPdpConfig),
  ],
})
export class CustomPdpModule {}
