import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenToForwardSlash',
})
export class HyphenToForwardSlashPipe implements PipeTransform {
  transform(text) {
    if (text) {
      const cleanNum = text.toString(2).replace(/\-/g, '/');
      return cleanNum;
    }
  }
}
