import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  OCC_USER_ID_CURRENT,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { VscaOAuthLibWrapperService } from '../../core/auth/user-auth/service/vsca-oauth-lib-wrapper.service';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class CustomRegistrationService {
  public provinceData = new BehaviorSubject<any>(false);
  provinceData$ = this.provinceData.asObservable();
  public practiceOrFacilityTypeData = new BehaviorSubject<any>(false);
  practiceOrFacilityTypeData$ = this.practiceOrFacilityTypeData.asObservable();
  public contactTitleData = new BehaviorSubject<any>(false);
  contactTitleData$ = this.contactTitleData.asObservable();
  public contactRoleData = new BehaviorSubject<any>(false);
  contactRoleData$ = this.contactRoleData.asObservable();
  public passwordRecoveryQuestionsData = new BehaviorSubject<any>(false);
  passwordRecoveryQuestionsData$ =
    this.passwordRecoveryQuestionsData.asObservable();
  public sectionChange = new Subject<{
    header: string;
    form?: FormGroup;
    forms?: FormGroup[];
    totalFormData?: any;
    msgData?: any;
  }>();
  authRedirectService: any;
  selectedRole$ = new BehaviorSubject<any>(null);

  constructor(
    private readonly baseService: BaseService,
    private readonly oAuthLibWrapperService: VscaOAuthLibWrapperService,
    private readonly userIdService: UserIdService,
    private readonly store: Store<StateWithClientAuth>
  ) {}

  public getProvinces(params): void {
    this.baseService.get(config.GET_PROVINCES, params).subscribe((res) => {
      this.provinceData.next(res);
    });
  }

  public getPracticeOrFacilityType(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.practiceOrFacilityTypeData.next(res);
      });
  }

  public getContactTitle(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.contactTitleData.next(res);
      });
  }

  public getContactRole(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.contactRoleData.next(res);
      });
  }

  public getPasswordRecoveryQuestions(params): void {
    this.baseService
      .get(config.GET_PRACTICEORFACILITYTYPE, params)
      .subscribe((res) => {
        this.passwordRecoveryQuestionsData.next(res);
      });
  }

  public getAccountData(userId: string, accountId: string): Observable<any> {
    return this.baseService.get(
      `${config.USER_ACCOUNT}${userId}/b2bUnits/${accountId}`,
      { fields: 'FULL' }
    );
  }

  public getJanrainUserData(userEmail: string): Observable<any> {
    return this.baseService.get(
      `/customer-register/user-information/${userEmail}`,
      { fields: 'FULL' }
    );
  }

  public getJanrainUserDataByUserName(userName: string): Observable<any> {
    return this.baseService.get(
      `/customer-login/user-information/${userName}`,
      { fields: 'FULL' }
    );
  }

  public postAccountRegistration(params?: any): Observable<any> {
    this.selectedRole$.next(params);
    return this.baseService.post(
      config.POST_ACCOUNT_REGISTRATION_DETAILS,
      params
    );
  }

  public postValidateAccountInfo(params?: any): Observable<any> {
    this.selectedRole$.next(params);
    return this.baseService.post(
      config.VALIDATE_ACCOUNT_INFORMATION_USER_REGISTRATION,
      params
    );
  }

  public postValidateIndirectAccountInfo(params?: any): Observable<any> {
    this.selectedRole$.next(params);
    return this.baseService.post(
      config.VALIDATE_HCP_ACCOUNT_INFORMATION_USER_REGISTRATION,
      params
    );
  }

  public postValidateIndirectAccountInAkamai(params?: any): Observable<any> {
    this.selectedRole$.next(params);
    return this.baseService
      .post(config.VALIDATE_HCP_ACCOUNT_INFORMATION_IN_AKAMAI, params)
      .pipe(
        tap(),
        catchError((err) => {
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  public postUserRegistrationInfo(params?: any): Observable<any> {
    this.selectedRole$.next(params);
    return this.baseService.post(config.POST_USER_REGISTRATION_INFO, params);
  }

  loginWithCredentials(userId: string, password: string) {
    this.oAuthLibWrapperService
      .vscaAuthorizeWithPasswordFlowForbaseSiteId(userId, password, 'fr')
      .then((res) => {
        this.userIdService.setUserId(OCC_USER_ID_CURRENT);

        this.store.dispatch(new AuthActions.Login());
      });

    // OCC specific user id handling. Customize when implementing different backend
  }

  getRoleTypes(): Observable<any> {
    return this.baseService.get(config.CUSTOMER_REGISTRATION_ROLE);
  }

  getRegistrationParams(token: string): Observable<any> {
    return this.baseService.get(config.CUSTOMER_REGISTRATION_PARAMS,
    {
      registration: encodeURI(token).replaceAll("%20","+")
    });
  }
}
