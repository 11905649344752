import {Component, Input, OnInit} from '@angular/core';
import {CmsParagraphComponent, CmsService, RoutingService} from '@spartacus/core';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {CustomAddToCartService} from '../../custom-add-to-cart/custom-add-to-cart.service';

@Component({
  selector: 'app-indirect-empty-cart',
  templateUrl: './indirect-empty-cart.component.html',
  styleUrls: ['./indirect-empty-cart.component.scss']
})
export class IndirectEmptyCartComponent implements OnInit {
  @Input() compName;
  emptycontent: any;
  userAccountgetData: any;

  constructor(
    private readonly route: RoutingService,
    private readonly cmsData: CmsService,
    private readonly addToCart: CustomAddToCartService,
    private readonly user: UserAccountFacade,
  ) {
  }

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res) {
        this.userAccountgetData = res;
      }
    });
    this.addToCart.getAllCarts(this.userAccountgetData?.userName);
    this.cmsData.getComponentData(this.compName).subscribe((res: CmsParagraphComponent) => {
        if (res && res?.content) {
          this.emptycontent = res.content;
        }
      }
    );
  }
}
