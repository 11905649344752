import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';



@NgModule({
  declarations: [TermsConditionsComponent],
  exports: [TermsConditionsComponent],
  imports: [
    CommonModule
  ]
})
export class TermsConditionsModule { }
