<div class="container header-Container">
  <div class="row">
    <div class="col-lg-3 col-xl-3 ">
      <span class="activeSeasonId">
        {{'scheduleToShip.pageTitle'|cxTranslate}}
      </span>
    </div>
    <div class="col-7 col-lg-7 col-xl-7 bc-Dash-Div no-print">
      <span class="back-To-Dashbaord" (click)="backToCart()">
        <img src='assets/img/chevron-left.svg' class="svg-Images" alt="left">
        {{'selectVaccines.back_to_db'|cxTranslate}}</span>
    </div>
    <div class="col-3 col-lg-2 col-xl-2 text-right print-Div no-print">
      <span class="print" (click)="print()">
        <img src='assets/img/print.svg' class="svg-Images" alt="print">
        {{'selectVaccines.print'|cxTranslate}}</span>
    </div>
  </div>
</div>

<app-flu-header [selectedHeader]="header"></app-flu-header>

<section class="container schedule-to-ship-body">
  <section class="row stsb-head">
    <section class="col-12">
      <div class="row">
        <div class="col-sm-12 col-md-8 ">
          <p><strong>{{'scheduleToShip.copyHeading'|cxTranslate}}</strong></p>
          <p *ngIf="stsMessage != ''" [innerHTML]="stsMessage"></p>
        </div>
        <div class="col-sm-12 col-md-4">
          <button class="btn proceed-Btn" (click)="goToCheckOut()" [disabled]="!proceed">
            <!-- <i class="fa fa-spinner fa-spin" *ngIf="showSpinnerBtn"></i> -->
            {{'selectVaccines.proceed_toCart'|cxTranslate}}</button>
          <br>
          <div class="notify">
            <img src='assets/img/warning.svg' class="svg-Images" alt="warning">
            <p>
              <b>{{'scheduleToShip.disclaimerBold'|cxTranslate}}</b> : {{'scheduleToShip.disclaimerCopy'|cxTranslate}}
            </p>
          </div>
        </div>
      </div>
    </section>
  </section>
  <div class="row stsb-body">
    <div class="col-12 stsb-body-title">
      <img src='assets/img/delayed-shipping.png' class="svg-Images" alt="Shipping frequesncy">
      <span>{{'scheduleToShip.shippingFrequency'|cxTranslate}}</span>
    </div>
    <div class="col-12 stsb-body-content">
      <div class="wrapper">
        <div class="t-row">
          <div class="box"></div>
          <div class="box">{{'scheduleToShip.remainingToSchedule'|cxTranslate}}</div>
          <div class="box">{{'scheduleToShip.deliveryWeekOne'|cxTranslate}}</div>
          <div class="box">{{'scheduleToShip.deliveryWeekTwo'|cxTranslate}}</div>
          <div class="box">{{'scheduleToShip.deliveryWeekThree'|cxTranslate}}</div>
        </div>
        <div class="t-row" *ngFor="let sched of productSchedule; let i = index">
          <form [formGroup]="schedToShipForm" [attr.data-total]="total" id="{{i + 'form'}}">
            <ng-container formArrayName="forms">
              <div class="box prod-info">
                <cx-media *ngIf="sched?.product?.images != undefined || sched?.product?.images?.length > 0"
                          class="cx-product-image" [container]="sched.product?.images[1]" format="product"></cx-media>
                <img *ngIf="sched?.product?.images === undefined || sched?.product?.images?.length === 0"
                     [src]="fallbackImage" alt="" class="cx-product-image">
                <span class="product-name" (click)="showPdp(sched?.product)">{{sched?.product?.name}}</span>
              </div>
              <div class="box">
                {{sched?.quantity}} {{'scheduleToShip.doses'|cxTranslate}}
                <span class="total-notice text-notice"></span>
                <span class="text-notice amount-difference"></span>
              </div>
              <ng-container [formGroupName]="i">
                <div class="box" [ngClass]="{'week-null': sched.deliveries[0] == undefined}">
                  <span>{{'scheduleToShip.doses'|cxTranslate}}</span>
                  <input formControlName="weekone" name="weekone" type="number" class="week-one"
                         *ngIf="sched.deliveries[0] != undefined" value="0"
                         (keyup)="weekCalc($event,i,sched?.product?.stepQuantity,sched?.quantity,0)">
                  <input type="number" class="week-one" *ngIf="sched.deliveries[0] == undefined" value="0" disabled>
                  <span class="value-less-notice text-notice hide">{{'scheduleToShip.wrongValue'|cxTranslate}}
                    {{sched?.product?.stepQuantity}}</span>
                  <span class="box-notice hide text-notice">{{'scheduleToShip.wrongQuantity'|cxTranslate}}
                    {{sched?.product?.stepQuantity}}</span>
                </div>
                <div class="box" [ngClass]="{'week-null': sched.deliveries[1] == undefined}">
                  <span>{{'scheduleToShip.doses'|cxTranslate}}</span>
                  <input formControlName="weektwo" name="weektwo" type="number" class="week-one"
                         *ngIf="sched.deliveries[1] != undefined" value="0"
                         (keyup)="weekCalc($event,i,sched?.product?.stepQuantity,sched?.quantity,1)">
                  <input type="number" class="week-one" *ngIf="sched.deliveries[1] == undefined" value="0" disabled>
                  <span class="value-less-notice text-notice hide">{{'scheduleToShip.wrongValue'|cxTranslate}}
                    {{sched?.product?.stepQuantity}}</span>
                  <span class="box-notice hide text-notice">{{'scheduleToShip.wrongQuantity'|cxTranslate}}
                    {{sched?.product?.stepQuantity}}</span>
                </div>
                <div class="box" [ngClass]="{'week-null': sched.deliveries[2] == undefined}">
                  <span>{{'scheduleToShip.doses'|cxTranslate}}</span>
                  <input formControlName="weekthree" name="weekthree" type="number" class="week-one"
                         *ngIf="sched.deliveries[2] != undefined" value="0"
                         (keyup)="weekCalc($event,i,sched?.product?.stepQuantity,sched?.quantity,2)">
                  <input type="number" class="week-one" *ngIf="sched.deliveries[2] == undefined" value="0" disabled>
                  <span class="value-less-notice text-notice hide">{{'scheduleToShip.wrongValue'|cxTranslate}}
                    {{sched?.product?.stepQuantity}}</span>
                  <span class="box-notice hide text-notice">{{'scheduleToShip.wrongQuantity'|cxTranslate}}
                    {{sched?.product?.stepQuantity}}</span>
                </div>
              </ng-container>
            </ng-container>
          </form>
        </div>

      </div>
    </div>
  </div>
</section>
