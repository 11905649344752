import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

@NgModule({
  imports: [
    ConfigModule.withConfig({
      layoutSlots: {
        header: {
          lg: {
            slots: [
              'PreHeader',

              'SiteContext',

              'SiteLinks',

              'SiteLogo',

              'MiniCart',

              'SearchBox',

              'NavigationBar',
            ],
          },

          sm: {
            slots: ['SiteContext', 'SiteLinks', 'PreHeader', 'SiteLogo', 'MiniCart', 'SearchBox'],
          },

          xs: {
            slots: ['SiteContext', 'SiteLinks', 'PreHeader', 'SiteLogo', 'MiniCart', 'SearchBox'],
          },
        },

        navigation: {
          lg: { slots: [] },

          slots: ['NavigationBar', 'SiteContext', 'SiteLinks'],
        },

        footer: {
          slots: ['Footer'],
        },

        LandingPage2Template: {
          pageFold: 'Section2B',

          slots: [
            'Section1',

            'Section2A',

            'Section2B',

            'Section2C',

            'Section3',

            'Section4',

            'Section5',
          ],
        },

        ContentPage1Template: {
          slots: [
            'FAQ-left',
            'ContentSlotFAQ',
            'Content-contactus',
            'Generic-LeftContent-Slot',
            'Generic-Sidebar-Slot',
            'Cookies-LeftContent-Slot',
            'Cookies-Sidebar-Slot'
          ],
        },
        CategoryPageTemplate: {
          pageFold: 'Section2',

          slots: ['Section1', 'Section2', 'Section3'],
        },
        fluseasonreservationpagetemplate: {
          slots: [
            'FluParaMiddleSlottop',
            'FluParaMiddleSlottest',
            'FluParaMiddleSlot',
            'BannerContent',
          ],
        },
        FluSeasonProductListPageTemplate: {
          //template
          slots: [
            'SelectVaccineSlot',
            'FluViewCheckoutSlot',
            'ScheduleToShipSlot',
          ], //position of component
        },
        FluSeasonPaymentPageTemplate: {
          slots: ['FluViewCheckoutSlot'],
        },
        FluSeasonViewCartPageTemplate: {
          slots: ['FluCartMarketBannerContentSlot', 'FluViewCartSlot'],
        },
        flureserrvationconfirmpagetemplate: {
          slots: ['FluViewConfirmationSlot'],
        },
        flureservationoverviewpagetemplate: {
          slots: ['FlureservationOverViewSlot'],
        },

        ProductListPageTemplate: {
          slots: ['ProductLeftRefinements', 'ProductListSlot'],
        },

        ProductGridPageTemplate: {
          slots: ['ProductLeftRefinements', 'ProductGridSlot'],
        },

        SearchResultsListPageTemplate: {
          slots: [
            'Section2',

            'ProductLeftRefinements',

            'SearchResultsListSlot',

            'Section4',
          ],
        },

        SearchResultsGridPageTemplate: {
          slots: [
            'Section2',

            'ProductLeftRefinements',

            'SearchResultsGridSlot',

            'Section4',
          ],
        },

        ProductDetailsPageTemplate: {
          lg: {
            pageFold: 'UpSelling',
          },

          pageFold: 'Summary',

          slots: [
            'Summary',

            'UpSelling',

            'CrossSelling',

            'PlaceholderContentSlot',
          ],
        },

        CartPageTemplate: {
          slots: [
            'TopContent',

            'CenterRightContentSlot',

            'EmptyCartMiddleContent',
          ],
        },
        AccountPageTemplate: {
          slots: [
            'ContentSlotHeaderBannerOrdersListing',
            'BannerContent',
            'BodyContent',
            'SideContent',
          ],
        },
        MakeAPaymentPageTemplate: {
          slots: ['BannerContent', 'BodyContent'],
        },
        LoginPageTemplate: {
          lg: {
            slots: [
              'LeftContentSlot',
              'SanofiAccountOverviewBannerSlot',
              'AccountOverviewOrdersSlot',
              'AccountOverviewPlaceholderSlot2',
              'RightContentSlot',
              'RegistrationSlot',
              'CustomerRegistrationSlot',
              'AccountOverviewFinancialsSlot',
              'NewsAndUpdatesSlot',
              'SanofiAccountDashboardFluReservationsCMSSlot',
              'SanofiAccountOverviewBannerSlot6',
              'SanofiAccountOverviewBannerSlot5',
              'SanofiAccountOverviewBannerSlot2',
              'AccountOverviewPlaceholderSlot',
            ],
          },
          sm: {
            slots: [
              'LeftContentSlot',
              'SanofiAccountOverviewBannerSlot',
              'AccountOverviewOrdersSlot',
              'AccountOverviewPlaceholderSlot2',
              'RightContentSlot',
              'RegistrationSlot',
              'CustomerRegistrationSlot',
              'NewsAndUpdatesSlot',
              'AccountOverviewFinancialsSlot',
              'SanofiAccountDashboardFluReservationsCMSSlot',
              'SanofiAccountOverviewBannerSlot6',
              'SanofiAccountOverviewBannerSlot5',
              'SanofiAccountOverviewBannerSlot2',
              'AccountOverviewPlaceholderSlot',
            ],
          },
          xs: {
            slots: [
              'LeftContentSlot',
              'SanofiAccountOverviewBannerSlot',
              'AccountOverviewOrdersSlot',
              'AccountOverviewFinancialsSlot',
              'SanofiAccountDashboardFluReservationsCMSSlot',
              'SanofiAccountOverviewBannerSlot6',
              'AccountOverviewPlaceholderSlot2',
              'RightContentSlot',
              'RegistrationSlot',
              'CustomerRegistrationSlot',
              'NewsAndUpdatesSlot',
              'AccountOverviewPlaceholderSlot',
              'SanofiAccountOverviewBannerSlot5',
              'SanofiAccountOverviewBannerSlot2',
            ],
          },
        },
        RegistrationPageTemplate: {
          slots: [
            'CustomerRegistrationSlot',
            'RegistrationSlot',
            'NeedAssistanceSlot',
            'ProductMonogragh-Slot',
            'AccountOverviewProductMonographContentSlot',
          ],
        },
        CustomerRegisterPageTemplate: {
          slots: ['CustomerRegistrationSlot', 'NeedAssistanceSlot'],
        },
        CustomerRegisterConfirmPageTemplate: {
          slots: ['RegistrationSlot', 'UserRegisterBannerSlot'],
        },
        RegisterPageTemplate: {
          slots: ['RegistrationSlot', 'UserRegisterBannerSlot'],
        },
        RegisterConfirmPageTemplate: {
          slots: ['RegistrationSlot', 'UserRegisterBannerSlot'],
        },
        LoginHelpPageTemplate: {
          slots: ['BodyContent', 'NeedAssistanceSlot'],
        },
        ErrorPageTemplate: {
          slots: ['TopContent', 'MiddleContent', 'BottomContent'],
        },

        OrderConfirmationPageTemplate: {
          slots: ['BodyContent', 'SideContent'],
        },

        MultiStepCheckoutSummaryPageTemplate: {
          slots: ['TopContent', 'BodyContent', 'SideContent', 'BottomContent'],
        },

        CheckoutLoginPageTemplate: {
          slots: ['RightContentSlot'],
        },
        CheckoutPageTemplate: {
          slots: ['EmptyCartMiddleContent'],
        },
        ConfirmOrderPageTemplate: {
          slots: ['BodyContent'],
        },
        sanofiMediacalResourcesPagetemplate: {
          slots: [
            'MedicalResourceComp',
            'healthcarevertical',
            'healthcarehorizantal',
          ],
        },
        sanofiResourcesPageTemplate: {
          slots: ['patientEducation-vertical', 'patientEducation-Horizontal'],
        },

        IndirectProductCatalogPageTemplate: {
          slots: ['IndirectProductCatalogVertical', 'IndirectProductCatalogHorizontal'],
        },
        IndirectCartPageTemplate: {
          slots: ['IndirectCartMiddle'],
        },
        IndirectCheckoutPageTemplate: {
          slots: ['IndirectCheckoutMiddle'],
        },
        IndirectConfirmOrderPageTemplate: {
          slots: ['IndirectBodyContent'],
        },
        SwitchWhsPopupPageTemplate: {
          slots: [
            'Section2A',
          ],
        },
      },
    } as LayoutConfig),
  ],
})
export class LayoutConfigModule {}
