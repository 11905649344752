import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
  name: 'statementsCurrency',
})
export class StatementsCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    locale: string = 'ru-RU',
  ): string | null {
    const options = { minimumFractionDigits: 2 };
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
  }
}
