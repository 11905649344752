import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PhoneFormatPipeModule } from '../../pipes/phone-format/phone-format.module';
import {
  NgbModule,
  NgbPopoverModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  provideConfig,
} from '@spartacus/core';
import { CustomUserRegistrationModule } from 'src/app/custom-user-registration/custom-user-registration.module';
import { MainPipeModule } from '../../pipes/mainpipe.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { TermsConditionsModule } from '../terms-conditions/terms-conditions.module';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions/terms-conditions.component';
import { CheckoutComponent } from './checkout.component';
import { TotalPricesComponent } from './total-prices/total-prices.component';
@NgModule({
  declarations: [CheckoutComponent, TotalPricesComponent],
  exports: [CheckoutComponent, TotalPricesComponent],
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    PhoneFormatPipeModule,
    CustomUserRegistrationModule,
    I18nModule,
    NgbPopoverModule,
    NgbModule,
    TermsConditionsModule,
    MainPipeModule,
    FileUploadModule,
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
      cmsComponents: {
        checkoutPage: {
          component: TermsConditionsComponent
        }
      }
    }),
  ],
})
export class CheckoutModule {}
