import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '@spartacus/core';


@Injectable()
export class FrRedirectGuard implements CanActivate {

  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    this.isLoggedIn.subscribe((resLoggedIn: any) => {
      if (resLoggedIn) {
        this.router.navigate(['/influenza/dashboard']);
        return of(true);
      } else {
        this.router.navigate(['/']);
      }
    });
    return of(false);
  }

}
