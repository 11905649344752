import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import {
  AuthConfig,
  DateTimeProvider,
  HashHandler,
  OAuthLogger,
  OAuthService,
  OAuthStorage,
  TokenResponse,
  UrlHelperService,
  ValidationHandler,
} from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class VscaOAuthService extends OAuthService {
  constructor(
    protected ngZone: NgZone,
    protected http: HttpClient,
    @Optional() storage: OAuthStorage,
    @Optional() tokenValidationHandler: ValidationHandler,
    @Optional() protected config: AuthConfig,
    protected urlHelper: UrlHelperService,
    protected logger: OAuthLogger,
    @Optional() protected crypto: HashHandler,
    @Inject(DOCUMENT) document: any,
    protected dateTimeService: DateTimeProvider
  ) {
    super(
      ngZone,
      http,
      storage,
      tokenValidationHandler,
      config,
      urlHelper,
      logger,
      crypto,
      document,
      dateTimeService
    );
  }

  /**
   * Uses password flow to exchange userName and password for an access_token.
   * @param userName
   * @param password
   * @param baseSiteId Optional additional baseSiteId.
   */
  fetchTokenUsingPasswordFlowAndBaseSiteId(
    userName: string,
    password: string,
    baseSiteId?: string
  ): Promise<TokenResponse> {
    const parameters = {
      username: userName,
      password,
      baseSiteId,
    };
    return this.fetchTokenUsingGrant('password', parameters);
  }
}
