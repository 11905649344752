import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ResourcesService } from '../resources.service';

@Component({
  selector: 'app-browse-by-topic',
  templateUrl: './browse-by-topic.component.html',
  styleUrls: ['./browse-by-topic.component.scss']
})
export class BrowseByTopicComponent implements OnInit, OnDestroy {

  catalogTypes = [];
  list = [];
  selectedtype;
  catalogTypeSubscription: any;

  constructor(
    public service: ResourcesService,
    private readonly cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.service.getCatalogs();
    this.catalogTypeSubscription = this.service.catalogTypes$.subscribe((res) => {
      if(res !== null){
        this.catalogTypes = res.subcategories;
        this.catalogTypes = [{name: 'Tous', id:'m1'}].concat(this.catalogTypes);
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy(){
    this.catalogTypeSubscription.unsubscribe();
  }

  getCatalogType(type){
    this.service.getCatalogTypes(type);
  }

}
