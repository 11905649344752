import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { OrderHistoryService } from '../../../order-history/order-history.service';
import { CommonUtils } from '../../../shared/utils/common.utils';
import { NumberTen } from '../../../shared/utils/constants/global.constants';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, OnDestroy {
  isLoadingDone = false;
  ordersList = [];
  userId;
  orderDataSub: Subscription;
  userAccSubs: Subscription;
  constructor(
    private readonly service: OrderHistoryService,
    private readonly user: UserAccountFacade,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getOrderList();
  }

  getOrderList() {
    this.userAccSubs = this.user.get().subscribe((res) => {
      this.userId = res;
      if (res) {
        const params = { baseSiteId: 'fr', userId: this.userId.userName };
        this.service.getOrderHistory(params);
        this.orderDataSub = this.service.userData$.subscribe((result) => {
          if (result !== null) {
            this.ordersList = result?.orders;
            if (!!this.ordersList) {
              this.ordersList.forEach((element) => {
                const dateAndTime = element.placed.split('T');
                element.placedDateObj = dateAndTime[0];
                element.total = result?.orders[this.ordersList.indexOf(element)]?.total?.value;
              });
              this.ordersList =
                !!this.ordersList && this.ordersList.length >= NumberTen
                  ? this.ordersList.slice(0, NumberTen)
                  : this.ordersList.slice();
            } else {
              this.ordersList = [];
            }
            this.isLoadingDone = true;
            this.cdr.detectChanges();
          }
        });
      }
    });
  }

  sortColumn(event, fieldName, dataList, type): any {
    CommonUtils.onSortClick(event, fieldName, dataList, type); // Column Sorting
  }
  ngOnDestroy() {
    this.orderDataSub?.unsubscribe();
    this.userAccSubs?.unsubscribe();
  }
}
