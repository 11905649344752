import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as config from '../../config/app.config';
import { BaseService } from '../../services/base.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  uid: any;

  constructor(
    private readonly baseService: BaseService,
    ) {}


  sendFile(uid?, params?:any): Observable<any> {
    return this.baseService.post(
      `/users/${uid}${config.DOCUMENT_UPLOAD}`,
      params
    )
  }
}
