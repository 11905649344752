<div [ngClass]="{
    'square-banner':
      bannerComponent === 'AccountRegistrationConfirmationBannerComponent',
    'banner-Inf': bannerComponent === 'SanofiAccountOverviewBannerComponent6'
  }">
  <a *ngIf = "!!cmsResponse && !!imageUrl"  href="{{redirectionLink}}" target="_blank" [ngClass] = "{'disable-Link' : !redirectionLink}">
    <img src="{{ imageUrl }}" [ngClass]="{'banner': bannerComponent === 'OrderHistoryBannerComponent',
      'invoice-banner': bannerComponent === 'InvoiceBannerComponent',
      'influenza-Banner': bannerComponent === 'SanofiFluDashboardBannerComponent'}" alt="order" />
  </a>
</div>