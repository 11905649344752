import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { CustomBannerComponent } from './custom-banner.component';
import {CustomNavigationModule} from "../custom-navigation/custom-navigation.module";

@NgModule({
  declarations: [CustomBannerComponent],
  imports: [CommonModule, RouterModule, CustomNavigationModule, MediaModule],
  providers: [
    provideConfig({
      cmsComponents: {
        SimpleResponsiveBannerComponent: {
          component: CustomBannerComponent,
        },
        BannerComponent: {
          component: CustomBannerComponent,
        },
        SimpleBannerComponent: {
          component: CustomBannerComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class CustomBannerModule {}
