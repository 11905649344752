<section class="product-refinement" *ngIf="(productList$ | async) as productList">
    <section class="header">
        <aside aria-label="Product Category" class="content content-left">

           <div class="selectedWholesalerSection" *ngIf="isIndirectPage()">
                <div class="mb-2">
                    <span class="bold {{!userDetails.wholesalerId ? 'noWholesalerSelected' : 'wholesalerSelected'}}">{{'account.selectedWholesaler'|cxTranslate}}: <span *ngIf="!userDetails.wholesalerId" class="noWholesalerSelected"> - </span></span>
                    <span class="wholesalerName">{{userDetails?.wholesalerDisplayName}}</span>
                    <div class="switch-whs-popup mt-2">
                    <a [routerLink]="['/select-whs']" class="change-wholesaler">{{'account.changeWholesaler'| cxTranslate}}</a>
                    </div>
                </div>
            </div>

            <section *ngIf=false class="selectBox">
                <b>{{'plp.category'|cxTranslate}} </b>
                <ng-select [items]="categoryFormat" bindLabel="name" bindValue="value" [multiple]="false"
                    name="selectedCategory" placeholder="Select Category" [(ngModel)]="selectedCategory">
                </ng-select>
            </section>
        </aside>
    </section>
    <section class="main">

        <aside aria-label="Product Filterby" class="content content-left">
            <section class="title" *ngIf="!isIndirectPage() && facets$?.length>0">
                <section class="filterByTitle-main">{{'plp.filterby' | cxTranslate}}</section>
                <cx-facet class="facets" *ngFor="let facet of facets$" #facetRef [facet]="facet"></cx-facet>

            </section>
            <section class="title" *ngIf="productList?.products?.length==0 && productList?.freeTextSearch?.length>0">

                <section class="filterByTitle-main explorer">{{'plp.explore' | cxTranslate}}</section>
                <ul class="explore-list"  *ngIf="userDetails != undefined">
                    <li>
                        <a href="/fr/fr/EUR/Open-Catalogue/c/1" class="products-link">
                            {{'plp.exploreProducts'|cxTranslate}}</a>
                    </li>
                    <li>
                        <a href="/fr/fr/EUR/influenza/dashboard" class="products-link">
                            {{'plp.exploreInfluenza'|cxTranslate|titlecase}}</a>
                    </li>
                </ul>
                <section *ngIf="userDetails == undefined">
                <a href="/fr/fr/EUR/Open-Catalogue/c/1" class="products-link">
                    {{'plp.products'|cxTranslate|titlecase}}</a>
                </section>
            </section>

        </aside>
    </section>
    <ng-template #facetsRwd>
        <section class="modal-class">
        <section class="modal-heading">
            <h2><span class="close-icon" aria-hidden="true" (click)="hide()">&times;</span></h2>
            <h2 class="select-heading">{{'plp.selectRefinement'|cxTranslate}}</h2>
        </section>
        <section class="filterByTitle-main"
            *ngIf="productList?.products?.length>0 || productList?.freeTextSearch?.length==0 else noProductsExplorer">
            {{'plp.filterby' | cxTranslate}}</section>
        <ng-template #noProductsExplorer>
            <section class="filterByTitle-main explore-refine">{{'plp.explore' | cxTranslate}}</section>
        </ng-template>
        <section class="facets">
            <cx-facet class="facets" *ngFor="let facet of facets$" #facetRef [facet]="facet"></cx-facet>
        </section>
        <span id="clearAll-filters">
            <a href="/fr/fr/EUR/Open-Catalogue/c/1" data-di-id="di-id-37774359-a6b0cba1"
                *ngIf="productList?.products?.length>0 || productList?.freeTextSearch?.length==0 else noProducts">
                {{'plp.clearFliter'|cxTranslate}}</a>
            <ng-template #noProducts>
                <a href="/fr/fr/EUR/Open-Catalogue/c/1" class="float-left products-link">
                    {{'plp.products'|cxTranslate|titlecase}}</a>
            </ng-template>
        </span>
        </section>
    </ng-template>
    <button class="btn btn-default" id="refineBtn" data-di-id="#refineBtn" (click)="refine()">
        {{'plp.refineButton'|cxTranslate}}<span class="activeclass"
            *ngIf="activefacets">({{activefacets}})</span><span></span>
    </button>


</section>
