import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { OpenLinkPopUpComponent } from '../shared/modules/open-link-popup/open-link-popup';
import { ResourcesBannerModule } from '../shared/modules/resources-banner/resources-banner.module';
import { MainPipeModule } from '../shared/pipes/mainpipe.module';
import { BrowseByTopicComponent } from './browse-by-topic/browse-by-topic.component';
import { DisplayImgComponent } from './display-img/display-img.component';
import { HealthcareProviderInformationComponent } from './healthcare-provider-information/healthcare-provider-information.component';
import { PatientEducationComponent } from './patient-education/patient-education.component';

@NgModule({
  declarations: [
    PatientEducationComponent,
    HealthcareProviderInformationComponent,
    BrowseByTopicComponent,
    DisplayImgComponent,
    OpenLinkPopUpComponent,
  ],
  imports: [
    CommonModule,
    ResourcesBannerModule,
    FluDashboardBannerModule,
    NgSelectModule,
    FormsModule,
    I18nModule,
    MainPipeModule,
  ],
  providers: [
    provideOutlet({
      id: 'patientEducation-Horizontal',
      position: OutletPosition.REPLACE,
      component: PatientEducationComponent,
    }),
    provideOutlet({
      id: 'patientEducation-vertical',
      position: OutletPosition.REPLACE,
      component: BrowseByTopicComponent,
    }),
  ],
})
export class ToolsAndResourcesModule {}
