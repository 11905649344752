import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { ForgotUserPasswordService } from '../forgot-user-password.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  userIdRequired = false;
  passwordRequired = false;
  
  constructor(
    private readonly fb: FormBuilder,
    private readonly forgotUserPasswordService: ForgotUserPasswordService,
    private readonly route: RoutingService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      userId: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  validateUsername(){
    if(!this.loginForm.controls.userId.errors?.required){
      this.userIdRequired = false;
    }
  }

  validatePassword(){
    if(!this.loginForm.controls.password.errors?.required){
      this.passwordRequired = false;
    }
  }

  onSubmit() {
    if(this.loginForm.invalid){
      if(this.loginForm.controls.userId.errors?.required){
        this.userIdRequired = true;
      }
      if(this.loginForm.controls.password.errors?.required){
        this.passwordRequired = true;
      }
      return false;
    }
    this.forgotUserPasswordService.loginWithCredentials(
      this.loginForm.value.userId,
      this.loginForm.value.password
    );
    return true;
  }
}
