import { Pipe } from '@angular/core';

@Pipe({
  name: 'customTruncate',
})
export class CustomTruncatePipe {
  transform(value: string, args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

//How to use in mark up
// {{ str | customTruncate:[20] }}  or
// {{ str | customTruncate:[20, '...'] }}
