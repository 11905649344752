
    <div class="modal-header overlay-heading">
        <h4 class="modal-title checkout-title restore-header">
            You are about to leave a Sanofi Canada website  </h4>
    </div>
    
    <div class="modal-body text-center confirm-order">
        <p> 
            Sanofi does not review the information contained on this website and/or database for content, accuracy or completeness. Use of and access to this information is subject to the terms, limitations and conditions set by the website and/or database producer. Sanofi makes no representation as to the accuracy or any other aspect of the information contained on such website and/or database, nor does Sanofi necessarily endorse such website and/or database. 
           
        </p>
    
        <button type="submit" class="btn btn-default blue-button blue_white " (click)="redirectto()"> Proceed
        </button>
        <button type="button" class="btn btn-default white-button" (click)="dismissModal()">Cancel
        </button>
    </div>