import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { MakeAPaymentService } from 'src/app/custom-make-a-payment/make-a-payment.service';
import { CustomProfileAndPreferencesService } from 'src/app/custom-profile-and-preferences/custom-profile-and-preferences.service';
import { AddCardService } from '../../add-card/add-card.service';
 
@Component({
  selector: 'app-externalsite-alert',
  templateUrl: './externalsite-alert.component.html',
  styleUrls: ['./externalsite-alert.component.scss']
})
export class ExternalsiteAlertComponent implements OnInit {
   
  @Input() public eventReference;
  constructor(
    private readonly modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }
  redirectto(){
    window.open(this.eventReference);
    this.modalService.dismissAll();
  }
  dismissModal(){
    this.modalService.dismissAll();
  }
}