import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {

  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Allow: Backspace, Tab, Enter, Escape, and Delete
    if (event.key === 'Backspace' || event.key === 'Tab' || event.key === 'Enter' || event.key === 'Escape' || event.key === 'Delete' ||
      // Allow: Ctrl+A
      (event.key === 'a' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.key === 'c' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.key === 'v' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.key === 'x' && (event.ctrlKey || event.metaKey)) ||
      // Allow: home, end, left, right
      (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Home' || event.key === 'End')) {
      // Let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((event.key < '0' || event.key > '9') &&
      (event.key < '0' || event.key > '9')) {
      event.preventDefault();
    }
  }
}
