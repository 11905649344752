import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService } from '@spartacus/core';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { OpenLinkPopUpComponent } from '../../shared/modules/open-link-popup/open-link-popup';
import { DisplayImgComponent } from '../display-img/display-img.component';
import { ResourcesService } from '../resources.service';
declare const require: any;

@Component({
  selector: 'app-patient-education',
  templateUrl: './patient-education.component.html',
  styleUrls: ['./patient-education.component.scss'],
})
export class PatientEducationComponent implements OnInit, OnDestroy {
  catalogsList: any = [];
  baseUrl = this.occEndPoint.getBaseUrl({
    baseUrl: true,
    prefix: false,
    baseSite: false,
  });
  fileUrl: any;
  defaultImg = '../../../assets/img/sanofi_missing_product.jpg';
  catalogsDataSubscription: any;

  constructor(
    private readonly service: ResourcesService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly cd: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly sanitizer: DomSanitizer,
    private readonly route: ActivatedRoute,
    private _dataLayerService: DataLayerService
  ) {}

  ngOnInit() {
    // to filter by categoryes change m1 , i.e pharmacovigilance-material
    this.route.queryParams.subscribe((params) => {
      if (params != undefined && params.cat != undefined) {
        this.service.getCatalogTypes(params.cat);
      } else {
        this.service.getCatalogTypes('m1');
      }
    });

    this.catalogsDataSubscription = this.service.catalogs$.subscribe((res) => {
      if (res !== null) {
        if (res && res.categoryData && res.categoryData.length) {
          this.catalogsList = res.categoryData[0].products;
        }
        this.cd.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.catalogsDataSubscription.unsubscribe();
  }

  downloadFile(catalog, downloadUrl, name) {
    if (downloadUrl && downloadUrl.url) {
      const pdfUrl = `${this.baseUrl}${downloadUrl.url}`;
      const pdfName = `${name}.pdf`;
      const FileSaver = require('file-saver');
      FileSaver.saveAs(pdfUrl, pdfName);
      this._dataLayerService.downloadDataLayer(catalog);
    }
  }

  openNewModalPopup(image) {
    const modalRef = this.modalService.open(DisplayImgComponent, {
      size: 'md',
      scrollable: false,
      windowClass: 'patient-edu-modal',
    });
    const img = `${this.baseUrl}${image.url}`;
    modalRef.componentInstance.image = img;
  }

  openLinkPopup(urlLink) {
    const modalRef = this.modalService.open(OpenLinkPopUpComponent, {
      size: 'md',
      scrollable: false,
      windowClass: 'open-link-modal',
    });
    modalRef.componentInstance.linkUrl = urlLink;
    modalRef.componentInstance.message = 'externalpopover.modalContentBody';
  }

  getImage(image) {
    if (image && image.url) {
      return `${this.baseUrl}${image.url}`;
    } else {
      return this.defaultImg;
    }
  }
}
