import { Pipe, PipeTransform } from '@angular/core';
import {formatCurrency, getCurrencySymbol} from '@angular/common';
@Pipe({
  name: 'invoiceCurrency',
})
export class InvoiceCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    locale: string = 'fr-FR',
  ): string | null {
    const options = { minimumFractionDigits: 2 };
    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
  }
}
