import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as config from '../../config/app.config';
import { BaseService } from '../../services/base.service';


@Injectable({
  providedIn: 'root'
})
export class LoactionStripService {
  userAccountType: any;
  userAccountData: any;
  constructor(private readonly baseService: BaseService) {
    this.userAccountData = new Subject();
   }

  public getUserAccountDataFromDb(userId): void {
    this.baseService
      .get(config.USER_ACCOUNT + userId + '/b2bUnits', { fields: 'FULL' })
      .subscribe((res) => {
        if (res) {
          this.userAccountType = res.type;
          this.userAccountData.next({ res });
        }
      });
  }
}
