import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../../../services/base.service';
import * as config from '../../../config/app.config';
import { Occ } from '@spartacus/core';
import B2BUnit = Occ.B2BUnit;

@Injectable({
  providedIn: 'root'
})
export class SelectWhsPopupService {
  constructor(
    public baseService: BaseService,
  ) {
  }

  private enableParentSource = new BehaviorSubject<boolean>(true);
  enableParent$ = this.enableParentSource.asObservable();

  enableParent(): void {
    this.enableParentSource.next(true);
  }

  disableParent(): void {
    this.enableParentSource.next(false);
  }

  getRegisteredWhsForB2BUnit(b2bUnitUid): Observable<B2BUnit[]> {
    return this.baseService
      .get(`/b2bUnits/${b2bUnitUid}/registeredWholesalers`, {fields: 'DEFAULT'});
  }

  public getUserAccountDataFromDb(userId): Observable<B2BUnit> {
    return this.baseService
      .get(`${config.USER_ACCOUNT}${userId}/b2bUnits/`, {fields: 'FULL'});
  }

  updateWholesalerForUsername(username: string, wholesalerId: string): Observable<any> {
    return this.baseService.patch(`/b2bUnits?emulatedUser=${username}`, wholesalerId);
  }
}
