import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NgbPopover,
  NgbPopoverConfig,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CmsService,
  OccEndpointsService,
  RoutingService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { CustomInfluenzaService } from 'src/app/custom-influenza/custom-influenza.service';
import { SubscriptionsContainer } from 'src/app/shared/utils/subscriptions-container';
import * as _ from 'underscore';
import { CustomPdpService } from '../../custom-pdp/custom-pdp.service';
import { CustomProdPgService } from '../../custom-prod-pg/custom-prod-pg.service';
import { OrderHistoryService } from '../../order-history/order-history.service';
import { CheckoutService } from '../../shared/modules/checkout/checkout.service';
import { CustomAddToCartService } from '../custom-add-to-cart.service';

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss'],
})
export class MiniCartComponent implements OnInit, OnDestroy {
  name = 'World';
  itemInCart = false;
  userDetails: any;
  isFlu = false;
  isIndirect = false;
  currentPage: any;
  standardCartDetails: any;
  influenzaCartDetails: any;
  indirectCartDetails: any;
  userAccountgetData: any;
  stdCartEntries: any;
  indCartEntries: any;
  totalquantityCart: any;
  totalquantityfluCart: any;
  totalQuantityIndirectCart: any;
  fluCartDetails: any;
  fluCartEntries: any;
  productEntries: any = [];
  searchedClientList: any;
  selectedData: any;
  productFluEntries = [];
  productIndirectEntries: any = [];
  stdCartEntry = 0;
  isEmpty = true;
  allCartDetails: any;
  stdCartDetails: any;
  indCartDetails: any;
  public images: any;
  totalQuantity: any = [];
  totalQuantityFlu: any = [];
  totalQuantityIndirect: any = [];
  quantityDisplay: any;
  quantityDisplayFlu: any;
  quantityDisplayIndirect: any;
  saveCartName: string;
  usergetDataObj: any;
  getuserData: any;
  baseUrl: string;
  cartId: any;
  isEmptyFlu = false;
  isEmptyIndirect = true;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  errorMsg: string;
  cartSavedSuccess: string;
  cartItems: any;
  @ViewChild('p1') public emptypopover: NgbPopover;
  @ViewChild('popTitleSuccess', { static: false })
  private readonly popTitleSuccess;
  @ViewChild('p2') public stdpopover: NgbPopover;
  @ViewChild('t') public tooltip: NgbTooltip;
  @ViewChild('p4') public flupopover: NgbPopover;
  @ViewChild('p3') public indirectpopover: NgbPopover;
  @ViewChild('s1') public savedpopover: NgbPopover;
  @ViewChild('s3') public saveorderpopover: NgbPopover;
  @ViewChild('c1') public sessionCartPopover: NgbPopover;
  updatedStdSub: Subscription;
  plpMiniCartSub: Subscription;
  pdpMiniCartSub: Subscription;
  updateSessionSub: Subscription;
  restoreSub: Subscription;
  saveOrderSub: Subscription;
  orderHistorySub: Subscription;
  allCartItemsSub: Subscription;
  userAccountSub: Subscription;
  userAccountDetailsSub: Subscription;
  cartItemsSub: Subscription;
  invalidCartSub: Subscription;
  saveCartSub: Subscription;
  userAccSub: Subscription;
  showCartIcon: boolean = true;
  subs = new SubscriptionsContainer();

  constructor(
    private readonly user: UserAccountFacade,
    private readonly addToCart: CustomAddToCartService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: RoutingService,
    private readonly pageDate: CmsService,
    private readonly config: NgbPopoverConfig,
    private readonly occEndPoint: OccEndpointsService,
    private readonly plpService: CustomProdPgService,
    private readonly pdpService: CustomPdpService,
    private readonly checkoutService: CheckoutService,
    private readonly orderHistoryService: OrderHistoryService,
    private readonly influenzaService: CustomInfluenzaService
  ) {
    config.autoClose = 'outside';
    config.closeDelay = 2000;
    config.openDelay = 1000;
  }
  tooltipToggle() {
    this.tooltip.open();
  }
  ngOnInit(): void {
    this.itemInCart = true;
    this.updatedStdSub = this.checkoutService.updatedStdOrderDetails$.subscribe(
      (res) => {
        if (res) {
          this.addToCart.getAllCarts(this.userAccountgetData?.userName);
          this.plpService.getCarts(this.userDetails?.userName);
          this.addToCart.cartItems.next(false);
        }
      }
    );
    this.plpMiniCartSub = this.plpService
      .getUpdateMiniCart()
      .subscribe((res) => {
        if (res) {
          this.getCartDetails(res);
        }
      });
    this.pdpMiniCartSub = this.pdpService
      .getUpdateMiniCart()
      .subscribe((res) => {
        if (res) {
          this.getCartDetails(res);
        }
      });
    this.updateSessionSub = this.addToCart
      .getUpdateSessionCart()
      .subscribe((res) => {
        if (res) {
          this.getCartDetails(res);
        }
      });
    this.restoreSub = this.addToCart.getRestoredCart().subscribe((res) => {
      if (res) {
        this.getCartDetails(res);
        this.route.goByUrl('/cart');
      }
    });
    this.saveOrderSub = this.addToCart
      .getUpdateSavedOrder()
      .subscribe((res) => {
        if (res) {
          this.cartSavedSuccess = res?.savedCartData?.name;
          this.savedpopover.open();
          setTimeout(() => this.savedpopover.close(), 2500);
        }
      });
    this.orderHistorySub = this.orderHistoryService
      .getSavedOrderName()
      .subscribe((res) => {
        if (res) {
          this.cartSavedSuccess = res?.savedCartData?.name;
          this.savedpopover.open();
          setTimeout(() => this.savedpopover.close(), 2500);
        }
      });

    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });

    this.subs.add = this.pageDate.getCurrentPage().subscribe((res) => {
      this.currentPage = res?.pageId;

      if (res) {
        const sessionCartPopover = res?.slots?.MiniCart;
        if (Object.keys(sessionCartPopover).length === 0) {
          sessionStorage.clear();
        }
      }

      if (
        this.currentPage === 'fluseasonreservationpage' ||
        this.currentPage === 'FluSeasonProductListPage' ||
        this.currentPage === 'fluseasonviewcartpage' ||
        this.currentPage === 'fluseasonviewpaymentpage' ||
        this.currentPage === 'flureservationconfirmationpage' ||
        this.currentPage === 'flureservationoverviewpage' ||
        this.currentPage === 'ScheduleToShipPage'
      ) {
        this.isFlu = true;
        // Hide show cart if flu season has order
        this.influenzaService.ordersHistory.subscribe((previousOrderData) => {
          if (previousOrderData?.orders.length >= 1) {
            this.showCartIcon = false;
            sessionStorage.setItem('flu-has-order', 'yes');
          } else {
            this.showCartIcon = true;
            sessionStorage.setItem('flu-has-order', 'no');
          }
          this.cdr.detectChanges();
        });
      } else if (this.isIndirectPage()) {
        this.isIndirect = true;
        this.isFlu = false;
        this.showCartIcon = true;
      } else {
        this.isIndirect = false;
        this.isFlu = false;
        this.showCartIcon = true;
        sessionStorage.removeItem('flu-has-order');
      }
      this.cdr.detectChanges();
    });

    this.userAccSub = this.user.get().subscribe((res) => {
      const miniCart = document.getElementById(`miniCart`) as HTMLElement;
      if (res && res !== null) {
        this.userDetails = res;
        miniCart.classList.remove('not-visible');
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData?.userName);
        this.addToCart.getAllCarts(this.userAccountgetData?.userName);
        this.allCartItemsSub = this.addToCart.allCartItems$.subscribe(
          (res1) => {
            if (res1) {
              this.cartItems = res1?.carts;
              this.cartItems = this.cartItems?.filter(
                (item) => item?.saveTime === undefined
              );
              this.cartItems = this.cartItems?.[this.cartItems?.length - 1];
              if (this.cartItems === undefined) {
                this.isEmpty = true;
                this.isEmptyFlu = true;
                this.isEmptyIndirect = true;
                this.quantityDisplay = '';
                this.quantityDisplayIndirect = '';
                this.addToCart.cartItems.next(false);
                this.cdr.detectChanges();
              }
              this.cartId = this.cartItems?.code;
              this.getCartDetails(this.cartId);
            }
          }
        );
      } else {
        miniCart.classList.add('not-visible');
        this.addToCart.deleteCartCache();
        this.allCartItemsSub?.unsubscribe();
      }
    });
    //  this.cdr.detectChanges()
  }

  isIndirectPage(): boolean {
    return this.currentPage === 'IndirectCartPage' || this.currentPage === 'IndirectCheckoutPage' || window.location.href.indexOf('productType:INDIRECT') != -1
  }

  getUserAccountData(userId): void {
    this.addToCart.getUserAccountDataFromDb(userId);
    this.userAccountSub = this.addToCart.userAccountData.subscribe(
      (userData) => {
        if (
          userData.res != null &&
          userData.res !== undefined &&
          userData.res?.length !== 0
        ) {
          this.usergetDataObj = userData.res.defaultB2BUnit;
          const accountId = this.usergetDataObj.uid;
          this.addToCart.getUserAccountDetailsDataFromDb(userId, accountId);
          this.userAccountDetailsSub =
            this.addToCart.userAccountDetails.subscribe((userDataget) => {
              if (userDataget) {
                this.getuserData = userDataget.res.shippingAddresses;
                this.searchedClientList = userDataget.res.shippingAddresses;
                if (this.getuserData) {
                  this.selectedData = this.getuserData[0];
                  this.saveCartName = this.selectedData?.companyName;
                }
              }
            });
          this.cdr.detectChanges();
        }
      }
    );
  }
  getCartDetails(cartId): void {
    this.addToCart.getCarts(cartId, this.userAccountgetData?.userName);
    this.cartItemsSub = this.addToCart.cartItems$.subscribe((cartData: any) => {
      if (cartData) {
        this.cartDataMapping(cartData);
      }
    });
  }

  navigatetoProduct(code) {
    this.route.goByUrl(`/product/${code}/${code}`);
  }
  cartDataMapping(data): void {
    this.productEntries = [];
    this.productFluEntries = [];
    this.productIndirectEntries = [];
    this.cartId = data?.code;
    this.allCartDetails = data?.childCarts;
    this.stdCartEntry = data.totalItems;
    if (this.allCartDetails) {
      this.stdCartDetails = this.allCartDetails.filter(
        (item) => item.cartType === 'STANDARD'
      );
      this.standardCartDetails = Object.assign({}, ...this.stdCartDetails);
      if (this.stdCartDetails.length <= 0) {
        this.isEmpty = true;
      } else if (this.stdCartDetails.length > 0) {
        this.isEmpty = false;
      }
      for (const cartDetails of this.stdCartDetails) {
        this.stdCartEntries = cartDetails;
        for (let entries of cartDetails.entries) {
          const imagesFormat = _.clone(
            _.findWhere(entries.product?.images, { format: 'thumbnail' })
          );
          if (imagesFormat) {
            imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
          }
          this.images = { PRIMARY: imagesFormat };
          entries = { ...entries, ...this.images };
          this.productEntries.push(entries);
          const quantity = [];
          for (const total of this.stdCartEntries?.entries) {
            quantity.push(total.quantity);
            this.totalquantityCart = quantity;
          }
          this.totalQuantity = this.totalquantityCart.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          if (this.totalQuantity > 99) {
            this.quantityDisplay = '99+';
          } else {
            this.quantityDisplay = this.totalQuantity;
          }

          if (!sessionStorage.getItem('sessionCartPopover')) {
            if (this.sessionCartPopover) {
              this.sessionCartPopover.open();
              setTimeout(() => this.sessionCartPopover.close(), 2500);
            }
            sessionStorage.setItem('sessionCartPopover', 'true');
          }
        }
        this.cdr.detectChanges();
      }
      //
      this.fluCartDetails = this.allCartDetails.filter(
        (item) => item.cartType === 'INFLUENZA'
      );

      this.influenzaCartDetails = Object.assign({}, ...this.fluCartDetails);
      this.isEmptyFlu =
        this.influenzaCartDetails?.entries?.length <= 0 ||
        this.fluCartDetails?.length <= 0
          ? true
          : false;
      this.quantityDisplayFlu = '';
      for (const cartDetails of this.fluCartDetails) {
        this.fluCartEntries = cartDetails;
        for (let entries of cartDetails.entries) {
          const imagesFormat = _.clone(
            _.findWhere(entries.product?.images, { format: 'thumbnail' })
          );
          if (imagesFormat) {
            imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
          }
          this.images = { PRIMARY: imagesFormat };
          entries = { ...entries, ...this.images };
          this.productFluEntries.push(entries);
          const quantity = [];
          for (const total of this.fluCartEntries?.entries) {
            quantity.push(total.quantity);
            this.totalquantityfluCart = quantity;
          }
          this.totalQuantityFlu = this.totalquantityfluCart.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          if (this.totalQuantityFlu > 99) {
            this.quantityDisplayFlu = '99+';
          } else {
            this.quantityDisplayFlu = this.totalQuantityFlu;
          }
        }
        this.cdr.detectChanges();
      }

      // INDIRECT
      this.indCartDetails = this.allCartDetails.filter(
        (item) => item.cartType === 'INDIRECT'
      );
      this.indirectCartDetails = Object.assign({}, ...this.indCartDetails);
      if (this.indCartDetails.length <= 0) {
        this.isEmptyIndirect = true;
      } else if (this.indCartDetails.length > 0) {
        this.isEmptyIndirect = false;
      }
      this.quantityDisplayIndirect = '';
      for (const cartDetails of this.indCartDetails) {
        this.indCartEntries = cartDetails;
        for (let entries of cartDetails.entries) {
          const imagesFormat = _.clone(
            _.findWhere(entries.product?.images, { format: 'thumbnail' })
          );
          if (imagesFormat) {
            imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
          }
          this.images = { PRIMARY: imagesFormat };
          entries = { ...entries, ...this.images };
          this.productIndirectEntries.push(entries);
          const quantity = [];
          for (const total of this.indCartEntries?.entries) {
            quantity.push(total.quantity);
            this.totalQuantityIndirectCart = quantity;
          }
          this.totalQuantityIndirect = this.totalQuantityIndirectCart.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          );
          if (this.totalQuantityIndirect > 99) {
            this.quantityDisplayIndirect = '99+';
          } else {
            this.quantityDisplayIndirect = this.totalQuantityIndirect;
          }

          if (!sessionStorage.getItem('sessionCartPopover')) {
            if (this.sessionCartPopover) {
              this.sessionCartPopover.open();
              setTimeout(() => this.sessionCartPopover.close(), 2500);
            }
            sessionStorage.setItem('sessionCartPopover', 'true');
          }
        }
        this.cdr.detectChanges();
      }
    }
  }
  toggleWithGreeting(popover) {
    if (this.isFlu) {
      this.route.goByUrl('/influenza/cart');
    } else if (this.isIndirect) {
      this.route.goByUrl('/indirect/cart');
    } else {
      this.route.goByUrl('/cart');
    }
  }

  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg = '';
    this.saveCartName = this.selectedData?.companyName;
  }
  navigateToPdp() {
    this.emptypopover.close();
    this.route.goByUrl('/Open-Catalogue/c/1');
  }
  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCart.saveCartDetails(this.saveCartName, this.cartId);
      this.invalidCartSub = this.addToCart.invalidCartName$.subscribe((res) => {
        if (res) {
          for (const error of res?.error?.errors) {
            this.errorMsg = error?.message;
          }
        }
      });
      this.saveCartSub = this.addToCart.saveCartSuccess$.subscribe((res) => {
        if (res) {
          this.stdpopover.close();
        }
      });
    } else {
      if (this.saveCartName) {
        this.errorMsg = 'Save cart name is not valid.';
      } else {
        this.errorMsg = 'Cart name cannot be blank';
      }
    }
  }

  navigateToCart() {
    this.stdpopover.close();
    this.route.goByUrl('/cart');
  }

  navigateToSavedOrder() {
    this.emptypopover.close();
    this.route.goByUrl('/cart/save');
  }
  navigateTominiSavedOrder() {
    this.route.goByUrl('/cart/save');
  }
  navigateToFlu() {
    this.emptypopover.close();
    this.route.goByUrl('/influenza/dashboard');
  }
  navigateToFlufromCart() {
    this.flupopover.close();
    this.route.goByUrl('/influenza/cart');
  }
  navigateToIndirectCart(): void {
    this.indirectpopover.close();
    this.route.goByUrl('/indirect/cart');
  }

  closepop() {
    if (this.stdpopover) {
      this.stdpopover.close();
    }
    if (this.emptypopover) {
      this.emptypopover.close();
    }
    if (this.flupopover) {
      this.flupopover.close();
    }
    if (this.indirectpopover) {
      this.indirectpopover.close();
    }
    this.errorMsg = '';
    this.saveCartName = this.selectedData?.companyName;
  }
  omit_special_char(event) {
    this.errorMsg = '';
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    return new RegExp(reg).test(event.key);
  }
  ngOnDestroy() {
    this.updatedStdSub?.unsubscribe();
    this.plpMiniCartSub?.unsubscribe();
    this.pdpMiniCartSub?.unsubscribe();
    this.updateSessionSub?.unsubscribe();
    this.restoreSub?.unsubscribe();
    this.saveOrderSub?.unsubscribe();
    this.orderHistorySub?.unsubscribe();
    this.allCartItemsSub?.unsubscribe();
    this.userAccountSub?.unsubscribe();
    this.userAccountDetailsSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.invalidCartSub?.unsubscribe();
    this.saveCartSub?.unsubscribe();
    this.userAccSub?.unsubscribe();
    this.subs.dispose();
  }
}
