import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-open-link-popup',
  templateUrl: './open-link-popup.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenLinkPopUpComponent {
  @Input() public message;
  @Input() public linkUrl;

  constructor(private modalService: NgbModal, private cdr: ChangeDetectorRef) {}

  closeModal(): void {
    this.modalService.dismissAll();
  }
}

// Use with NgbModal
// Pass Message and LinkUrl
// Example usage from parent component:
// openLinkPopup(urlLink) {
//   const modalRef = this.modalService.open(OpenLinkPopUpComponent, {
//     size: 'md',
//     scrollable: false,
//     windowClass: 'open-link-modal',
//   });
//   const url = urlLink;
//   modalRef.componentInstance.linkUrl = urlLink;
//   modalRef.componentInstance.message = 'externalpopover.modalContentBody';
// }
