import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-products',
  templateUrl: './order-products.component.html',
  styleUrls: ['./order-products.component.scss']
})
export class OrderProductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
