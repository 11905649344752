import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from '../../shared/utils/productUtils';
import { CustomProdPgService } from '../custom-prod-pg.service';
@Component({
  selector: 'app-custom-product-card',
  templateUrl: './custom-product-card.component.html',
  styleUrls: ['./custom-product-card.component.scss'],
})
export class CustomProductCardComponent implements OnInit, OnDestroy {
  sessionStorage = sessionStorage;
  public productInfo: {
    available: boolean;

    availabilityText: string;
    batchInfo: {
      batchNumber: string;
      expiryDate: string;
      quantity: number;
    };
    images: any;
    code: string;
    configurable: boolean;
    configuratorType: string;
    description: string;
    discontinued: boolean;
    messageDangerousGoods: boolean;
    messageColdChain: boolean;
    sapAllocation: boolean;
    displayProduct: boolean;
    flu: boolean;
    cipCode: string;
    ucdCode: string;
    nameHtml: any;
    summary: any;
    productUpdate: string;
    productDocuments: {
      productDocument: [
        {
          url: string;
        }
      ];
    };
    name: string;
    orderable: boolean;
    quantityPerUnit: number;
    productType: string;
    productUpdates: string;
    productDocument: any;
    volumePrices: [
      {
        currencyIso: string;
        formattedValue: string;
        maxQuantity: string;
        minQuantity: string;
        priceType: string;
        value: number;
      }
    ];
    url: string;
    stock: {
      isValueRounded: boolean;
    };
    isValueRounded: boolean;
    yellowFever: boolean;
    volumePricesFlag: boolean;
  } = null;
  buttonName = 'Login to order';
  description: any;
  monograph: any;
  itemInCart: boolean = false;
  monographLink: any;
  @ViewChild('p2') public successPopover: NgbPopover;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  userDetails: any;
  @Input() iconDisplay: boolean;
  @Input() set product(p: {
    available: boolean;
    images: any;
    availabilityText: string;
    batchInfo: {
      batchNumber: string;
      expiryDate: string;
      quantity: number;
    };
    code: string;
    cipCode: string;
    ucdCode: string;
    configurable: boolean;
    configuratorType: string;
    description: string;
    discontinued: boolean;
    messageDangerousGoods: boolean;
    messageColdChain: boolean;
    sapAllocation: boolean;
    displayProduct: boolean;
    flu: boolean;
    url: string;
    productUpdates: string;
    productDocuments: {
      productDocument: [{ url: string }];
    };
    nameHtml: any;
    summary: any;
    productUpdate: string;
    name: string;
    orderable: boolean;
    quantityPerUnit: number;
    productType: string;
    productDocument: any;
    stock: {
      isValueRounded: boolean;
    };
    isValueRounded: boolean;
    yellowFever: boolean;
    volumePrices: [
      {
        currencyIso: string;
        formattedValue: string;
        maxQuantity: string;
        minQuantity: string;
        priceType: string;
        value: number;
      }
    ];
    volumePricesFlag: boolean;
  }) {
    this.productInfo = { ...p };
  }
  batchInfo: {};
  itemAddedQuantity: any;
  cartId: any;
  cartItems: any;
  successMsg = false;
  updateErrorSub: Subscription;
  userDataSub: Subscription;
  cartItemsSub: Subscription;
  createdCartSub: Subscription;
  updatedCartSub: Subscription;
  constructor(
    private readonly user: UserAccountFacade,
    private readonly config: NgbPopoverConfig,
    private readonly routingService: RoutingService,
    private readonly plpService: CustomProdPgService,
    private readonly sanitizer: DomSanitizer,
    private readonly translation: TranslationService,
    private globalMessage: GlobalMessageService,
    private readonly dataLayerService: DataLayerService
  ) {
    config.closeDelay = 2000;
    config.openDelay = 1000;
  }

  ngOnInit(): void {
    this.batchInfo = this.productInfo.batchInfo;
    this.monograph = this.productInfo.productDocuments;
    this.itemInCart = true;
    if (this.monograph) {
      for (const link of this.monograph?.productDocument) {
        this.monographLink = this.sanitizer.bypassSecurityTrustResourceUrl(
          link.url
        );
      }
    }
    this.description = this.productInfo.description;
    this.userDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (res !== undefined) {
        if (this.isIndirect() && this.isIndirectPage()) {
          this.buttonName = 'Add to cart';
        } else if (this.productInfo.flu === true || this.isIndirect()) {
          this.buttonName = 'Reserve now';
        } else {
          this.buttonName = 'Add to cart';
        }
      } else {
        this.buttonName = 'Login to order';
      }
    });
    this.cartItemsSub = this.plpService.cartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems.filter(
          (item) => item?.saveTime === undefined
        );
      }
    });
  }
  navigateToFlu() {
    this.routingService.goByUrl('/influenza/dashboard');
  }

  navigateToIndirectCatalog(): void {
    this.routingService.goByUrl('/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
  }
  performQuantityComputation(
    product,
    operator,
    idData,
    stepQuantity,
    minQuantity,
    maxQuantity,
    entryNumber
  ): void {
    try {
      ProductUtils.alterQuantity(
        operator,
        idData,
        stepQuantity,
        minQuantity,
        maxQuantity,
        'cart'
      );
    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
  }
  goToLogin() {
    this.routingService.goByUrl('/login');
  }
  addToCart(productCode, popover): void {
    this.successMsg = true;
    if (this.buttonName === 'Reserve now') {
      if (this.productInfo?.productType === 'INDIRECT') {
        this.navigateToIndirectCatalog();
      }
      else {
        this.navigateToFlu();
      }
    } else if (this.buttonName === 'Add to cart') {
      const inputElement = document.getElementById(
        `prodQuantity${productCode}`
      ) as HTMLInputElement;

      const params = {
        product: {
          code: productCode,
        },
        quantity: inputElement.value,
      };

      if (this.isIndirect()) {
        params['selectedB2BUnit'] = this.userDetails.wholesalerId;
      }

      this.itemAddedQuantity = inputElement.value;
      if (this.cartItems && this.cartItems?.length === 0) {
        this.plpService.createCart(this.userDetails?.userName);
        this.createdCartSub = this.plpService.createdCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.plpService.getCarts(this.userDetails?.userName);
            this.UpdateCartDetails(params);
          }
        });
      } else {
        if (this.cartId === undefined) {
          this.cartItems =
            this.cartItems && this.cartItems?.length > 0
              ? this.cartItems[this.cartItems?.length - 1]
              : [];
          this.cartId = this.cartItems?.code;
        }
        this.UpdateCartDetails(params);
      }
    }
  }
  UpdateCartDetails(params) {
    this.plpService.updateCart(params, this.userDetails?.userName, this.cartId);
    this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe(
      (res) => {
        if (res) {
          if (this.successMsg) {
            this.successPopover.open();
            setTimeout(() => this.successPopover.close(), 2500);
            // popover.remove();
            this.successMsg = false;
          }
        }
      }
    );
    this.updateErrorSub = this.plpService.updateError$.subscribe((res) => {
      if (res) {
        this.successMsg = false;
      }
    });
  }

  isIndirect(): boolean {
    return this.productInfo?.productType && this.productInfo?.productType === 'INDIRECT';
  }
  isIndirectPage(): boolean {
    return window.location.href.indexOf('productType:INDIRECT') != -1;
  }

  itemSelected(info) {
    this.routingService.goByUrl('/product/' + info.code + '/' + info.code);
    this.dataLayerService.selectItemDataLayer(info, 'standard process');
  }
  ngOnDestroy() {
    this.updateErrorSub?.unsubscribe();
    this.userDataSub?.unsubscribe();
    this.cartItemsSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
    this.updatedCartSub?.unsubscribe();
  }
}
