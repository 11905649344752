import { Component } from '@angular/core';
import { GenericLinkComponent } from '@spartacus/storefront';
import {OpenLinkPopUpComponent} from "../../shared/modules/open-link-popup/open-link-popup";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {
  GenericLinkComponentService
} from "@spartacus/storefront";

@Component({
  selector: 'cx-generic-link',
  templateUrl: './custom-generic-link.component.html',
  styleUrls: ['./custom-generic-link.component.scss'],
})
export class CustomGenericLinkComponent extends GenericLinkComponent {

  constructor(
    private readonly modalService: NgbModal,
    protected router: Router,
    protected service: GenericLinkComponentService,
  ) {
    super(router, service);
  }

  openLinkPopup(urlLink) {
    const modalRef = this.modalService.open(OpenLinkPopUpComponent, {
      size: 'md',
      scrollable: false,
      windowClass: 'open-link-modal',
    });
    modalRef.componentInstance.linkUrl = urlLink;
    modalRef.componentInstance.message = 'externalpopover.modalContentBody';
  }
}
