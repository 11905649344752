
<div class="standart-cart">
    <div class="row-20 checkout ch-prnt" id="leftCont-rightNav-cart">
        <aside class="col-md-4 col-sm-12 right-navigation float-right right-align cart-main-page" aria-labelledby="empty-cart">
            
        </aside>
       
        <section class="col-md-8 col-sm-12 left-section float-left cart-left">
            <div class="bold-12 empty-cart-content">
                <a href="#" title="" target="" data-di-id="di-id-ad47086c-dcb75898">
                    <h3 class="font-20">
                        {{'addToCartItems.emptyStdCart'|cxTranslate}}
                    </h3>
                </a>
                <div class="darkblue font-14 empty-cart-options" [innerHTML]="emptycontent">
            
                </div>
            </div>
        </section>
        <aside class="col-md-12 col-sm-12 right-navigation float-right right-align quick-order-section" aria-labelledby="quick-order-label">
            <div class="ad-product-details cartProductSaveDetails">
                <h3>{{'addToCartItems.addProducts'|cxTranslate}}</h3>
                <h6>{{'addToCartItems.quickOrder'|cxTranslate}}</h6>
                <p>{{'addToCartItems.searchItems'|cxTranslate}}</p>
                <app-quick-order></app-quick-order>
            </div>
        </aside>
        <section class="col-xs-12 col-sm-8 col-md-12 left-section">
        </section>
        </div>
    </div>