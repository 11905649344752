<section class="row registrationView" id="productList-area">
  <section class="col-md-8 col-sm-8 pay-section float-left account-reg-section">
    <app-user-header
      [selectedHeader]="header"
      [tableCategory]="creditInvoice"
    ></app-user-header>

    <app-account-information
      *ngIf="header == 'accountInformation'"
      [formDetails]="formData"
    ></app-account-information>

    <!-- user info form starts  -->

    <app-user-information
      *ngIf="header == 'userInformation'"
      [formDetails]="formData"
    ></app-user-information>
    <!-- user info form ends -->
  </section>
  <!-- <aside
    aria-labelledby="banner"
    class="col-md-4 col-sm-4 col-xs-12 float-right"
  >
    <app-need-assistance
      *ngIf="header !== 'confirmation'"
    ></app-need-assistance>
  </aside> -->
</section>
