
<div *ngIf="showDisclaimer" class="global-alerts">
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeDisclaimerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    {{"addToCartItems.disclaimerMsg" | cxTranslate}}
  </div>
</div>
<section [ngClass]="isSticky === true ? 'sticky' : 'header'" *ngIf="productEntries?.length>0">
  <section class="savings-total sticky-total">
    <h3>
      {{'addToCartItems.orderSubtotal'|cxTranslate}}
      <span *ngIf="!updateTotal;else noPrice">{{standardCartDetails?.subTotal?.value | localCurrency}}
      </span>{{'plp.cad'|cxTranslate}}
      <ng-template #noPrice>
        {{'addToCartItems.noprice'|cxTranslate}}
      </ng-template>
    </h3>
    <button *ngIf="!updateTotal" type="button" class="btn btn-default blue-button mt2 proceedCheckout"
      (click)="proceedToCheckoutOne()" [disabled]="orderBlock || checkoutClicked || yfEntry || wrongQuantity"
      [ngClass]="orderBlock || yfEntry? 'disableCTA' :''">
      <i class="fa fa-spinner fa-spin" *ngIf="checkoutClicked"></i>
      {{'addToCartItems.onsnProceedToCheckout'|cxTranslate}}
    </button>
    <button *ngIf="updateTotal" class="btn btn-default white-button mt2 updateOrder" (click)="updateCart()">
      {{'addToCartItems.updateOrder'|cxTranslate}}
    </button>
    <p class="mx0 mb1">
      {{'addToCartItems.disclaimerContinueBtnText'|cxTranslate}}
    </p>
  </section>
</section>
<section class="preheader no-print">
  <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
    <section class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0 float-left emptyheader"></section>
    <section class="col-lg-3 col-md-3 col-sm-6 col-xs-12 middle-row mt0 text-right float-left">
      <a href="/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT" target="" id="continueShoppingId" data-di-id="#continueShoppingId">
        <img src="../../assets/icons/chevron-left.svg" alt="" class="chevron-left">
        {{'addToCartItems.continueShopping'|cxTranslate}}
      </a>
    </section>
    <section class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-right float-right p0 header-links"
      *ngIf="productEntries?.length>0">
      <section class="save-document save-document-container  mb1 mt0 ">
        <ng-template #popSaveTitle>{{'addToCartItems.saveOrderTitle'|cxTranslate}}</ng-template>
        <ng-template #popSaveContent>
          <input type="text" class="save-order" required #name="ngModel" [(ngModel)]="saveCartName"
            (keypress)="omit_special_char($event)" maxlength="30">
          <span *ngIf="name.errors?.required" class="common-error required">{{errorMsg}}</span>
          <span class="common-error required">{{errorMsg}}</span>
          <button type="reset" class="popover-close btn white-button popover-cancel-button" (click)="toggle(p2)">
            {{'addToCartItems.cancel'|cxTranslate}}</button>
          <button class="btn blue-button popover-cancel-button" type="submit" data-di-id="di-id-3064f17d-e97cc628"
            (click)="saveOrder()">
            {{'addToCartItems.save'|cxTranslate}}</button>
        </ng-template>
        <ng-template #popTitle><span>
            {{'addToCartItems.clearTitle'|cxTranslate}}</span>
        </ng-template>
        <ng-template #popContent>
          <button type="reset" class="popover-close btn
                     white-button popover-cancel-button" data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p1)">
            {{'addToCartItems.cancel'|cxTranslate}}</button>
          <button class="btn blue-button popover-cancel-button" type="submit" data-di-id="di-id-3064f17d-e97cc628"
            (click)="clearChildCart()">
            {{'addToCartItems.clearCart'|cxTranslate}}</button>
        </ng-template>
        <a class="clear-cart clear-cart-img-section" [ngbPopover]=popContent [popoverTitle]=popTitle triggers="manual"
          #p1="ngbPopover" placement="top" (click)="toggle(p1)" container="body" popoverClass="popover-links">
          <img src="../../assets/icons/trash.svg" alt="Delete">
          <span>{{'addToCartItems.clearCart'|cxTranslate}}</span></a>

        <a href="javascript:void(0)" target="" aria-label="download as" class="xls_cart xls-cart-img-section"
          id="tealium-xls" data-di-id="#tealium-xls" (click)="exportXls()">
          <img src="../../assets/icons/excel.png" alt="">
          <span>{{'addToCartItems.xls'|cxTranslate}} </span></a>
        <a href="javascript:void(0)" onclick="window.print();" target="" id="printLinkId" class="print-cart-img-section"
          data-di-id="#printLinkId">
          <img src="../../assets/icons/print.svg" alt="">
          <span>{{'addToCartItems.print'|cxTranslate}} </span></a>
      </section>
    </section>
  </section>
</section>
<div *ngIf="deleteSuccess!==''" class="global-alerts">

  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button"
      data-di-id="di-id-4f6d7b93-70563360">×</button>
    <span class="delete-succ" [innerHTML]="deleteSuccess"></span>{{'addToCartItems.deleteSuccessMsg'|cxTranslate}}
  </div>
</div>
<div *ngIf="addSuccess" class="global-alerts">

  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button"
      data-di-id="di-id-4f6d7b93-70563360">×</button>
    {{'addToCartItems.addProdSuccess'|cxTranslate}}
  </div>
</div>
<div *ngIf="orderBlock && showDangerAlert && productEntries?.length>0" class="global-alerts">

  <div class="alert alert-danger alert-dismissable getAccAlert">
    <button (click)="closeDangerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
      type="button" data-di-id="di-id-4f6d7b93-70563360">×</button>
    <div *ngIf="orderBlockNonHcp else NA">
      {{'addToCartItems.orderBlockMsgNonHcp'|cxTranslate}}
    </div>
    <ng-template #NA>
      {{'addToCartItems.orderBlockMsg'|cxTranslate}}
    </ng-template>
  </div>
</div>
<section class="standart-cart" *ngIf="productEntries?.length>0; else emptyCart">
  <section class="row-20 checkout ch-prnt" id="leftCont-rightNav-cart">
    <aside class="col-md-4 col-sm-12 right-navigation float-right right-align cart-main-page"
      [ngClass]="isSticky === true ? 'header' : ''" aria-label="subTotal">
      <section class="savings-total">
        <h3>
          {{'addToCartItems.orderSubtotal'|cxTranslate}}
          <span *ngIf="!updateTotal;else noPrice">{{standardCartDetails?.subTotal?.value | localCurrency}}
          </span>{{'plp.cad'|cxTranslate}}
          <ng-template #noPrice>
            {{'addToCartItems.noprice'|cxTranslate}}
          </ng-template>
        </h3>
        <button *ngIf="!updateTotal" type="button" class="btn btn-default blue-button mt2 proceedCheckout no-print"
          (click)="proceedToCheckoutOne()" [disabled]="orderBlock || checkoutClicked || yfEntry || wrongQuantity"
          [ngClass]="orderBlock || yfEntry? 'disableCTA' :''">
          <i class="fa fa-spinner fa-spin" *ngIf="checkoutClicked"></i>
         {{'addToCartItems.onsnProceedToCheckout'|cxTranslate}}
        </button>
        <button *ngIf="updateTotal" class="btn btn-default white-button mt2 updateOrder" (click)="updateCart()">
         {{'addToCartItems.updateOrder'|cxTranslate}}
        </button>
        <p class="mx0 mb1">
          {{'addToCartItems.disclaimerContinueBtnText'|cxTranslate}}
        </p>
      </section>
    </aside>



    <ng-template #continueCheckout >
      <section class="questionnaire-popover">
        <div class="modal-dialog">
          <div class="modal-content overlay">
            <div class="modal-body confirm-order questions">

              <div *ngFor="let qType of questionnaireType | async; let q = index" class="mt-4">
                <h2>{{"addToCartItems.prescriptionHeading" | cxTranslate }} <strong class="qustionType">{{qType.code}}</strong></h2>
                <div *ngIf="qType.code === 'PRALUENT'" class="mb-4">
                  <ul *ngFor="let praluentAnswers of allPraluentType$ | async; let i = index" class="question-section praluent" ngClass="question{{i}}">
                    <li class="question-label">{{ i + 1 }}. {{ questionsPraluent[i].text }}</li>
                    <li *ngFor="let pOption of praluentAnswers; let j = index" class="question-list" ngClass="{{pOption?.code}}">
                      <label>
                        <span class="optionRadio"><input type="radio" name="option{{q}}{{i}}" [ngModel]="selectedRadioPraluent" [value]="pOption" (change)="onOptionSelect(questionsPraluent[i].answerType ,pOption.code, qType.code)"></span>
                        <span class="optionName">{{ pOption.name }}</span>
                      </label>
                    </li>
                  </ul>

                  <p class="zipCodeHeading question-label">4.
                    {{ "addToCartItems.whatIsZipcodeTextOne" | cxTranslate }}
                    <u>{{ "addToCartItems.whatIsZipcodeTextTwo" | cxTranslate }}</u>
                    {{ "addToCartItems.whatIsZipcodeTextThree" | cxTranslate }}
                  </p>
                  <input type="text" [(ngModel)]="zipCodePraluent" (ngModelChange)="onZipCodePraluent($event)" maxlength="5" pattern="[0-9]{1,5}" class="postCode mb-4" appNumericOnly name="zipCodePraluent" #numberInputRef="ngModel" title="{{'addToCartItems.pleaseEnterPostcode' | cxTranslate }}"> 
                </div>

                <div *ngIf="qType.code === 'DUPIXENT'" class="mb-4">
                  <ul *ngFor="let dupixientAnswers of allDupixientType$ | async; let i = index" class="question-section dupixent" ngClass="question{{i}}">
                    <li class="question-label">{{ i + 1 }}. {{ questionsDupixent[i].text }}</li>
                    <li *ngFor="let dOption of dupixientAnswers; let j = index" class="question-list" ngClass="{{dOption.code}}">
                      <label>
                        <span class="optionRadio"><input type="radio" name="option{{q}}{{i}}" [ngModel]="selectedRadioDupixient" [value]="dOption" (change)="onOptionSelect(questionsDupixent[i].answerType ,dOption.code, qType.code)"></span>
                        <span class="optionName">{{ dOption.name }}</span>
                      </label>
                    </li>
                  </ul>
                  <p class="zipCodeHeading question-label">4.
                    {{ "addToCartItems.whatIsZipcodeTextOne" | cxTranslate }}
                    <u>{{ "addToCartItems.whatIsZipcodeTextTwo" | cxTranslate }}</u>
                    {{ "addToCartItems.whatIsZipcodeTextThree" | cxTranslate }}
                  </p>
                  <input type="text" [(ngModel)]="zipCodeDupixent" (ngModelChange)="onZipCodeDupixent($event)" maxlength="5" pattern="[0-9]{1,5}" class="postCode mb-4" appNumericOnly name="onZipCodeDupixent" #numberInputRef="ngModel" title="{{'addToCartItems.pleaseEnterPostcode' | cxTranslate }}">
              </div>
              
              <div *ngIf="questionnaireType.value.length == 1 && qType.code === 'DUPIXENT'">
                <button type="submit" class="
                  btn btn-default
                  blue-button blue_white
                  order-confirm
                  my-3
                " data-toggle="modal" data-target="#loading-order" id="orderConfirmbtn"
                data-di-id="#orderConfirmbtn" [disabled]="submitDisabledDupixent"  (click)="submitQuestionaire()" >
                {{ "addToCartItems.continue" | cxTranslate }}
                </button>
              
                <button type="button" class="btn btn-default white-button checkout-edit" data-toggle="modal"
                  data-target="#overlay" data-di-id="di-id-35119eec-5a7dc742" (click)="closeQuestionaireModal()"
                  >
                  {{"addToCartItems.goBack" | cxTranslate}}
                </button>
              </div>
              
              <div *ngIf="questionnaireType.value.length == 1 && qType.code === 'PRALUENT'">
                <button type="submit" class="
                  btn btn-default
                  blue-button blue_white
                  order-confirm
                  my-3
                " data-toggle="modal" data-target="#loading-order" id="orderConfirmbtn"
                data-di-id="#orderConfirmbtn" [disabled]="submitDisabledPraluent"  (click)="submitQuestionaire()" >
                {{ "addToCartItems.continue" | cxTranslate }}
                </button>
              
                <button type="button" class="btn btn-default white-button checkout-edit" data-toggle="modal"
                  data-target="#overlay" data-di-id="di-id-35119eec-5a7dc742" (click)="closeQuestionaireModal()"
                  >
                  {{"addToCartItems.goBack" | cxTranslate}}
                </button>
              </div>
            </div>
            
            <div *ngIf="questionnaireType.value.length == 2">
              <button type="submit" class="
                btn btn-default
                blue-button blue_white
                order-confirm
                my-3
              " data-toggle="modal" data-target="#loading-order" id="orderConfirmbtn"
              data-di-id="#orderConfirmbtn" [disabled]="submitDisabled"  (click)="submitQuestionaire()" >
              {{ "addToCartItems.continue" | cxTranslate }}
            </button>
            
            <button type="button" class="btn btn-default white-button checkout-edit" data-toggle="modal"
              data-target="#overlay" data-di-id="di-id-35119eec-5a7dc742" (click)="closeQuestionaireModal()"
              >
              {{"addToCartItems.goBack" | cxTranslate}}
            </button>
            </div>
              
            </div>
          </div>
        </div>
      </section>
    </ng-template>


    <section class="col-md-8 col-sm-12 left-section float-left cart-left" style="min-height: 357px;">
      <section class="cart-accordion">
        <section class="panel-group">
          <section class="panel panel-default">
            <section class="panel-heading">
              <section class="deli_loc clear_fix">
                <section class="cart_shipto col-sm-6 col-md-6 col-lg-6 cart_shipto pl0">
                  <section class="firstLevel-acc cartShipToHeader mb-0">
                    <h6>{{'addToCartItems.shipLocation'|cxTranslate}}</h6>
                    <section class="col-sm-7 col-md-7 col-lg-7   locleft">
                      <section class="ship-address">
                        <section id="location-select">
                          <section class="location-icon">
                            <span class="glyph-icon">
                              <i class="fas fa-map-marker-alt icon mr-3"></i>
                            </span>
                          </section>
                          <section class="location-Details">
                            <span class="selected-loc">{{selectedData?.companyName}}
                              <!-- {{"("+selectedData?.unitID+")"}}  -->
                            </span>
                            <span class="addr-label">
                              <span>
                                {{selectedData?.line2 ? selectedData?.line2 : '' }}
                                {{selectedData?.line1 ? selectedData?.line1 : '' }}
                              </span><br>
                              <span>
                                {{selectedData?.postalCode ? selectedData?.postalCode : '' }}
                                {{selectedData?.town ? selectedData?.town : '' }}
<!--                              {{selectedData?.region?.isocodeShort ? selectedData?.region?.isocodeShort+' ' : ''}}-->
                              </span>
                            </span>
                          </section>
                          <section class="custome-modal">
                            <ng-template #content let-modal>
                              <section class="modal-header cart-Modal-Header">
                                <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')">
                                  <img alt="" src="../../assets/icons/close.png">
                                </button>
                                <h4 class="modal-title" id="modal-basic-title">
                                  {{'account.selectAWholesaler'| cxTranslate}}</h4>
                              </section>
                              <section class="modal-body">

                                <section class="input-group loc-SearchBar">
                                  <span class="input-group-btn">
                                    <button class="btn" type="button">
                                      <span class="loc-Search-Icon">
                                      </span>
                                    </button>
                                    <input type="text" #clientName (keyup)="SearchClientName(clientName.value)"
                                      class="form-control searchboxnew"
                                      placeholder="{{'account.searchAWwholesalerName'| cxTranslate}}	" />
                                  </span>
                                </section>
                                <hr />
                                <section>
                                  <section class="custom-control custom-radio radio_grp label-location w-100 m-0">
                                    <table aria-describedby="shiptolocation"
                                      *ngFor="let data of productEntries;  let i = index" class="w-100">
                                      <tr class="sample-header">
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                      </tr>
                                      <tr class="line-location"
                                        [ngClass]="{'label-location': (i !== productEntries.length-1)}">
                                        <input type="radio" (change)='onOptionsSelected(data)' [id]="i"
                                          [checked]="selectedData?.id == i" name="customRadio"
                                          class="custom-control-input" />
                                        <label class="custom-control-label secloct w-100" [for]="i">
                                            {{data?.wholesalerDisplayName}}
                                        </label>

                                        <!-- <p class="labelpp">
                                          {{data?.line2 ? data?.line2 : '' }}
                                          {{data?.line1 ? data?.line1 : '' }}
                                          {{data?.postalCode }}
                                          {{data?.town ? data?.town : ''}} -->
<!--                                          {{data?.region?.isocodeShort ? data?.region?.isocodeShort+' ' : ''}}-->

                                          <!-- </p> -->

                                      </tr>
                                    </table>
                                  </section>

                                </section>
                              </section>
                              <section class="modal-footer">
                                <button type="button" class="btn location-done" (click)='goToPLP()'
                                  (click)="modal.close('Save click')">
                                  {{'account.keepOnGoing'| cxTranslate}}
                                </button>
                              </section>
                            </ng-template>


                          </section>
                        </section>

                      </section>

                    </section>
                  </section>
                  <div class="selectedWholesalerSection">
                    <div class="mb-3">
                        <span class="bold">{{'account.selectedWholesaler'|cxTranslate}}:</span>
                        {{userAccountgetData?.wholesalerDisplayName}}
                        <div class="switch-whs-popup">
                          <a (click)="goToPLP()" class="change-wholesaler">{{'account.changeWholesaler'| cxTranslate}}</a>
                        </div>
                    </div>
                </div>
                </section>
              </section>
              <section id="pdp-list1" class="panel-collapse clear_fix">
                <section class="panel-body no-location-panel">
                  <section *ngFor="let productentry of productEntries; let i = index" class="pdp-list-data">

                    <section class="row row-20 pdp-list-product-data">
                      <section class="col-xs-12 col-sm-7 col-md-7 col-lg-7 pr0 cart_print">
                        <a (click)="productDetails(productentry)">
                          <img class="image-product" *ngIf="productentry?.PRIMARY!==undefined"
                            src="{{productentry?.PRIMARY?.url}}" alt="">

                          <img class="image-product" *ngIf="productentry?.PRIMARY===undefined" [src]="fallbackImage"
                            alt="">
                          <h2 [innerHTML]="productentry.product.name"></h2>
                        </a>
                      </section>
                    </section>
                    <section class="row">
                      <!--Entity for space-->
                      <section class="col-lg-2 col-md-2 col-sm-2 hidden-xs
                                             delete-popover-container min-col-width pr0 width13 no-print">
                        <ng-template #popTitleDelete>
                          {{'addToCartItems.deleteTitle'|cxTranslate}}</ng-template>
                        <ng-template #popContentDelete>
                          <button type="reset" class="popover-close btn white-button popover-cancel-button"
                            data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p1)">
                            {{'addToCartItems.cancel'|cxTranslate}}</button>
                            <ng-container *ngIf="getArrayLength() != 1 else lastItem">
                              <button class="btn blue-button popover-cancel-button" type="submit"
                              data-di-id="di-id-3064f17d-e97cc628"
                              (click)="deleteCart(productentry?.entryNumber,productentry?.product?.name)">
                              {{'addToCartItems.delete'|cxTranslate}}</button>
                            </ng-container>
                            <ng-template #lastItem>
                              <button class="btn blue-button popover-cancel-button" type="submit"
                              data-di-id="di-id-3064f17d-e97cc628"
                              (click)="clearChildCart()">
                              {{'addToCartItems.delete'|cxTranslate}}</button>
                            </ng-template>
                        </ng-template>

                        <a [ngbPopover]="popContentDelete" [popoverTitle]="popTitleDelete" triggers="manual"
                          #p1="ngbPopover" placement="top" class="trash cart-edit-delete" (click)="toggle(p1)">
                          <img src="../../assets/icons/trash.svg" alt="Delete">
                          {{'addToCartItems.delete'|cxTranslate}}
                        </a>
                      </section>

                      <section class="data-pad"
                        [ngClass]="(productentry?.basePrice?.value == undefined && productentry?.basePrice?.value == 0.0)?'col-sm-4 col-md-4 col-lg-4':'col-sm-5 col-md-5 col-lg-5'">
                        <section class="checkout-data">
                          <p [innerHTML]="productentry.product.description"></p>
                          <p class="bold mb-2">
                            {{"selectVaccines.cipCode" | cxTranslate}}: &nbsp;
                            <span class="num-bold productCode"
                              *ngIf="productentry.product.cipCode !== undefined">{{productentry.product.cipCode}}</span>
                          </p>
                          <p class="bold">
                            {{"selectVaccines.deliveredAndInvoicedBy" | cxTranslate}}: &nbsp;
                            <span class="prdct-No">{{ productentry?.wholesalerDisplayName }}</span>
                            <!-- <span class="num-bold productCode"
                              *ngIf="productentry.product.cipCode !== undefined">{{productentry.product.cipCode}}
                            </span> -->
                          </p>
                          <p class="message-cart-area" *ngIf="productentry.product?.messageDangerousGoods">
                            {{'plp.messageDangerousGoods'|cxTranslate}}</p>
                          <p class="message-cart-area" *ngIf="productentry.product?.messageColdChain">
                            {{'plp.messageColdChain'|cxTranslate}}</p>
                        </section>
                        <section class="checkout-data vol-Pricing mb-3"
                          *ngIf="!productentry.product.discontinued && productentry.product.available">
                          <a class="link vol-Price" placement="top"
                            *ngIf="productentry.product.volumePrices?.length > 0" [ngbPopover]="popContent"
                            popoverTitle="{{'plp.volumePricing'|cxTranslate}}">
                            {{'plp.volumePricing'|cxTranslate}}
                          </a>
                          <ng-template #popContent>
                            <table class="tb" id="vol-price-pdp" aria-describedby="volTable">
                              <thead>
                                <th scope="col"></th>
                              </thead>
                              <tbody *ngFor="let prices of productentry.product.volumePrices; let i=index">
                                <tr>
                                  <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                                    {{'selectVaccines.units'|cxTranslate}}:
                                  </td>
                                  <td>{{prices?.value | localCurrency}}</td>
                                  <td><span *ngIf="i>0" class="save-price">{{'selectVaccines.save'|cxTranslate}}
                                      {{productentry.product.volumePrices[0].value -
                                      productentry.product.volumePrices[i].value | localCurrency}}
                                      (
                                      {{(productentry.product.volumePrices[0].value -
                                      productentry.product.volumePrices[i].value) /
                                      productentry.product.volumePrices[0].value | percent:'1.2-2'}}
                                      )
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </ng-template>
                        </section>
                      </section>
                      <section class="col-sm-3 price-list col-lg-3 col-md-2">
                        <section class="product-price">
                          <h6>
                            {{'plp.yourPrice'|cxTranslate}}
                          </h6>
                          <span>{{productentry?.basePrice?.value | localCurrency}}
                            {{'plp.cad'|cxTranslate}}
                          </span>

                        </section>
                      </section>

                      <section class="col-xs-6 col-sm-2 col-lg-2 px0 col-md-3 unit-list"
                        *ngIf="productentry?.basePrice?.value != undefined && (productentry?.product?.productType == 'BEYFORTUS' || productentry?.basePrice?.value != 0.0)">
                        <section class="unitCount-comp text-center">
                          <section class="addUnits-head bold">
                            {{'plp.units'|cxTranslate}}
                          </section>

                          <section class="unit-area">
                            <span class="disabled " aria-hidden="true" class="no-print"
                              id="{{'minusIcon'+productentry?.entryNumber}}">
                              <img src="../../assets/icons/less.svg" id="{{'unitMinus'+productentry?.entryNumber}}"
                                [ngClass]="{'disabled-img':productentry?.quantity <= productentry?.product?.minQuantity}"
                                alt="" (click)="performQuantityComputation(
                                  productentry?.product,
                                  2,
                                  productentry?.entryNumber,
                                  productentry?.product?.stepQuantity,
                                  productentry?.product?.minQuantity,
                                  productentry?.product?.maxQuantity,
                                  productentry.entryNumber
                                  )">
                              <img src="../../assets/icons/less-disable.svg"
                                id="{{'unitMinusDisable'+productentry?.entryNumber}}" alt=""
                                [ngClass]="{'disabled-img': productentry?.product?.minQuantity === undefined || productentry?.quantity > productentry?.product?.minQuantity}">
                            </span>
                            <input type="number" class="prod-quantity text-center" value={{productentry?.quantity}}
                              (keyup)="performQuantityComputation(
                                productentry?.product,
                                null,
                                productentry?.entryNumber,
                                productentry?.product?.stepQuantity,
                                  productentry?.product?.minQuantity,
                                  productentry?.product?.maxQuantity,
                                  productentry.entryNumber
                                )" id="{{'prodQuantity'+productentry?.entryNumber}}"
                              min='${productentry?.product.minQuantity}' max='${productentry?.product.minQuantity}'
                              onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                            <span class="" aria-hidden="true" class="no-print"
                              id="{{'plusIcon'+productentry?.entryNumber}}">
                              <img src="../../assets/icons/more.svg" id="{{'unitPlus'+productentry?.entryNumber}}"
                                alt="" (click)="performQuantityComputation( productentry?.product,
                                1,
                                productentry?.entryNumber,
                                productentry?.product?.stepQuantity,
                                productentry?.product?.minQuantity,
                                productentry?.product?.maxQuantity,
                                productentry.entryNumber)"
                                [ngClass]="{'disabled-img':productentry?.quantity >= productentry?.product?.maxQuantity}">
                              <img src="../../assets/icons/more-disable.svg"
                                id="{{'unitPlusDisable'+productentry?.entryNumber}}" class="{{productentry?.product?.maxQuantity === undefined || productentry?.quantity < productentry?.product?.maxQuantity ? 'disabled-img' : ''}}"
                                   alt="">
                            </span>
                          </section>
                          <section hidden class="doses-section" id="{{'noOfDoses'+productentry?.entryNumber}}">
                            {{productentry?.product?.quantityPerUnit*productentry?.quantity}}
                            {{'plp.doses'|cxTranslate}}
                          </section>
                        </section>
                      </section>
                      <section class="col-xs-6 col-sm-2 col-lg-3 px0 col-md-3 unit-list"
                        [ngClass]="(productentry?.basePrice?.value == 0.0) ? 'no-price-width':''"
                        *ngIf="productentry?.basePrice?.value == undefined || (productentry?.product?.productType != 'BEYFORTUS' && productentry?.basePrice?.value == 0.0)">
                        <section class="no-price">
                          <p>{{'plp.noPrice' | cxTranslate}}</p>
                        </section>
                      </section>
                      <section class="col-lg-2 col-md-2 col-sm-2 hidden-xs
                                            delete-popover-container-rwd min-col-width pr0 width13 no-print">
                        <ng-template #popTitleDelete>
                          {{'addToCartItems.deleteTitle'|cxTranslate}}</ng-template>
                        <ng-template #popContentDelete>
                          <button type="reset" class="popover-close btn white-button popover-cancel-button"
                            data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p1)">
                            {{'addToCartItems.cancel'|cxTranslate}}</button>
                          <button class="btn blue-button popover-cancel-button" type="submit"
                            data-di-id="di-id-3064f17d-e97cc628"
                            (click)="deleteCart(productentry?.entryNumber,productentry?.product?.name)">
                            {{'addToCartItems.delete'|cxTranslate}}</button>
                        </ng-template>

                        <a [ngbPopover]="popContentDelete" [popoverTitle]="popTitleDelete" triggers="manual"
                          #p10="ngbPopover" placement="top" class="trash cart-edit-delete" (click)="openPopover(p10)">
                          <img src="../../assets/icons/trash.svg" alt="Delete">
                          {{'addToCartItems.delete'|cxTranslate}}
                        </a>
                      </section>
                    </section>
                  </section>
                </section>
              </section>

            </section>
          </section>
        </section>
      </section>
    </section>
    <aside class="col-md-4 col-sm-12 right-navigation float-right right-align add-products no-print"
      aria-label="quickOrderSection">
      <section class="ad-product-details cartProductSaveDetails">
        <h3>{{'addToCartItems.addProducts'|cxTranslate}}</h3>
        <h6>{{'addToCartItems.quickOrder'|cxTranslate}}</h6>
        <p>{{'addToCartItems.searchItems'|cxTranslate}}</p>
        <app-quick-order-indirect [additionalQuery]="':relevance:all:1:productType:INDIRECT'"></app-quick-order-indirect>
      </section>
    </aside>
  </section>

</section>
<ng-template #emptyCart>
  <app-indirect-empty-cart compName="IndirectEmptyCartParagraphComponent"></app-indirect-empty-cart>
</ng-template>
