import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VerticalBannerComponent } from './vertical-banner/vertical-banner.component';



@NgModule({
  declarations: [VerticalBannerComponent],
  imports: [
    CommonModule
  ],
  exports: [VerticalBannerComponent]
})
export class ResourcesBannerModule { }
