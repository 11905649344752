import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { SelectWhsPopupService } from './services/select-whs-popup.service';

@Component({
  selector: 'app-switch-whs-popup',
  templateUrl: './select-whs-popup.component.html',
  styleUrls: ['./select-whs-popup.component.scss']
})
export class SelectWhsPopupComponent implements OnInit, OnDestroy {
  selectedWhs: string;
  b2bUnits: any;
  searchedB2bUnitList: any;
  wholesalerId: string;
  userAccountData: any;
  b2bUnitsByAccountTypeSub: Subscription;

  constructor(
    private readonly selectWhsPopupService: SelectWhsPopupService,
    private readonly ref: ChangeDetectorRef,
    private readonly router: RoutingService,
    private readonly user: UserAccountFacade,
    private readonly route: RoutingService,
  ) {
  }

  ngOnInit(): void {
    this.selectWhsPopupService.disableParent();

    this.user.get().pipe(
      filter(customer => !!customer),
      switchMap(customer => this.selectWhsPopupService.getUserAccountDataFromDb(customer?.uid)),
      switchMap((b2bUnit: any) => this.selectWhsPopupService.getRegisteredWhsForB2BUnit(b2bUnit?.defaultB2BUnit?.uid))
    ).subscribe(registeredWhs => {
      this.b2bUnits = registeredWhs;
      this.searchedB2bUnitList = this.b2bUnits;
      this.ref.detectChanges();
    });
  }

  selectWhsForB2BUnit(): void {
    this.user.get().subscribe((customer: any) => {
      if (customer) {
        this.selectWhsPopupService.updateWholesalerForUsername(customer.userName, this.wholesalerId).subscribe(() => {
          window.location.href = '/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT';
          // this.router.goByUrl('/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
        });
      }
    });
  }

  getWholesalerId(b2bUnit): void {
    if (b2bUnit.uid !== undefined && b2bUnit.uid !== null) {
      this.wholesalerId = b2bUnit.uid;
    }
  }

  searchB2BName(value): void {
    if (value.length > 0) {
      this.searchedB2bUnitList = this.b2bUnits.filter(x =>
        (x.locName && x.locName.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        || (!x.locName && x.name && x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
      );
    } else {
      this.searchedB2bUnitList = this.b2bUnits;
    }
  }

  ngOnDestroy(): void {
    this.selectWhsPopupService.enableParent();
  }

  getWholesalerLength(): number {
    return this.searchedB2bUnitList.length;
  }

  backToPLP(){
    this.route.goByUrl('/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
  }

}
