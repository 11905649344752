import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import { CmsConfig, ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IndirectCartCheckoutComponent } from '../custom-add-to-cart/indirect-cart-checkout/indirect-cart-checkout.component';
import { IndirectCartConfirmationComponent } from '../custom-add-to-cart/indirect-cart-confirmation/indirect-cart-confirmation.component';
import { SearchBoxModule } from '../search-box/search-box.module';
import { IndirectCheckoutModule } from '../shared/modules/indirect-checkout/indirect-checkout.module';
import { QuickOrderModule } from '../shared/modules/quick-order/quick-order.module';
import { MainPipeModule } from '../shared/pipes/mainpipe.module';
import { NumericOnlyDirective } from './indirect-cart/indirect-cart-number.directive';
import { IndirectCartComponent } from './indirect-cart/indirect-cart.component';
import { IndirectEmptyCartComponent } from './indirect-empty-cart/indirect-empty-cart.component';

@NgModule({
  declarations: [
    IndirectCartComponent,
    IndirectEmptyCartComponent,
    NumericOnlyDirective
  ],
  imports: [
    CommonModule,
    I18nModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgSelectModule,
    IndirectCheckoutModule,
    SearchBoxModule,
    QuickOrderModule,
    ConfigModule.withConfig({
      cmsComponents: {
        IndirectCartMiddleComponent: {
          component: IndirectCartComponent,
        },
        IndirectCheckoutMiddleComponent: {
          component: IndirectCartCheckoutComponent,
        },
        IndirectBodyContentComponent: {
          component: IndirectCartConfirmationComponent,
        },
      },
    } as CmsConfig),
    MainPipeModule
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class IndirectModule {
}
