import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
})
export class CookiesPolicyComponent implements OnInit {
  cookiesPolicy: any;
  constructor(private readonly sanitizer: DomSanitizer) {}
  ngOnInit(): void {
    this.cookiesPolicy = document.querySelector(
      '#ot-sdk-cookie-policy'
    ).innerHTML;
    document.querySelector('#cookie-list').innerHTML = this.cookiesPolicy;
  }
}
