import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthModule, ClientAuthModule } from '@spartacus/core';
import { VscaUserAuthModule } from './user-auth/vsca-user-auth.module';


@NgModule({
  imports: [CommonModule, ClientAuthModule.forRoot(), VscaUserAuthModule.forRoot()],
})
export class VscaAuthModule extends AuthModule{
  static forRoot(): ModuleWithProviders<VscaAuthModule> {
    return {
      ngModule: AuthModule,
    };
  }
}
