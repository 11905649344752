<div class="Registration-nav col-xs-12 col-sm-12 col-md-12 p0 multipleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 payment">
      <div class="Common-registration" [ngClass]="selectedHeader=='billingDetails'?'Common-active':''">
          <span>1</span>
          <span>{{ "accountRegistration.billingInformation.header" | cxTranslate }}</span>
      </div>
  </div> 
   <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
       <div class="Common-registration" [ngClass]="selectedHeader=='shippingDetails'?'Common-active':''">
           <span>2</span>
           <span>{{ "accountRegistration.shippingInformation.header" | cxTranslate }}</span>
       </div>
   </div>
   <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
      <div class="Common-registration" [ngClass]="selectedHeader=='contacts'?'Common-active':''">
          <span>3</span>
          <span>{{ "accountRegistration.contactsInformation.header" | cxTranslate }}</span>
      </div>
  </div>
    <div class="col-xs-12 col-md-12 col-lg-3 confirmation">
        <div class="Common-registration" [ngClass]="selectedHeader=='confirmation'?'Common-active':''">
            <span>4</span>
            <span>{{ "accountRegistration.accountConfirmation.header" | cxTranslate }}</span>
        </div>
    </div>
</div>
<div *ngIf="selectedHeader=='shippingDetails'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
      <div class="Common-registration" [ngClass]="selectedHeader=='shippingDetails'?'Common-active':''">
          <span>2</span>
          <span>{{ "accountRegistration.shippingInformation.header" | cxTranslate }}</span>
      </div>
  </div>
</div>
<div *ngIf="selectedHeader=='billingDetails'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 payment">
      <div class="Common-registration" [ngClass]="selectedHeader=='billingDetails'?'Common-active':''">
          <span>1</span>
          <span>{{ "accountRegistration.billingInformation.header" | cxTranslate }}</span>
      </div>
  </div>
</div>
<div *ngIf="selectedHeader=='contacts'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
      <div class="Common-registration" [ngClass]="selectedHeader=='contacts'?'Common-active':''">
          <span>3</span>
          <span>{{ "accountRegistration.contactsInformation.header" | cxTranslate }}</span>
      </div>
  </div>
</div>
<div *ngIf="selectedHeader=='confirmation'" class="Registration-nav singleHeader confirmation">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
      <div class="Common-registration" [ngClass]="selectedHeader=='confirmation'?'Common-active':''">
          <span>4</span>
          <span>{{ "accountRegistration.accountConfirmation.header" | cxTranslate }}</span>
      </div>
  </div>
</div>