<div class="Registration-nav col-xs-12 col-sm-12 col-md-12 p0 multipleHeader no-print">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 payment">
    <div class="Common-registration" [ngClass]="selectedHeader=='selectVaccine'?'Common-active':''">
      <span>1</span>
      <span>{{'selectVaccines.selectVaccines'|cxTranslate}}</span>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
    <div class="Common-registration" [ngClass]="selectedHeader=='ViewCart'?'Common-active':''">
      <span>2</span>
      <span>{{'selectVaccines.viewCart'|cxTranslate}}</span>
    </div>
  </div>
  <div class="col-xs-12 col-md-12 col-lg-3 confirmation">
    <div class="Common-registration " [ngClass]="selectedHeader=='scheduleToShip'?'Common-active':''">
      <span>3</span>
      <span>{{'selectVaccines.scheduleToShip'|cxTranslate}}</span>
    </div>
  </div>
  <div class="col-xs-12 col-md-12 col-lg-3 confirmation">
    <div class="Common-registration" [ngClass]="selectedHeader=='Checkout'?'Common-active':''">
      <span>4</span>
      <span>{{'selectVaccines.checkout'|cxTranslate}}</span>
    </div>
  </div>
</div>

<!-- =================================Responsive================================ -->

<div *ngIf="selectedHeader=='selectVaccine'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 payment">
    <div class="Common-registration" [ngClass]="selectedHeader=='selectVaccine'?'Common-active':''">
      <span>1</span>
      <span>{{'selectVaccines.selectVaccine'|cxTranslate}}</span>
    </div>
  </div>
</div>
<div *ngIf="selectedHeader=='ViewCart'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
    <div class="Common-registration" [ngClass]="selectedHeader=='ViewCart'?'Common-active':''">
      <span>2</span>
      <span>{{'selectVaccines.viewCart'|cxTranslate}}</span>
    </div>
  </div>
</div>

<div *ngIf="selectedHeader=='scheduleToShip'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
    <div class="Common-registration" [ngClass]="selectedHeader=='Checkout'?'Common-active':''">
      <span>3</span>
      <span>{{'selectVaccines.scheduleToShip'|cxTranslate}}</span>
    </div>
  </div>
</div>

<div *ngIf="selectedHeader=='Checkout'" class="Registration-nav singleHeader">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
    <div class="Common-registration" [ngClass]="selectedHeader=='Checkout'?'Common-active':''">
      <span>4</span>
      <span>{{'selectVaccines.checkout'|cxTranslate}}</span>
    </div>
  </div>
</div>