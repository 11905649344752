<div class="modal-header sanofi-modal-header">
  <button aria-label="Close" class="close-x-btn" (click)="closeModal()"></button>
</div>
<div class="modal-body sanofi-modal-body">
  <p>{{message | cxTranslate}}</p>
  <div class="action-btns">
    <button aria-label="Cancel" class="btn btn-popup btn-cancel" (click)="closeModal()">{{"externalpopover.modalBluebtn"
      | cxTranslate}}</button>
    <a aria-label="Continue" class="btn btn-popup btn-active" href="{{linkUrl}}" target="_blank">{{"externalpopover.modalWhiteBtn" |
      cxTranslate}}</a>
  </div>
</div>
