import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { VscaLoginFormComponentService } from './vsca-login-form-component.service';

@Component({
  selector: 'vsca-cx-login-form',
  templateUrl: './vsca-login-form.component.html',
  styleUrls: ['./vsca-login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VscaLoginFormComponent {
  constructor(
    protected service: VscaLoginFormComponentService,
    private readonly route: RoutingService,
    private dataLayerService: DataLayerService
  ) {}

  form: FormGroup = this.service.form;
  isUpdating$ = this.service.isUpdating$;
  isPasswordVisible: boolean = false; 

  @HostBinding('class.user-form') style = true;

  onSubmit(): void {
    this.service.login();
    this.dataLayerService.loginDataLayer();
  }

  gotoLoginHelp() {
    this.route.goByUrl('/login/loginhelp');
  }

  gotoLoginHelpName() {
    this.route.goByUrl('/login/loginhelpname');
  }
  
   togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onPasswordInput(event: Event): void {
    const passwordValue = (event.target as HTMLInputElement).value;
    this.isPasswordVisible = passwordValue.length < 0;
  }

}
