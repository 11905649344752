<div *ngIf="showDisclaimer" class="global-alerts">
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeDisclaimerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    {{"addToCartItems.disclaimerMsg" | cxTranslate}}
  </div>
</div>
<section class="preheader">
    <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 float-right">
        <section class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0 float-left emptyheader"></section>
        <section class="col-lg-3 col-md-3 col-sm-6 col-xs-12 middle-row mt0 text-right float-left">
            <a href="/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT" target="" id="continueShoppingId" data-di-id="#continueShoppingId">
                <img src="../../assets/icons/chevron-left.svg" alt="" class="chevron-left">
                {{'addToCartItems.continueShopping'|cxTranslate}}</a>
        </section>
        <section class="col-lg-4 col-md-4 col-sm-6 col-xs-12 text-right float-right p0 header-links">
                <section class="save-document save-document-container  mb1 mt0 ">
                    <ng-template #popSaveTitle>{{'addToCartItems.saveOrderTitle'|cxTranslate}}</ng-template>
                    <ng-template #popSaveContent>
                        <input type="text" class="save-order" required [(ngModel)]="saveCartName" (keypress)="omit_special_char($event)">
                        <span class="common-error required">{{errorMsg}}</span>
                        <button type="reset" class="popover-close btn white-button popover-cancel-button" (click)="toggle(p2)">
                            {{'addToCartItems.cancel'|cxTranslate}}</button>
                        <button class="btn blue-button popover-cancel-button" type="submit" data-di-id="di-id-3064f17d-e97cc628" (click)="saveOrder()">
                            {{'addToCartItems.save'|cxTranslate}}</button>
                    </ng-template>
                    <a href="javascript:void(0)" target="" aria-label="download as"
                    class="xls_cart xls-cart-img-section" id="tealium-xls" d
                    ata-di-id="#tealium-xls" (click)="exportXls()">
                        <img src="../../assets/icons/excel.png" alt="">
                    <span>{{'addToCartItems.xls'|cxTranslate}} </span></a> <a href="javascript:void(0)" onclick="window.print();" target="" id="printLinkId" class="print-cart-img-section" data-di-id="#printLinkId">
                        <img src="../../assets/icons/print.svg" alt="">
                    <span>{{'addToCartItems.print'|cxTranslate}} </span></a>
                </section>
            </section>
        </section>
</section>
<app-indirect-checkout [cartDetails]="cartDetails" [stdCartType]="stdCart"></app-indirect-checkout>

