import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  AuthService,
  CmsSearchBoxComponent,
  PageType,
  RoutingService,
  WindowRef
} from '@spartacus/core';
import {
  CmsComponentData,
  ICON_TYPE,
  SearchBoxComponentService,
  SearchBoxConfig,
  SearchBoxProductSelectedEvent,
  SearchBoxSuggestionSelectedEvent,
  SearchResults
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CustomSearchService } from './custom-search.service';

const DEFAULT_SEARCH_BOX_CONFIG: SearchBoxConfig = {
  minCharactersBeforeRequest: 3,
  displayProducts: true,
  displaySuggestions: false,
  maxProducts: 5,
  maxSuggestions: 5,
  displayProductImages: true,
};

@Component({
  selector: 'cx-searchbox',
  templateUrl: './search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() config: SearchBoxConfig;
  @Input() quickOrder = false;
  @Output() selectedProductEvent = new EventEmitter<any>();
  @Input() searchValueClear = false;
  isUserLoggedIn: boolean;
  value$: Observable<any>;
  hideSearchHistory = true;
  userId: string;
  userName: any;
  closeDropDown: boolean;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  hidequickOrder: boolean;
  showQuickOrder = false;
  forceClose: boolean;
  inputFieldHTML: string;
  addQuery: string;
  subLoggedIn: Subscription;
  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  test: boolean = false;
  /**
   * Sets the search box input field
   */
  @Input('queryText')
  set queryText(value: string) {
    if (value) {
      this.search(value);
    }
  }

  @Input('additionalQuery')
  set additionalQuery(addQuery: string) {
    this.addQuery = addQuery;
  }

  iconTypes = ICON_TYPE;
  searchBoxClass = 'searchbox-is-active';
  /**
   * In some occasions we need to ignore the close event,
   * for example when we click inside the search result section.
   */
  private ignoreCloseEvent = false;
  searchValue: any;
  chosenWord = '';
  public subscription: Subscription;
  searchHistory = [];

  constructor(
    protected searchBoxComponentService: SearchBoxComponentService,
    @Optional()
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    private readonly user: UserAccountFacade,
    private readonly loggedInProvider: CustomSearchService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: Router,
    private readonly renderer: Renderer2,
    private readonly authService: AuthService
  ) {}

  /**
   * Returns the SearchBox configuration. The configuration is driven by multiple
   * layers: default configuration, (optional) backend configuration and (optional)
   * input configuration.
   */
  protected config$: Observable<SearchBoxConfig> = (
    this.componentData?.data$ || of({} as any)
  ).pipe(
    map((config) => {
      const isBool = (obj: SearchBoxConfig, prop: string): boolean =>
        obj?.[prop] !== 'false' && obj?.[prop] !== false;

      return {
        ...DEFAULT_SEARCH_BOX_CONFIG,
        ...config,
        displayProducts: isBool(config, 'displayProducts'),
        displayProductImages: isBool(config, 'displayProductImages'),
        displaySuggestions: isBool(config, 'displaySuggestions'),
        // we're merging the (optional) input of this component, but write the merged
        // result back to the input property, as the view logic depends on it.
        ...this.config,
      };
    }),
    tap((config) => (this.config = config))
  );

  results$: Observable<SearchResults> = this.config$.pipe(
    switchMap((config) => this.searchBoxComponentService.getResults(config))
  );

  ngOnInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (
        event.target.classList.contains('flyout') ||
        event.target.classList.contains('stop-navigating')
      ) {
        this.onBlur();
      }
    });
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.searchValue = null;
        this.onBlur();
      }
    });
    this.subscription = this.routingService
      .getRouterState()
      .pipe(filter((data) => !data.nextState))
      .subscribe((data) => {
        if (
          !(
            data.state.context?.id === 'search' &&
            data.state.context?.type === PageType.CONTENT_PAGE
          )
        ) {
          this.chosenWord = '';
        }
      });

    this.user.get().subscribe((res) => {
      if (res !== undefined) {
        const userDetails = JSON.parse(JSON.stringify(res));
        this.userName = userDetails.userName;
        // this.isUserLoggedIn = true;
      }
      // else {
      //   this.isUserLoggedIn = false;
      // }
    });

    this.subLoggedIn = this.isLoggedIn.subscribe((res) => {
      if (res) {
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false;
      }
    });
  }

  /**
   * Closes the searchBox and opens the search result page.
   */
  search(query: string): void {
    if (query.length === 0) {
      if (this.quickOrder) {
        this.selectedProductEvent.emit([]);
      }
      this.searchBoxComponentService.clearResults();
    }

    this.searchBoxComponentService.search(query && this.addQuery ? query.concat(this.addQuery) : query, this.config);
    // force the searchBox to open
    this.open();
  }

  showGreyBg(quickOrderComp) {
    quickOrderComp
      ? this.searchBoxComponentService.toggleBodyClass(
          this.searchBoxClass,
          false
        )
      : this.searchBoxComponentService.toggleBodyClass(
          this.searchBoxClass,
          true
        );
  }
  /**
   * Opens the type-ahead searchBox
   */
  open(): void {
    this.closeDropDown = true;
    this.showQuickOrder = true;
    if (this.isUserLoggedIn) {
      this.hideSearchHistory = true;
      this.value$ = this.loggedInProvider.getSearchResults(this.userName);
    }
    this.results$.subscribe((res) => {
      if (res.products?.length > 0) {
        this.hideSearchHistory = false;
      } else {
        this.hideSearchHistory = true;
      }
      this.cdr.detectChanges();
    });
  }

  /**
   * Dispatch UI events for Suggestion selected
   *
   * @param eventData the data for the event
   */
  dispatchSuggestionEvent(eventData: SearchBoxSuggestionSelectedEvent): void {
    this.searchBoxComponentService.dispatchSuggestionSelectedEvent(eventData);
  }

  /**
   * Dispatch UI events for Product selected
   *
   * @param eventData the data for the event
   */
  dispatchProductEvent(eventData: SearchBoxProductSelectedEvent): void {
    this.searchBoxComponentService.dispatchProductSelectedEvent(eventData);
  }

  /**
   * Closes the type-ahead searchBox.
   */
  close(event: UIEvent, force?: boolean): void {
    // Use timeout to detect changes
    setTimeout(() => {
      if ((!this.ignoreCloseEvent && !this.isSearchBoxFocused()) || force) {
        this.blurSearchBox(event);
      }
      this.searchValue = null;
    });
  }

  protected blurSearchBox(event: UIEvent): void {
    if (!this.quickOrder) {
      this.searchBoxComponentService.toggleBodyClass(
        this.searchBoxClass,
        false
      );
    }
    if (event && event.target) {
      (<HTMLElement>event.target).blur();
    }
    this.searchInput.nativeElement = '';
  }

  // Check if focus is on searchbox or result list elements
  private isSearchBoxFocused(): boolean {
    return (
      this.getResultElements().includes(this.getFocusedElement()) ||
      this.winRef.document.querySelector('input[aria-label="Search"]') ===
        this.getFocusedElement()
    );
  }

  /**
   * Especially in mobile we do not want the search icon
   * to focus the input again when it's already open.
   * */
  avoidReopen(event: UIEvent): void {
    if (this.searchBoxComponentService.hasBodyClass(this.searchBoxClass)) {
      this.close(event);
      event.preventDefault();
    }
  }

  // Return result list as HTMLElement array
  private getResultElements(): HTMLElement[] {
    return Array.from(
      this.winRef.document.querySelectorAll(
        '.products > li a, .suggestions > li a'
      )
    );
  }

  // Return focused element as HTMLElement
  private getFocusedElement(): HTMLElement {
    return <HTMLElement>this.winRef.document.activeElement;
  }

  updateChosenWord(chosenWord: string): void {
    this.chosenWord = chosenWord;

    if (this.chosenWord.length > 0) {
      this.loggedInProvider.saveKeyword(
        {
          recentSearchText: [this.chosenWord],
        },
        this.userName
      );
      this.searchValue = null;
    }
  }

  private getFocusedIndex(): number {
    return this.getResultElements().indexOf(this.getFocusedElement());
  }

  /**
   * Opens the PLP with the given query.
   *
   *  if there's a single product match, we could open the PDP.
   */
  launchSearchResult(event: UIEvent, query: string): void {
    if (!this.quickOrder) {
      if (!query || query.trim().length === 0) {
        return;
      }
      this.close(event);
      this.updateChosenWord(query);
      this.searchBoxComponentService.launchSearchPage(query);
      this.searchValue = null;
      this.searchBoxComponentService.clearResults();
      this.onBlur();
    }
  }

  /**
   * Disables closing the search result list.
   */
  disableClose(): void {
    this.ignoreCloseEvent = true;
  }

  preventDefault(ev: UIEvent): void {
    ev.preventDefault();
  }

  /**
   * Clears the search box input field
   */
  clear(el: HTMLInputElement): void {
    this.disableClose();
    el.value = '';
    this.searchBoxComponentService.clearResults();

    // Use Timeout to run after blur event to prevent the searchbox from closing on mobile
    setTimeout(() => {
      // Retain focus on input lost by clicking on icon
      el.focus();
      this.ignoreCloseEvent = false;
    });
  }
  goToSearch(query) {
    this.searchBoxComponentService.launchSearchPage(query);
    this.hideSearchHistory = false;
    this.searchBoxComponentService.clearResults();
  }
  navigatetoSearch(query) {
    this.searchBoxComponentService.launchSearchPage(query);
    this.searchBoxComponentService.clearResults();
  }
  onBlur() {
    this.closeDropDown = false;
    this.searchValue = null;
    this.searchBoxComponentService.clearResults();
    this.cdr.detectChanges();
  }
  quickOrderClick(product) {
    this.showQuickOrder = false;
    this.searchValue = product.name;
    this.selectedProductEvent.emit(product);
    this.searchBoxComponentService.clearResults();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subLoggedIn?.unsubscribe();
  }
  ngOnChanges() {
    if (this.searchValueClear) {
      this.searchValueClear = false;
      this.searchValue = null;
    }
  }
}
