import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-prices',
  templateUrl: './total-prices.component.html',
  styleUrls: ['./total-prices.component.scss'],
})
export class TotalPricesComponent implements OnInit {
  @Input() abstractOrder: any;
  constructor() {}

  ngOnInit(): void {}
}
