import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthService, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { SubscriptionsContainer } from 'src/app/shared/utils/subscriptions-container';
import { ForgotUserPasswordService } from '../forgot-user-password.service';

@Component({
  selector: 'app-help-hint-recovery-question',
  templateUrl: './help-hint-recovery-question.component.html',
  styleUrls: ['./help-hint-recovery-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpHintRecoveryQuestionComponent implements OnInit, OnDestroy {
  userData: any;
  userInfo: any;
  showUserDetail = false;
  showAnswerMessage: any;
  answerSubscription: any;
  userDataSubscription: any;
  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  subs = new SubscriptionsContainer();
  deletedAccountB2B = false;
  subsDeleted = new SubscriptionsContainer();

  constructor(
    private readonly forgotUserPasswordService: ForgotUserPasswordService,
    private readonly authService: AuthService,
    private readonly router: RoutingService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.answerSubscription =
      this.forgotUserPasswordService.validateAnswer$.subscribe((res) => {
        if (res !== null) {
          this.userData = res;
          this.showUserDetail =
            this.userData && this.userData.email ? true : false;
          this.showAnswerMessage = this.showUserDetail;
          if (!this.userData.email) {
            this.getUserData();
          }
        } else {
          this.getUserData();
        }
      });
      this.subsDeleted.add = this.forgotUserPasswordService.validateEmail$.subscribe((res) => {
              if (res !== null) {
                  this.deletedAccountB2B =
                      res.error?.errors?.[0]?.subject === 'accountNumber' ? true : false;
              }
              this.cdr.detectChanges();
          }
      );
    this.subs.add = this.isLoggedIn.subscribe((resLoggedIn) => {
      if (resLoggedIn) {
        this.router.goByUrl('my-account/account-overview');
      }
    });
  }

  getUserData() {
    this.userDataSubscription =
      this.forgotUserPasswordService.userData$.subscribe((res) => {
        this.userInfo = res;
      });
  }

  ngOnDestroy() {
    this.forgotUserPasswordService.validateEmail.next(null);
    this.forgotUserPasswordService.validateAnswer.next(null);
    this.answerSubscription.unsubscribe();
    this.userDataSubscription.unsubscribe();
    this.subs.dispose();
    this.subsDeleted.dispose();
  }
}
