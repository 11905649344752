<div class="col-xs-12 col-sm-12 col-md-12 Registration-split">
  <h3 class="pdp-title">
    {{ "accountRegistration.billingInformation.title" | cxTranslate }}
  </h3>
  <span class="title-sub-heading">{{
    "accountRegistration.title_sub_heading" | cxTranslate
  }}</span>
  <div class="col-xs-12 col-sm-8 col-md-8 p0">
    <form
      [formGroup]="billingForm"
      id="billingAddressForm"
      (ngSubmit)="proceedToShipping()"
    >
      <div id="registerBilling" formGroupName="billingAddress">
        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
          "
        >
          <ng-template #errorPopContent>{{
            "accountRegistration.special_validation_msg_popover" | cxTranslate
          }}</ng-template>

          <label for="facilityName" class="Fields-label icon-label"
            >{{ "accountRegistration.form.facilityName.label" | cxTranslate }}
          </label>
          <a placement="top" [ngbPopover]="errorPopContent"
            ><img
              src="../../assets/icons/faqs_reg.png"
              alt="faq_reg"
              class="faq"
          /></a>
          <input
            id="facilityName"
            name="facilityName"
            data-valid="valid_facility"
            aria-labelledby="billingAddressForm.facilityName tooltipFacilityName"
            type="text"
            class="Fields-input"
            formControlName="facilityName"
            data-di-id="#billingAddressForm.facilityName"
          />

          <span
            *ngIf="
              !!billingForm.get('billingAddress.facilityName').value &&
              !billingForm.get('billingAddress.facilityName').valid &&
              billingForm.get('billingAddress.facilityName').touched
            "
            class="errorMessage"
            >{{
              "accountRegistration.special_validation_msg" | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              billingForm.get('billingAddress.facilityName').errors?.required &&
              !billingForm.get('billingAddress.facilityName').valid &&
              billingForm.get('billingAddress.facilityName').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ "accountRegistration.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
          "
        >
          <label for="formLegalName" class="Fields-label"
            >{{ "accountRegistration.form.legalName.label" | cxTranslate }}
          </label>
          <input
            id="formLegalName"
            name="legalName"
            data-valid="valid_facility"
            type="text"
            class="Fields-input"
            formControlName="legalName"
            autocomplete="off"
            data-di-id="#formLegalName"
          />

          <span
            *ngIf="
              !!billingForm.get('billingAddress.legalName').value &&
              !billingForm.get('billingAddress.legalName').valid &&
              billingForm.get('billingAddress.legalName').touched
            "
            class="errorMessage"
            >{{
              "accountRegistration.special_validation_msg" | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              billingForm.get('billingAddress.legalName').errors?.required &&
              !billingForm.get('billingAddress.legalName').valid &&
              billingForm.get('billingAddress.legalName').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ "accountRegistration.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
          "
        >
          <label for="billingAddressForm.line1" class="Fields-label icon-label"
            >{{ "accountRegistration.form.address_line_1.label" | cxTranslate }}
          </label>
          <a placement="top" [ngbPopover]="errorPopContent">
            <img
              src="../../assets/icons/faqs_reg.png"
              alt="faq_reg"
              class="faq"
            />
          </a>
          <input
            id="billingAddressForm.line1"
            name="billingAddress.line1"
            data-valid="valid_facility"
            aria-labelledby="billingAddressForm.line1 tooltipBillingAddress1"
            type="text"
            class="Fields-input"
            formControlName="line1"
            data-di-id="#billingAddressForm.line1"
          />

          <span
            *ngIf="
              !!billingForm.get('billingAddress.line1').value &&
              !billingForm.get('billingAddress.line1').valid &&
              billingForm.get('billingAddress.line1').touched
            "
            class="errorMessage"
            >{{
              "accountRegistration.special_validation_msg" | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              billingForm.get('billingAddress.line1').errors?.required &&
              !billingForm.get('billingAddress.line1').valid &&
              billingForm.get('billingAddress.line1').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ "accountRegistration.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label for="billingAddressForm.line2" class="Fields-label"
            >{{
              "accountRegistration.form.address_line_2.label" | cxTranslate
            }}
            <span>{{
              "accountRegistration.optional_tag" | cxTranslate
            }}</span></label
          >
          <input
            id="billingAddressForm.line2"
            name="billingAddressForm.line2"
            data-valid="valid_facility"
            placeholder="Suite/Floor/Building"
            type="text"
            class="Fields-input field-optional"
            formControlName="line2"
            data-di-id="#billingAddressForm.line2"
          />

          <span
            *ngIf="
              !!billingForm.get('billingAddress.line2').value &&
              !billingForm.get('billingAddress.line2').valid &&
              billingForm.get('billingAddress.line2').touched
            "
            class="errorMessage"
            >{{ "accountRegistration.alpha_numeric_msg" | cxTranslate }}</span
          >
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label for="billingAddressForm.town" class="Fields-label">{{
            "accountRegistration.form.city.label" | cxTranslate
          }}</label>
          <input
            id="billingAddressForm.town"
            name="billingAddressForm.town"
            data-valid="valid_city"
            type="text"
            class="Fields-input"
            formControlName="town"
            data-di-id="#billingAddressForm.town"
          />

          <span
            *ngIf="
              !!billingForm.get('billingAddress.town').value &&
              !billingForm.get('billingAddress.town').valid &&
              billingForm.get('billingAddress.town').touched
            "
            class="errorMessage"
            >{{ "accountRegistration.alpha_space_msg" | cxTranslate }}</span
          >
          <span
            *ngIf="
              billingForm.get('billingAddress.town').errors?.required &&
              !billingForm.get('billingAddress.town').valid &&
              billingForm.get('billingAddress.town').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ "accountRegistration.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            asses_dropdown
          "
        >
          <label
            for="billingAddressForm.state"
            id="provinceLabel"
            class="Fields-label"
            >{{
              "accountRegistration.form.province.label" | cxTranslate
            }}</label
          >
          <div class="">
            <div class="" formGroupName="region">
              <ng-select
                [items]="provinces"
                [searchable]="false"
                [clearable]="false"
                bindLabel="name"
                bindValue="isocode"
                placeholder="Please select"
                formControlName="isocode"
                class="dropdown-credit"
              ></ng-select>
            </div>
            <span
              *ngIf="
                billingForm.get('billingAddress.region.isocode').errors
                  ?.required &&
                !billingForm.get('billingAddress.region.isocode').valid &&
                billingForm.get('billingAddress.region.isocode').touched
              "
              class="errorMessage drop-downs"
              role="alert"
              aria-live="assertive"
              >{{ "accountRegistration.requiredMsg" | cxTranslate }}
            </span>
          </div>
        </div>
        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            zip-container
          "
        >
          <label for="billingAddressForm.postalCode" class="Fields-label">{{
            "accountRegistration.form.postalCode.label" | cxTranslate
          }}</label>
          <input
            id="billingAddressForm.postalCode"
            name="billingAddressForm.postalCode"
            data-valid="valid_zip_canada"
            maxlength="7"
            type="text"
            class="Fields-input"
            formControlName="postalCode"
            data-di-id="#billingAddressForm.postalCode"
          />

          <span
            *ngIf="
              billingForm.get('billingAddress.postalCode').errors?.pattern &&
              !billingForm.get('billingAddress.postalCode').valid &&
              billingForm.get('billingAddress.postalCode').touched
            "
            class="errorMessage"
            role="alert"
            aria-live="assertive"
            >{{
              "accountRegistration.form.postalCode.postal_code_validation"
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              billingForm.get('billingAddress.postalCode').errors?.required &&
              !billingForm.get('billingAddress.postalCode').valid &&
              billingForm.get('billingAddress.postalCode').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ "accountRegistration.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container small-device"
        >
          <div class="phone-extension">
            <div class="phone-container">
              <label for="formPhone" class="Fields-label">{{
                "accountRegistration.form.phone.label" | cxTranslate
              }}</label>
              <input
                id="formPhone"
                name="billingAddressForm.phone"
                data-valid="valid_phone"
                placeholder="123-456-7890"
                maxlength="12"
                appPhoneMask
                type="text"
                class="Fields-input phone-no-mask"
                formControlName="phone"
                autocomplete="off"
                data-di-id="#formPhone"
              />

              <span
                class="errorMessage"
                *ngIf="
                  !!billingForm.get('billingAddress.phone').value &&
                  !billingForm.get('billingAddress.phone').valid &&
                  billingForm.get('billingAddress.phone').touched
                "
                >{{
                  "accountRegistration.form.phone.ph_validation_msg"
                    | cxTranslate
                }}</span
              >
              <span
                *ngIf="
                  billingForm.get('billingAddress.phone').errors?.required &&
                  !billingForm.get('billingAddress.phone').valid &&
                  billingForm.get('billingAddress.phone').touched
                "
                class="defaultMessage"
                role="alert"
                aria-live="assertive"
                >{{ "accountRegistration.requiredMsg" | cxTranslate }}
              </span>
            </div>
            <div class="phone-container doubleLineContainer">
              <label for="billingAddressForm.extension" class="Fields-label"
                >{{ "accountRegistration.form.extension.label" | cxTranslate
                }}<span>{{
                  "accountRegistration.optional_tag" | cxTranslate
                }}</span></label
              >
              <input
                id="billingAddressForm.extension"
                name="billingAddressForm.extension"
                data-valid="valid_extension"
                type="text"
                class="Fields-input field-optional extension"
                formControlName="extension"
                data-di-id="#billingAddressForm.extension"
              />

              <span
                class="errorMessage"
                *ngIf="
                  !!billingForm.get('billingAddress.extension').value &&
                  !billingForm.get('billingAddress.extension').valid &&
                  billingForm.get('billingAddress.extension').touched
                "
                >{{
                  "accountRegistration.form.extension.ext_validation_msg"
                    | cxTranslate
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label for="billingAddressForm.fax" class="Fields-label"
            >{{ "accountRegistration.form.office_fax.label" | cxTranslate
            }}<span>{{
              "accountRegistration.optional_tag" | cxTranslate
            }}</span></label
          >
          <input
            id="billingAddressForm.fax"
            name="billingAddressForm.fax"
            data-valid="valid_phone"
            placeholder="123-456-7890"
            type="text"
            maxlength="12"
            autocomplete="off"
            appPhoneMask
            class="Fields-input phone-no-mask field-optional"
            formControlName="fax"
            data-di-id="#billingAddressForm.fax"
          />

          <span
            class="errorMessage"
            *ngIf="
              !!billingForm.get('billingAddress.fax').value &&
              !billingForm.get('billingAddress.fax').valid &&
              billingForm.get('billingAddress.fax').touched
            "
            >{{
              "accountRegistration.form.office_fax.fax_validation_msg"
                | cxTranslate
            }}</span
          >
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label for="billingAddressForm.email" class="Fields-label"
            >{{ "accountRegistration.form.email.label" | cxTranslate }}
          </label>
          <input
            id="billingAddressForm.email"
            name="billingAddressForm.email"
            data-valid="valid_mail"
            type="text"
            class="Fields-input"
            data-view="newemail"
            formControlName="email"
            data-di-id="#billingAddressForm.email"
          />

          <span
            *ngIf="
              !!billingForm.get('billingAddress.email').value &&
              billingForm.get('billingAddress.email').invalid &&
              billingForm.get('billingAddress.email').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{
              "accountRegistration.form.email.email_validation_msg"
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              billingForm.get('billingAddress.email').errors?.required &&
              !billingForm.get('billingAddress.email').valid &&
              billingForm.get('billingAddress.email').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ "accountRegistration.requiredMsg" | cxTranslate }}
          </span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label for="billingAddressForm.confirmemail" class="Fields-label"
            >{{ "accountRegistration.form.confirm_email.label" | cxTranslate }}
          </label>
          <input
            id="billingAddressForm.confirmemail"
            name="billingAddressForm.confirmemail"
            data-valid="valid_mail"
            type="text"
            class="Fields-input"
            data-view="confirmemail"
            formControlName="confirmEmail"
            data-di-id="#billingAddressForm.confirmemail"
          />

          <span
            *ngIf="
              !!billingForm.get('billingAddress.confirmEmail').value &&
              billingForm.get('billingAddress.confirmEmail').invalid &&
              billingForm.get('billingAddress.confirmEmail').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{
              "accountRegistration.form.email.email_validation_msg"
                | cxTranslate
            }}</span
          >

          <span
            *ngIf="
              !!billingForm.get('billingAddress.confirmEmail').value &&
              !billingForm.get('billingAddress.confirmEmail').invalid &&
              billingForm.get('billingAddress.confirmEmail').value !==
                billingForm.get('billingAddress.email').value
            "
            class="errorMessage"
            >{{
              "accountRegistration.form.confirm_email.email_not_match"
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              billingForm.get('billingAddress.confirmEmail').errors?.required &&
              !billingForm.get('billingAddress.confirmEmail').valid &&
              billingForm.get('billingAddress.confirmEmail').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{ "accountRegistration.requiredMsg" | cxTranslate }}
          </span>
        </div>

        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            asses_dropdown
          "
        >
          <label
            for="billingAddressForm.practiceFacility"
            id="practiceFacilityLabel"
            class="Fields-label"
            >{{
              "accountRegistration.form.practice_or_facility_type.label"
                | cxTranslate
            }}</label
          >
          <div class="pdp-dropdown">
            <div class="">
              <ng-select
                [items]="practiceOrFacility"
                [searchable]="false"
                [clearable]="false"
                bindLabel="name"
                bindValue="code"
                placeholder="Please select"
                formControlName="practiceOrFacilityType"
                class="dropdown-credit"
              ></ng-select>
            </div>

            <span
              *ngIf="
                billingForm.get('billingAddress.practiceOrFacilityType').errors
                  ?.required &&
                !billingForm.get('billingAddress.practiceOrFacilityType')
                  .valid &&
                billingForm.get('billingAddress.practiceOrFacilityType').touched
              "
              class="errorMessage drop-downs"
              role="alert"
              aria-live="assertive"
              >{{ "accountRegistration.requiredMsg" | cxTranslate }}
            </span>
          </div>
        </div>

        <hr class="Fields-container-line billingPage" />
        <div>
          <label
            for="billingAddressForm.yellowFever"
            id="yellowFever"
            class="Fields-label-new"
            >{{
              "accountRegistration.form.yellow_fever.label" | cxTranslate
            }}</label
          >
          <div class="custom-control custom-radio radio_grp label-location">
            <input
              type="radio"
              id="customRadio1"
              name="yellowFever"
              class="custom-control-input"
              [value]="yellowFever[0]"
              formControlName="yellowFever"
            />
            <label class="custom-control-label secloct" for="customRadio1">
              {{
                "accountRegistration.form.yellow_fever.accepted" | cxTranslate
              }}
            </label>
          </div>
          <div class="radio_grp">
            <div class="custom-control custom-radio label-location">
              <input
                type="radio"
                id="billingAddressForm.yellowFever2"
                name="yellowFever"
                class="custom-control-input"
                [value]="yellowFever[1]"
                formControlName="yellowFever"
              />
              <label
                class="custom-control-label"
                for="billingAddressForm.yellowFever2"
              >
                {{
                  "accountRegistration.form.yellow_fever.rejected" | cxTranslate
                }}
              </label>
            </div>
          </div>
        </div>
        <hr class="Fields-container-line billingPage" />
        <div class="invoice-btngrps">
          <button
            type="submit"
            class="btn btn-default oi-blue-button reviewvalidbtn"
            data-di-id="di-id-7a6cb2cd-cb37948a"
          >
            {{ "accountRegistration.form.continue_button" | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
