import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatementsCurrencyPipe } from './statementscurrency';

@NgModule({
  declarations: [StatementsCurrencyPipe],
  imports: [CommonModule],
  exports: [StatementsCurrencyPipe]
})

export class StatementsPipeModule { }
