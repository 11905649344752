import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';
import { UserAccountFacade } from "@spartacus/user/account/root";

@Injectable({
  providedIn: 'root',
})
export class AccountOverviewService {
  public userAccountIdDetailsData = new Subject<any>();

  constructor(
    private readonly baseService: BaseService,
    private readonly userAccountFacade: UserAccountFacade,
    public readonly excelService: ExcelDownloadService
  ) {}

  public getAccountId() {
    this.userAccountFacade.get().subscribe((user: any) => {
      if (user) {
        this.baseService.get(`/users/${user.userName}/b2bUnits`).subscribe((account) => {
          if (account !== null && account !== undefined && account?.length !== 0) {
            this.userAccountIdDetailsData.next(account);
          }
        })
      }
    });
  }

  public downloadXls(data, name, filenameNoChange = true) {
    this.excelService.exportAsExcelFile(data, name, filenameNoChange);
  }
}
