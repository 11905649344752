import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { translationChunksConfig } from '@spartacus/assets';
import { I18nModule, provideConfig } from '@spartacus/core';
import { SearchBoxModule } from '../../../search-box/search-box.module';
import { MainPipeModule } from '../../pipes/mainpipe.module';
import { QuickOrderIndirectComponent } from './quick-order-indirect/quick-order-indirect.component';
import { QuickOrderComponent } from './quick-order/quick-order.component';

@NgModule({
  declarations: [QuickOrderComponent, QuickOrderIndirectComponent],
  imports: [CommonModule, SearchBoxModule, NgbModule, I18nModule, MainPipeModule],
  exports: [QuickOrderComponent, QuickOrderIndirectComponent],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class QuickOrderModule { }
