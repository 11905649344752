import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-prices-order',
  templateUrl: './total-prices-order.component.html',
  styleUrls: ['./total-prices-order.component.scss'],
})
export class TotalPricesOrderComponent implements OnInit {
  @Input() orderDetails: any;
  constructor() {}

  ngOnInit(): void {}
}
