import { NgModule } from '@angular/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { CookiesPolicyComponent } from './cookies-policy.component';

@NgModule({
  declarations: [CookiesPolicyComponent],
  providers: [
    provideOutlet({
      id: 'Cookies-LeftContent-Slot',
      position: OutletPosition.AFTER,
      component: CookiesPolicyComponent,
    }),
  ],
})
export class CookiesPolicyModule {}
