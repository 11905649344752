import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNegative',
})
export class FormatNegativeValuePipe implements PipeTransform {
  transform(value) {
    if (Number(value) < 0) {
      const mul = -1;
      return Number(value) * mul;
    } else {
      return value;
    }
  }
}
