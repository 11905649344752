import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
  selector: 'app-my-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabItem: QueryList<TabComponent>;

  // contentChildren are set
  ngAfterContentInit(): void {
    // get all active tabs
    const activeTabs = this.tabItem.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabItem.first);
    }
  }

  selectTab(tab: TabComponent): void {
    // deactivate all tabs
    this.tabItem.toArray().forEach((item) => (item.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
