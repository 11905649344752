import { Component, OnInit,Input } from '@angular/core';
import { CustomInfluenzaService } from '../custom-influenza.service';

@Component({
  selector: 'app-flu-header',
  templateUrl: './flu-header.component.html',
  styleUrls: ['./flu-header.component.scss']
})
export class FluHeaderComponent implements OnInit {
  @Input() selectedHeader: string;
  @Input() tableCategory: string;
  constructor(private readonly influenzaService:CustomInfluenzaService) { }

  ngOnInit() {
  }

}
