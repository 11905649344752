<section class="container">
  <section class="row">
    <section class="col-12 col-sm-12 col-md-12 col-lg-12  "
      [ngClass]="{'col-xl-5':urlPath==='dashboard', 'col-xl-12':urlPath!='dashboard'}">
      <section class="container">
        <div [ngClass]="{'chartMargin-Width':urlPath==='dashboard'}">
          <canvas baseChart [data]="doughnutChartData" [colors]="chartColors" [labels]="doughnutChartLabels"
            [chartType]="doughnutChartType" [options]="doughnutChartOptions">
          </canvas>


        </div>
        <section *ngIf="doughnutChartData.length!==0" class="row"
          [ngClass]="{'dose-total-Section':urlPath==='dashboard' ,'dose-total-Section-reservation-details':urlPath!='dashboard'}">

          <section class="col-xl-12" [ngClass]="{'padding-left':urlPath==='dashboard'}">
            <img src="/assets/icons/chartImage.png" alt="chart-image"
              [ngClass]="{'chart-image-css':urlPath==='dashboard', 'chart-image-reservation-details-css':urlPath!='dashboard'}">
          </section>

          <section class="col-xl-12"
            [ngClass]="{'doseShip-row':urlPath==='dashboard', 'doseShip-row-reservation-details':urlPath!='dashboard'}">
            <span class="doseNum text-center">{{totalShippedQuantity}} sur {{totalDoses}}</span>
          </section>
          <section class="col-xl-12"
            [ngClass]="{'doses-Shipped':urlPath==='dashboard', 'doses-Shipped-reservation-details':urlPath!='dashboard'}">
            <span class="text-center"> Doses {{statusInChrtsDisplay}}</span>
          </section>
        </section>

      </section>


    </section>
    <!-- <section class="col-12 col-sm-12 col-md-12 col-lg-12  tableDiv"
      [ngClass]="{'col-xl-7':urlPath==='dashboard','col-xl-12':urlPath!='dashboard'}">
      <table aria-describedby="cardDetailsTable" class="table">
        <thead>
          <tr class="header-row">
            <th scope="col" class="header">{{'orderConfirmation.reservationStatus'|cxTranslate}}</th>
            <th scope="col" class="header">{{'orderConfirmation.productName'|cxTranslate}}</th>
            <th scope="col" class="header">{{'orderConfirmation.unitsDoses'|cxTranslate}}</th>
            <th *ngIf="totalCancelledQuantity >0" scope="col" class="header">
              {{'orderConfirmation.cancellationReason'|cxTranslate}}</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of consignmentProducts | keyvalue">
          <tr class="content-row">
            <td class="col-Divider">
              {{item?.key}}
            </td>
            <td class="col-Divider">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td>
                    {{value?.prodName}}
                  </td>
                </tr>
              </table>
            </td>
            <td valign="top" class="col-Divider">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td>
                    {{value?.quantity}}
                  </td>
                </tr>
              </table>
            </td>
            <td *ngIf="totalCancelledQuantity >0">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td>
                    {{value?.cancellationReason}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </section> -->


    <section class="col-12 col-sm-12 col-md-12 col-lg-12  tableDiv"
      [ngClass]="{'col-xl-7':urlPath==='dashboard','col-xl-12':urlPath!='dashboard'}">
      <!-- Scheduled to ship table -->
      <table aria-describedby="cardDetailsTable" class="table">
        <thead>
          <tr class="header-row">
            <th scope="col" class="header">{{'orderConfirmation.reservationStatus'|cxTranslate}}</th>
            <th scope="col" class="header" *ngIf="showWeek">{{'orderConfirmation.deliveryDate'|cxTranslate}}</th>
            <th scope="col" class="header">{{'orderConfirmation.productName'|cxTranslate}}</th>
            <th scope="col" class="header">{{'orderConfirmation.unitsDoses'|cxTranslate}}</th>
            <th *ngIf="totalCancelledQuantity >0" scope="col" class="header">
              {{'orderConfirmation.cancellationReason'|cxTranslate}}</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of scheduleToShip | keyvalue; let i = index;">
          <tr class="content-row">
            <td class="col-Divider" *ngIf="!item.consigned">
              En attente
            </td>
            <td class="col-Divider" *ngIf="!item.consigned && showWeek">
              <span *ngIf="item?.key == 'W0' && !item.consigned">S-2</span>
              <span *ngIf="item?.key == 'W2' && !item.consigned">S+2</span>
              <span *ngIf="item?.key == 'W4' && !item.consigned">S+4</span>
            </td>
            <td class="col-Divider">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td *ngIf="!value?.consigned">
                    {{value?.product.name}}
                  </td>
                </tr>
              </table>
            </td>
            <td valign="top" class="col-Divider">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td *ngIf="!value?.consigned">
                    {{value?.requestedQuantity}}
                  </td>
                </tr>
              </table>
            </td>
            <td *ngIf="totalCancelledQuantity >0">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td *ngIf="!value?.consigned">
                    {{value?.cancellationReason}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>

        <!-- Deliveries -->
        <tbody *ngFor="let item of consignmentProducts | keyvalue">
          <tr class="content-row" *ngIf="item?.key != 'En attente'">
            <td class="col-Divider">
              {{item?.key}}
            </td>
            <td class="col-Divider" *ngIf="showWeek">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td *ngIf="(value.status == 'Expédiée' || value.status == 'En cours de préparation')">
                    S{{value?.week}}
                  </td>
                </tr>
              </table>
            </td>
            <td class="col-Divider">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td>
                    {{value?.prodName}}
                  </td>
                </tr>
              </table>
            </td>
            <td valign="top" class="col-Divider">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td>
                    {{value?.quantity}}
                  </td>
                </tr>
              </table>
            </td>
            <td *ngIf="totalCancelledQuantity >0">
              <table class="innerRowTable">
                <tr *ngFor="let value of item?.value">
                  <td>
                    {{value?.cancellationReason}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </section>
</section>
