import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomAddToCartService } from 'src/app/custom-add-to-cart/custom-add-to-cart.service';
import { CheckoutService } from 'src/app/shared/modules/checkout/checkout.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CustomInfluenzaService } from '../custom-influenza.service';
@Component({
  selector: 'app-flu-confirmation-right-content',
  templateUrl: './flu-confirmation-right-content.component.html',
  styleUrls: ['./flu-confirmation-right-content.component.scss']
})
export class FluConfirmationRightContentComponent implements OnInit,OnDestroy {
  billingAddress: any;
  soldToAddress: any;
  contactAddress: any;
  userId: any;
  productEntries: any = [];
  totalProducts: any = 0;
  totalDoses = 0;
  standardCartDetails: any = [];
  infContact: any;
  paymentType: any;
  estTotal: any;
  getuserData: any;
  selectedData: any;
  dataForFluConfirmation: any = [];
  orderDate: any;
  orderId: any;
  url: any;
  urlPath: any;
  poId: any;
  status: any;
  currencyIso: any;
  salesTax: any;
  totalwithSavings: number;
  subTotal: any;
  cardType: any;
  ccNumber: any;
  userEmail: any;
  userName: any;
  displayName: any;
  detailedResSubscriber: any;
  userAccountSubscriber: any;
  purchaseOrderNumber:any='';
  placedByAsm: any;
  constructor(private readonly influenzaService: CustomInfluenzaService,
    private readonly ref: ChangeDetectorRef, private readonly checkoutService: CheckoutService,
    private readonly router: RoutingService, private readonly addToCartService: CustomAddToCartService,
    private readonly user: UserAccountFacade,
    private readonly sharedService: SharedService,
    private readonly activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.url = this.activatedRoute.url;
    this.urlPath = this.url?._value[this.url?._value.length - 1].path;

    this.user.get().subscribe((res: any) => {
      if (res) {
        this.userId = res?.userName;
        this.userEmail = res?.email;
      }
    });
    this.settingAccountDetals();
    if (this.urlPath === 'reservation-overview') {
      this.detailedResSubscriber= this.influenzaService.detailedReservation.subscribe((data) => {
        if (data) {
          this.ref.detectChanges();
          this.setDataForFlu(data);
          this.setRightContent(data);
          }
          this.detailedResSubscriber?.unsubscribe();
      });
    } else {
      const data=this.sharedService.getSessionStorage('orderDataForinlfuenza');
        if (data) {
          this.setDataForFlu(data);
          this.setRightContent(data);
        }
    }


  }
  ngOnDestroy(){
    this.userAccountSubscriber?.unsubscribe();
this.detailedResSubscriber?.unsubscribe();
  }
  setDataForFlu(data) {
    this.dataForFluConfirmation=[];
    this.dataForFluConfirmation.push(data);
    this.arrayFormation();
  }
  arrayFormation() {

    if (this.dataForFluConfirmation) {
      this.dataForFluConfirmation.forEach((element, index) => {
        this.estTotal = element?.totalPriceWithTax?.value;
        this.currencyIso = element?.totalPriceWithTax?.currencyIso;
        this.salesTax = element?.totalTax?.value;
        this.totalwithSavings = this.estTotal - element.totalDiscounts?.value;
        this.subTotal = element?.subTotal?.value;
        this.orderDate =element?.created;
        this.orderId = element?.code;
        this.purchaseOrderNumber = element?.purchaseOrderNumber;
        this.infContact = element?.orderContact;
        this.displayName = element?.paymentType?.displayName;
        this.paymentType = element?.paymentType?.code;
        this.cardType = element?.cc_type;
        this.ccNumber = element?.cc_Number?.substring(6,10);
        this.productEntries=[];
        this.totalDoses=0;
        this.totalProducts=0;
        this.productEntries = element?.entries;
        this.userName = element?.user?.name;
        this.placedByAsm = element?.placedBy;
        if (this.productEntries) {
          this.productEntries.forEach((elem) => {
            this.totalProducts += parseInt(elem?.quantity);
            const a = parseInt(elem?.quantity);
            const b = parseInt(elem?.product?.quantityPerUnit);
            this.totalDoses = this.totalDoses + (a * b);
          });
        }
        this.ref.detectChanges();
      });
      this.ref.detectChanges();
    }

  }

  billingAccount(): void {
    this.router.goByUrl('/my-account/profile');
    this.checkoutService.tabSelection('Account information');
  }
  settingAccountDetals() {
    this.addToCartService.getUserAccountDataFromDb(this.userId);
   this.userAccountSubscriber= this.addToCartService.userAccountData.subscribe((userData) => {
      if (
        userData.res != null &&
        userData.res !== undefined &&
        userData.res?.length !== 0
      ) {
        const usergetDataObj = userData.res.defaultB2BUnit;
        this.contactAddress = userData.res?.defaultB2BUnit?.contactAddress;
        const accountId = usergetDataObj.uid;
        this.addToCartService.getUserAccountDetailsDataFromDb(
          this.userId,
          accountId
        );
      }
      this.userAccountSubscriber?.unsubscribe();
    });
    this.addToCartService.userAccountDetails.subscribe((userDataget) => {
      this.getuserData = userDataget?.res?.shippingAddresses;
      this.ref.detectChanges();
    });
  }
  setRightContent(data) {
    this.billingAddress = data.billingAddress;
    this.soldToAddress = data.soldtoAddress;
    this.selectedData = data.shippingAddress;
    this.poId = data?.purchaseOrderNumber;
    this.status = data?.statusDisplay;
    this.orderDate = data?.created;
    this.infContact = data?.orderContact;
  }
}
