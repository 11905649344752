import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  AuthService,
  AuthStatePersistenceService,
  AuthStorageService,
  ConfigInitializerService,
  OAuthLibWrapperService,
  UserAuthEventModule,
  UserAuthModule,
} from '@spartacus/core';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { VscaAuthService } from './facade/vsca-auth.service';
import { VscaOAuthLibWrapperService } from './service/vsca-oauth-lib-wrapper.service';
import { VscaOAuthService } from './service/vsca-oauth-service';


/**
 * Initialize the check for `token` or `code` in the url returned from the OAuth server.
 */
export function checkOAuthParamsInUrl(
  authService: AuthService,
  configInit: ConfigInitializerService
) {
  const result = () =>
    configInit.getStable().toPromise().then(() => {
      // Wait for stable config is used, because with auth redirect would kick so quickly that the page would not be loaded correctly
      authService.checkOAuthParamsInUrl();
    });

  return result;
}

export function authStatePersistenceFactory(
  authStatePersistenceService: AuthStatePersistenceService
) {
  const result = () => authStatePersistenceService.initSync();
  return result;
}

/**
 * Authentication module for a user. Handlers requests for logged in users,
 * provides authorization services and storage for tokens.
 */
@NgModule({
  imports: [CommonModule, OAuthModule.forRoot(), UserAuthEventModule],
})
export class VscaUserAuthModule extends UserAuthModule {
  static forRoot(): ModuleWithProviders<VscaUserAuthModule> {
    return {
      ngModule: VscaUserAuthModule,
      providers: [
        {
          provide: OAuthStorage,
          useExisting: AuthStorageService,
        },
        {
          provide: OAuthLibWrapperService,
          useExisting: VscaOAuthLibWrapperService,
        },
        {
          provide: OAuthService,
          useExisting: VscaOAuthService,
        },
        {
          provide: AuthService,
          useExisting: VscaAuthService,
        }
      ],
    };
  }
}
