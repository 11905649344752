import {
  Component,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import {
  SearchBoxComponentService,
  SearchBoxConfig
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { MaximumQuantityInputError } from 'src/app/shared/errors/MaximumQuantityInputError';
import { MinimumQuantityInputError } from 'src/app/shared/errors/MinimumQuantityInputError';
import { QuantityInputError } from 'src/app/shared/errors/QuantityInputError';
import { DataLayerService } from 'src/app/shared/services/data-layer.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { CustomAddToCartService } from '../../../../custom-add-to-cart/custom-add-to-cart.service';
import { CustomProdPgService } from '../../../../custom-prod-pg/custom-prod-pg.service';

@Component({
  selector: 'app-quick-order-indirect',
  templateUrl: './quick-order-indirect.component.html',
  styleUrls: ['./quick-order-indirect.component.scss'],
})
export class QuickOrderIndirectComponent implements OnInit, OnDestroy {
  @Output() selectedProductEvent = new EventEmitter<any>();
  quickOrderDetail = false;
  clearSearch = false;
  productInfo: any;
  buttonName: string;
  itemAddedQuantity: any;
  userDetails: any;
  cartId: any;
  cartItems: any;
  addQuery: string;
  QUICKORDER_SEARCH_BOX_CONFIG: SearchBoxConfig = {
    minCharactersBeforeRequest: 3,
    displayProducts: true,
    displaySuggestions: false,

    displayProductImages: true,
  };
  clearSearchValue = false;
  userDataSub: Subscription;
  allCartsSub: Subscription;
  createdCartSub: Subscription;
  updatedCartSub: Subscription;
  updatedCartDetailsSub: Subscription;
  @ViewChild('p2') public successPopover: NgbPopover;
  wrongQuantity: boolean = false;
  constructor(
    private readonly user: UserAccountFacade,
    private readonly config: NgbPopoverConfig,
    private readonly route: RoutingService,
    private readonly plpService: CustomProdPgService,
    protected searchBoxComponentService: SearchBoxComponentService,
    private readonly addToCartService: CustomAddToCartService,
    private readonly translation: TranslationService,
    private globalMessage: GlobalMessageService,
    private dataLayerService: DataLayerService
  ) {}

  @Input('additionalQuery')
  set additionalQuery(addQuery: string) {
    this.addQuery = addQuery;
  }

  ngOnInit(): void {
    if (this.productInfo?.flu === true) {
      this.buttonName = 'Passer ma commande';
    } else {
      this.buttonName = 'Ajouter au panier';
    }
    this.userDataSub = this.user.get().subscribe((res) => {
      this.userDetails = res;
      if (res !== undefined) {
        if (this.productInfo?.flu === true) {
          this.buttonName = 'Passer ma commande';
        } else {
          this.buttonName = 'Ajouter au panier';
        }
      } else {
        this.buttonName = 'Login to order';
      }
    });
    this.allCartsSub = this.addToCartService.allCartItems$.subscribe((res) => {
      if (res) {
        this.cartItems = res?.carts;
        this.cartItems = this.cartItems?.filter(
          (item) => item?.saveTime === undefined
        );
      }
    });
  }

  addToCart(idData, productCode): void {
    this.clearSearchValue = false;
    if (this.buttonName === 'Passer ma commande') {
      this.navToReservenow();
    } else if (this.buttonName === 'Ajouter au panier') {
      const inputElement = document.getElementById(
        `prodQuantity${idData}`
      ) as HTMLInputElement;
      const params = {
        product: {
          code: productCode,
        },
        quantity: inputElement?.value,
        addToCartType: "quickorder"
      };
      if (this.isIndirect()) {
        params['selectedB2BUnit'] = this.userDetails.wholesalerId;
      }
      this.itemAddedQuantity = inputElement?.value;
      if (this.cartItems && this.cartItems?.length === 0) {
        this.plpService.createCart(this.userDetails?.userName);
        this.createdCartSub = this.plpService.createdCart$.subscribe((res) => {
          if (res) {
            this.cartId = res?.code;
            this.addToCartService.getAllCarts(this.userDetails?.userName);
            this.plpService.updateCart(
              params,
              this.userDetails?.userName,
              this.cartId
            );
            this.updatedCartSub = this.plpService.cartDetailsUpdate$.subscribe(
              (resp) => {
                if (resp) {
                  this.successPopover.open();
                  setTimeout(() => this.successPopover.toggle(), 2500);
                }
              }
            );
          }
        });
      } else {
        if (this.cartId === undefined) {
          this.cartItems =
            this.cartItems && this.cartItems?.length > 0
              ? this.cartItems[this.cartItems?.length - 1]
              : [];
          this.cartId = this.cartItems?.code;
        }
        this.plpService.updateCart(
          params,
          this.userDetails?.userName,
          this.cartId
        );
        this.updatedCartDetailsSub =
          this.plpService.cartDetailsUpdate$.subscribe((res) => {
            if (res) {
              this.successPopover.open();
              this.allCartsSub = this.addToCartService.allCartItems$.subscribe(
                (res) => {
                  if (res) {
                    this.dataLayerService.viewUpdateCartDataLayer(
                      res,
                      'standard process'
                    );
                    this.allCartsSub.unsubscribe();
                  }
                }
              );
              setTimeout(() => this.successPopover.toggle(), 2500);
              this.clearSearch = true;
            }
          });
      }
    }
  }

  isIndirect(): boolean {
    return this.productInfo?.productType && this.productInfo?.productType === 'INDIRECT';
  }

  ngDoCheck() {
    if (!this.successPopover?.isOpen() && this.clearSearch) {
      this.quickOrderDetail = false;
      this.clearSearch = false;
      this.clearSearchValue = true;
    }
  }
  navToReservenow() {
    this.route.goByUrl('/influenza/category');
  }
  navToIndirectPLP() {
    this.route.goByUrl('/fr/fr/EUR/Open-Catalogue/c/1?query=:relevance:all:1:productType:INDIRECT');
  }

  performQuantityComputation(
    product,
    operator,
    prodCode,
    stepQuantity,
    minQuantity,
    maxQuantity
  ): void {
    this.globalMessage.remove(GlobalMessageType.MSG_TYPE_ERROR);
    this.wrongQuantity = false;
    try {
      ProductUtils.alterQuantity(
        operator,
        prodCode,
        stepQuantity,
        minQuantity,
        maxQuantity,
        'cart'
      );
    } catch (e: any) {
      if (e instanceof QuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputStepQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MinimumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMinimumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      } else if (e instanceof MaximumQuantityInputError) {
        this.wrongQuantity = true;
        this.translation
          .translate('addToCartItems.inputMaximumQuantityErrorMsg')
          .subscribe((res) => {
            this.globalMessage.add(
              product.name + ':' + res,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          });
      }
    }
  }
  addtoFluCart() {
    this.route.goByUrl('/influenza/category');
  }
  onProductSelected(product) {
    if (product?.code !== undefined) {
      this.productInfo = product;
      this.quickOrderDetail = true;
    } else if (product?.code === undefined) {
      this.quickOrderDetail = false;
    }
  }
  ngOnDestroy() {
    this.userDataSub?.unsubscribe();
    this.updatedCartDetailsSub?.unsubscribe();
    this.updatedCartSub?.unsubscribe();
    this.allCartsSub?.unsubscribe();
    this.createdCartSub?.unsubscribe();
  }
}
