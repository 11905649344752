import { Injectable } from '@angular/core';
import { ProductService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { DataLayerService } from '../shared/services/data-layer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomPdpService {
  public productCode = new BehaviorSubject<any>(false);
  productCode$ = this.productCode.asObservable();
  public cartItems = new BehaviorSubject<any>(false);
  cartItems$ = this.cartItems.asObservable();
  public createdCart = new BehaviorSubject<any>(false);
  createdCart$ = this.createdCart.asObservable();
  public cartDetailsUpdate = new BehaviorSubject<any>(false);
  cartDetailsUpdate$ = this.cartDetailsUpdate.asObservable();
  public minicartUpdate = new Subject<any>();
  constructor(
    private readonly productSearch: ProductService,
    private readonly baseService: BaseService,
    private readonly dataLayerService: DataLayerService
  ) {}

  public getPDPCategory(productCode): any {
    this.productSearch.get(productCode).subscribe((res) => {
      if (res) {
        this.productCode.next(res);
      }
    });
  }
  public updateCart(productDetails, userId, cartId, quantity): any {
    this.baseService
      .post(
        config.USER_ACCOUNT +
          userId +
          '/carts/' +
          cartId +
          '/entries?fields=DEFAULT',
        productDetails
      )
      .subscribe((res) => {
        if (res) {
          this.cartDetailsUpdate.next(true);
          this.minicartUpdate.next(cartId);
          this.dataLayerService.addCartDataLayer(
            res,
            'standard process',
            quantity,
            'pdp'
          );
        }
      });
  }
  getUpdateMiniCart(): Observable<any> {
    return this.minicartUpdate.asObservable();
  }
  getCarts(userId): void {
    this.baseService
      .get(
        config.USER_ACCOUNT +
          userId +
          '/carts?currentPage=0&fields=DEFAULT&pageSize=20&savedCartsOnly=false'
      )
      .subscribe((res) => {
        this.cartItems.next(res);
      });
  }
  createCart(userId): void {
    this.baseService
      .post(config.USER_ACCOUNT + userId + '/carts?fields=DEFAULT', {})
      .subscribe((res) => {
        this.createdCart.next(res);
      });
  }
}
