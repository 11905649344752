<div *ngIf="!formDetails">
  <span class="security-Notify">{{
    "userRegistration.userInformation.password.passwordRuleSection"
    | cxTranslate
    }}</span>
  <ul class="required-ul">
    <li>
      {{
      "userRegistration.userInformation.password.passwordCondition_1"
      | cxTranslate
      }}
    </li>
    <li>
      {{
      "userRegistration.userInformation.password.passwordCondition_2"
      | cxTranslate
      }}
    </li>
    <li>
      {{
      "userRegistration.userInformation.password.passwordCondition_3"
      | cxTranslate
      }}
    </li>
    <li>
      {{
      "userRegistration.userInformation.password.passwordCondition_4"
      | cxTranslate
      }}
    </li>
  </ul>
</div>