import { Component } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { BannerComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-banner',
  templateUrl: './custom-banner.component.html',
  styleUrls: ['./custom-banner.component.scss'],
})
export class CustomBannerComponent extends BannerComponent {
  protected setRouterLink(data: CmsBannerComponent): void {
    // SAP_LJaeger: first we give routerLink a default value
    this.routerLink = '/';
    // SAP_LJaeger: then we call super to set the correct value if available
    super.setRouterLink(data);
  }
}
