<div class="row patient-three-row">
  <ng-container *ngFor="let catalog of catalogsList">
    <div class="
        col-xs-12 col-sm-12 col-md-4
        patient-card
        checkout-details
        desktop-view
      ">
      <img [src]="getImage(catalog?.localizedPicture)" alt="" class="flu-poster" />
      <span (click)="openNewModalPopup(catalog.localizedPicture)" *ngIf="catalog && catalog?.localizedPicture">
        <img src="../../../assets/icons/fullscreen.svg" class="fullscreen" alt="full" />
      </span>
      <h3 title="{{catalog?.name}}">{{catalog?.name | customTruncate:[40, '...']}}</h3>
      <div *ngIf="catalog?.description" [innerHTML]="sanitizer.bypassSecurityTrustHtml(catalog?.description)"></div>
      <div class="row footer">
        <div class="col-xs-6 col-sm-6 col-md-8 p0">
          <p class="poster-numb">
            <span class="bold"> {{ catalog?.code }}</span> |
            {{ catalog?.downloadedMediaType }}
          </p>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-4 p0 download-sec" *ngIf="!catalog?.resourceUrl">
          <img src="../../../assets/icons/download-icon.svg" alt="download" />
          <a (click)="
              downloadFile(catalog, catalog?.localizedDownloadedMedia, catalog?.code)
            ">{{"orderDetail.download" | cxTranslate}}</a>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-4 p0 download-sec" *ngIf="catalog?.resourceUrl">
          <img src="../../../assets/icons/download-icon.svg" alt="download" />
          <a (click)="openLinkPopup(catalog?.resourceUrl);
            ">{{"common.openLink" | cxTranslate}}</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>