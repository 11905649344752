import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { CustomFooterNavigationComponent } from './custom-footer-navigation/custom-footer-navigation.component';
import { CustomGenericLinkComponent } from './custom-generic-link/custom-generic-link.component';
import { CustomNavigationUiComponent } from './custom-navigation-ui/custom-navigation-ui.component';
import { CustomNavigationComponent } from './custom-navigation/custom-navigation.component';

@NgModule({
  declarations: [
    CustomGenericLinkComponent,
    CustomFooterNavigationComponent,
    CustomNavigationUiComponent,
    CustomNavigationComponent,
  ],
  imports: [CommonModule, RouterModule, I18nModule, IconModule],
  providers: [
    provideConfig({
      cmsComponents: {
        FooterNavigationComponent: {
          component: CustomFooterNavigationComponent,
        },
        NavigationComponent: {
          component: CustomNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [
    CustomGenericLinkComponent
  ]
})
export class CustomNavigationModule {}
