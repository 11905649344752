<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add new card details</h4>
    <button type="button" class="close-banner" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <img alt="" src="../../assets/icons/close.png">
    </button>
</div>
<div class="modal-body">

    <iframe title="" #iframe id="valid-for-data" [height]="550" [width]="400" [src]="urlSafe" name="valid-for-data"
        (load)="onload()" *ngIf="iframeLoad"></iframe>

</div>
<div class=" modal-footer ">
    <div class="panel pay-invioce-panel" *ngIf="isCheckbox">
        <label class="adding-card-checkbox">
            <input type="checkbox" class="save-check" [checked]="defaultCard"
                [attr.disabled]="defaultCard ? true : null" (change)="saveCard($event)"> &nbsp;&nbsp;&nbsp; Add
            this card to the account on file
        </label>
    </div>
<div class="panel pay-invioce-panel" >
    <label class="adding-card-checkbox">
        <input type="checkbox" class="input-check" (change)="GetStats($event)"> &nbsp;&nbsp; Make this my default card
        
    </label>
</div>
<button class="button-stack  blue-button login-btn ml10" (click)=" onSubmitIFrame() ">Add card</button>
<button class="button-stack blue-button-cancel login-btn ml10 " (click)=" activeModal.close( 'Close click' ) ">Cancel</button>
   </div>
