<section class="col-md-8 col-sm-8 pay-section float-left account-reg-section">
  <app-user-header [selectedHeader]="userData?.header" [tableCategory]="creditInvoice"></app-user-header>
  <div class="
        col-xs-12 col-sm-12 col-md-12
        Registration-split
        confirmation-split
      ">
    <h3 class="pdp-title">
      {{ "userRegistration.userConfirmation.title" | cxTranslate }}
      {{ message?.title }}
    </h3>
    <p class="confirmation-txt">{{
      "userRegistration.userConfirmation.content" | cxTranslate
      }}</p>
    <br>
    <!-- If condition required from backend - if admin -->
    <div class="" *ngIf="isAdmin">
      <p class="confirmation-txt">{{"userRegistration.userConfirmation.content2" | cxTranslate}}</p>
      <p class="confirmation-txt">{{"userRegistration.userConfirmation.content3" | cxTranslate}}
        <span class="sanofi-platform-style">{{"userRegistration.userConfirmation.contentPlatform" | cxTranslate}}</span>
      </p>
      <p class="confirmation-txt">{{"userRegistration.userConfirmation.content4" | cxTranslate}}</p>
    </div>
    <div class="invoice-btngrps">
      <button type="button" class="btn btn-default oi-blue-button reviewvalidbtn" data-di-id="di-id-41a05d18-6b04a424"
        [routerLink]="['/Open-Catalogue/c/1']">
        {{ "userRegistration.userConfirmation.catalog_button" | cxTranslate }}
      </button>
    </div>
  </div>
</section>
