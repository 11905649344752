import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  CmsService,
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
  OccEndpointsService,
  RoutingService,
  TranslationService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { IndirectService } from 'src/app/custom-indirect/indirect.service';
import * as _ from 'underscore';
import { CustomAddToCartService } from '../../../custom-add-to-cart/custom-add-to-cart.service';
import { ProductDetailsComponent } from '../../../custom-add-to-cart/custom-add-to-cart/product-details/product-details.component';
import { CustomInfluenzaService } from '../../../custom-influenza/custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../../../custom-influenza/product-details-influenza/product-details-influenza.component';
import { CustomPdpService } from '../../../custom-pdp/custom-pdp.service';
import { CustomProfileAndPreferencesService } from '../../../custom-profile-and-preferences/custom-profile-and-preferences.service';
import { BaseService } from '../../services/base.service';
import { DataLayerService } from '../../services/data-layer.service';
import { SharedService } from '../../services/shared.service';
import { SubscriptionsContainer } from '../../utils/subscriptions-container';
import { AddCardComponent } from '../add-card/add-card.component';
import { AddCardService } from '../add-card/add-card.service';
import { CheckoutService } from '../checkout/checkout.service';
import { FileUploadService } from '../file-upload/file-upload.service';
import { IndirectCheckoutService } from './indirect-checkout.service';

@Component({
  selector: 'app-indirect-checkout',
  templateUrl: './indirect-checkout.component.html',
  styleUrls: ['./indirect-checkout.component.scss'],
})
export class IndirectCheckoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() cartDetails: any;
  @Input() stdCartType: boolean;
  @Input() accountLocData: any;
  @Input() activeSeasonId: any;

  @ViewChild('continueCheckout', { static: false })
  private readonly continueCheckout;
  paymentsArray: any = [];
  chosenPaymentMethod = 0;
  chosenCard: any;
  screenwidth: any;
  selectedHeader: any = 'Checkout';
  cartId: any;
  userId: any;
  standardCartDetails: any;
  productEntries: any = [];
  public images: any;
  updateEntriesData: any = [];
  subTotal: number;
  baseUrl: string;
  editNotClicked = true;
  userCardDataArray: any = [];
  userTemporaryCard: any;
  editStdForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$"),
    ]),
    mobile: new FormControl('', [Validators.pattern('^[0-9]{8,10}$')]),
    // mobile: new FormControl('', [Validators.required,
    // Validators.pattern('^[0-9]{4}-[0-9]{2}-[0-9]{2}-[0-9]{2}$'),
    // ]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{8,10}$'),
    ]),
    // phone: new FormControl('', [
    //   Validators.pattern('^[0-9]{3}-[0-9]{2}-[0-9]{2}-[0-9]{2}$'),
    // ]),
    role: new FormControl(''),
    emailId: new FormControl(''),
    newemailId: new FormControl('', [
      Validators.pattern('^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });
  userCardData: any = [];
  childCarts: any = [];
  orderContact: any;
  contacts: any = [];
  userRoles: any = [];
  estimatedDate: any = '';
  getuserData: any;
  userAccountgetData: any;
  selectedData: any;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.png';
  chosenEmail: any;
  orderNo: string;
  billingAddress: any;
  soldToAddress: any;
  selectedPaymentMethod: any;
  selectedcardHolderName: string;
  selectedcardNumber: string;
  selectedcardType: string;
  selectedexpDate: string;
  totalInfluenzaProducts: any = 0;
  totalGoodiesProducts: any = 0;
  goodiesProductPercentage: number;
  displayPaymentMethod: any;
  deleteEmail: boolean;
  usergetDataObj: any;
  disabled = true;
  addCurrentcard = false;
  freightProduct = false;
  @ViewChild('myPopover') myPopover: NgbPopover;
  userFname: any;
  userLname: any;
  userEmail: any;
  productsForExcel: any = [];
  checkoutSubscriber: any;
  hidePaymentMethod = false;
  langSelected: any;
  activeSeasonSubscriber: any;
  cardExpiredFlag = false;
  influenzaCartIdSubscriber: any;
  influenzaCreateCartSubscriber: any;
  addCardSubscriber: any;
  userAccountSubscriber: any;
  userAccountDetailsSubscriber: any;
  stdChekoutSubscriber: any;
  cardDetailsPfSubscriber: any;
  updateContactSubscriber: any;
  updatedStdOrderSubscriber: any;
  updatedInfluenzaOrderDetailsSubscriber: any;
  emailSubscriber: any;
  prodCodeSubscriber: any;
  uploadedFile: any;
  asmAgentSub: Subscription;
  agentEmulating: any;
  activeSeason: any;
  showProductQuantityNotice: boolean = false;
  subs = new SubscriptionsContainer();
  contactAddress: any;
  pageSub: Subscription;
  pageId: String = '';
  constructor(
    private readonly influenzaService: CustomInfluenzaService,
    private readonly checkoutService: CheckoutService,
    private readonly indirectCheckoutService: IndirectCheckoutService,
    private readonly user: UserAccountFacade,
    private readonly addToCartService: CustomAddToCartService,
    private readonly ref: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly router: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly pdpService: CustomPdpService,
    private readonly addCardService: AddCardService,
    private readonly globalMessage: GlobalMessageService,
    private readonly translation: TranslationService,
    private readonly lang: LanguageService,
    private readonly _csAgentAuthService: CsAgentAuthService,
    private readonly _fileUpload: FileUploadService,
    private readonly sharedService: SharedService,
    private readonly dataLayerService: DataLayerService,
    private readonly customInfluenzaService: CustomInfluenzaService,
    private readonly pageData: CmsService,
    private readonly baseService: BaseService,
    private indirectService: IndirectService
  ) {
    this.screenwidth = window.screen.width;
  }
  ngDoCheck() {
    if (this.userTemporaryCard && this.addCurrentcard) {
      this.chosenCard = 0;
      this.addCurrentcard = false;
    }
  }
  ngOnInit() {
    this.lang.getActive().subscribe((res) => {
      if (res) {
        this.langSelected = res;
        this.paymentsArray = [];
        if (res === 'en') {
          this.paymentsArray = [
            { name: 'Bill Me Later', code: 0 },
            { name: 'Pay by Card', code: 1 },
            { name: 'Manage Payment Methods', code: 2 },
          ];
        } else {
          this.paymentsArray = [
            { name: 'Facturez-moi plus tard', code: 0 },
            { name: 'Payer par carte', code: 1 },
            { name: 'Gérer méthodes de paiement', code: 2 },
          ];
        }
        this.displayPaymentMethod = this.paymentsArray.find(
          (item) => item.code === 0
        ).name;
      }
    });
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.user.get().subscribe((res: any) => {
      if (res) {
        this.userId = res?.userName;
        this.userFname = res?.firstName;
        this.userLname = res?.lastName;
        this.userEmail = res?.email;
        this.influenzaService.getCartIdofUser(this.userId);
        this.influenzaCartIdSubscriber =
          this.influenzaService.cartIdActiveOfUser.subscribe((cartIdData) => {
            if (cartIdData) {
              if (cartIdData?.carts?.length > 0) {
                const cartIdResponse = cartIdData?.carts;
                let activeCartIdArray = [];
                activeCartIdArray = cartIdResponse?.filter(
                  (item) => item?.saveTime === undefined
                );
                this.cartId =
                  activeCartIdArray[activeCartIdArray?.length - 1]?.code;
                if (this.stdCartType) {
                  this.getContacts();
                }
              }
              // else {
              //   this.influenzaService.CreateCartIdofUser(this.userId);
              //   this.influenzaCreateCartSubscriber = this.influenzaService.createCartOfUser.subscribe((responseCartId) => {
              //     if (responseCartId) {
              //       this.cartId = responseCartId?.code;
              //     }
              //   });
              // }

              /* Check Schedule to shoi quantity and show notice if more are needed*/
              let childInfluenzaCarts = [];
              childInfluenzaCarts = cartIdData?.carts[0]?.childCarts;
              if (childInfluenzaCarts) {
                childInfluenzaCarts = childInfluenzaCarts.filter(
                  (item) => item.cartType === 'INFLUENZA'
                );
                if (this.pageId == 'fluseasonviewpaymentpage') {
                  this.dataLayerService.beginCheckoutDataLayer(
                    childInfluenzaCarts,
                    'new reservation'
                  );
                }
              }
              if (childInfluenzaCarts?.length > 0) {
                this.customInfluenzaService.getScheduleToShip(
                  this.userId,
                  cartIdData.carts[0].code
                );
                this.subs.add =
                  this.customInfluenzaService.scheduleToShipData.subscribe(
                    (stsData) => {
                      const productSchedule = stsData.productSchedules;
                      this.checkScheduleToShipQuantity(productSchedule);
                      this.ref.detectChanges();
                    }
                  );
              }
              this.influenzaCartIdSubscriber.unsubscribe();
            }
          });
        this.settingStdCartAccountDetals();
        this.addToCartService.getAllCarts(res?.userName);
        if (this.stdCartType) {
          this.getUserRoles();
          this.indirectCartCheckoutDetails();
        } else {
          this.influenzaCartCheckoutDetails();
        }
      }
    });
    this.chosenPaymentMethod = 0;
    this.addCardSubscriber = this.addCardService.cardAdded.subscribe((res) => {
      if (res) {
        if (!res?.defaultPayment) {
          let card: any;
          if (this.langSelected === 'fr') {
            card = `${
              res?.cardType?.code
            } Carte(XXXX-XXXX-XXXX-${res?.cardNumber.substring(6, 10)})`;
          } else {
            card = `${
              res?.cardType?.code
            } Card(XXXX-XXXX-XXXX-${res?.cardNumber.substring(6, 10)})`;
          }
          this.userTemporaryCard = {
            name: card,
            code: 0,
            cardHolderName: res?.accountHolderName,
            cardNumber: res?.cardNumber,
            cardType: res?.cardType?.code,
            expDate: res?.validTo,
            creditCardDefault: false,
          };
          this.addCurrentcard = true;
        }
      }
    });
    this.asmAgentSub = this._csAgentAuthService
      .isCustomerEmulated()
      .subscribe((res) => {
        this.agentEmulating = res;
      });
    this.pageSub = this.pageData.getCurrentPage().subscribe((res) => {
      this.pageId = res?.pageId;
      this.pageSub?.unsubscribe();
    });
  }
  ngAfterViewInit(): void {
    if (!this.stdCartType) {
      this.myPopover.open();
    }
  }

  private checkScheduleToShipQuantity(product) {
    //Checked products and redirect if all has 1 week
    let weekLength = [];
    product.map((product) => {
      weekLength.push(product.deliveries.length);
    });
    if (weekLength.every(this.checkWeekCount)) {
      if(!this.stdCartType){
        this.showProductQuantityNotice = true;
      }
      let prodSched = new Array();
      product.forEach((element) => {
        element.deliveries[0].requestedQuantity = element.quantity;
        const prodSchedule = {
          code: element.code,
          deliveries: element.deliveries,
        };
        prodSched.push(prodSchedule);
      });
      const prodSchedules = {
        productSchedules: prodSched,
      };
      this.subs.add = this.customInfluenzaService
        .postcheduleToShip(this.userId, this.cartId, prodSchedules)
        .subscribe((res) => {});
    }
  }

  private checkWeekCount(data) {
    //validate the week is one - for redirecting
    return data == 1;
  }

  getUploadFile(file): void {
    this.uploadedFile = file;
  }

  orderNoUpdate() {
    this.dwnloadAsExcel();
  }

  dwnloadAsExcel() {
    this.productsForExcel = [];

    this.productEntries.forEach((entries, index) => {
      this.productsForExcel.push({
        'N° SIRET':
          this.standardCartDetails?.shipToParty?.billingAddress?.unitID,
        'N° Article': entries?.product?.code,
        Quantité: entries?.quantity,
        'Ma référence': this.orderNo,
        'Code CIP Produit': entries?.product?.cipCode,
        'Libellé article': entries?.product?.name,
        'Prix unitaire HT': entries?.basePrice?.value,
        'Prix total article HT': entries?.totalPrice?.value,
      });

      if (index + 1 === this.productEntries.length) {
        this.influenzaService.dataForExcel = this.productsForExcel;
      }
    });
  }
  influenzaCartCheckoutDetails() {
    this.goodiesProductPercentage = this.sharedService.getSessionStorage('maxOrderPercentage');
    this.influenzaService.getActiveSeasonsFromDb(this.userId);
    this.activeSeasonSubscriber = this.influenzaService.activeSeaons
      .subscribe(
      (data) => {
        if (data) {
          this.activeSeason = data?.categoryData[0];
          this.activeSeasonId = data?.categoryData?.[0]?.code;
        }
      }
    );
    this.checkoutSubscriber =
      this.influenzaService.checkoutInfluenza$.subscribe((res: any) => {
        if (res) {
          let stdCartDetails = [];
          stdCartDetails = res?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === 'INFLUENZA'
            );
            this.standardCartDetails = Object.assign({}, ...stdCartDetails);
            this.orderContact = this.standardCartDetails.orderContact;
            for (const cartDetails of stdCartDetails) {
              for (let entries of cartDetails.entries) {
                if (entries?.product?.onsn === 'ON') {
                  this.estimatedDate = entries?.estimatedDeliveryDate;
                }
                if (entries?.freightProduct) {
                  this.freightProduct = entries?.freightProduct;
                }
                const imagesFormat = _.clone(
                  _.findWhere(entries.product?.images, { format: 'product' })
                );
                if (imagesFormat) {
                  imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
                }
                this.images = { PRIMARY: imagesFormat };
                entries = { ...entries, ...this.images };
                this.totalInfluenzaProducts = 0;
                this.productEntries.push(entries);
              }
            }
            let onsnCounter = 0;
            this.productEntries?.forEach((element, index) => {
              if (element?.product?.onsn === 'ON') {
                onsnCounter += 1;
              }
              if (element?.product?.productType != 'GOODIES') {
                this.totalInfluenzaProducts += parseInt(element.quantity);
              } else {
                this.totalGoodiesProducts += parseInt(element.quantity);
              }
              if (index + 1 === this.productEntries.length) {
                if (onsnCounter === 0) {
                  this.hidePaymentMethod = true;
                  this.ref.detectChanges();
                }
                if (this.standardCartDetails?.deliveryAddress) {
                  this.selectedData = this.standardCartDetails?.deliveryAddress;
                } else {
                  if (this.getuserData && this.getuserData?.length > 0) {
                    this.selectedData = _.first(
                      this.getuserData.filter(
                        (item) => item.unitID === this.userAccountgetData?.uid
                      )
                    );
                  }
                }
                this.dwnloadAsExcel();
              }
              this.ref.detectChanges();
            });
            this.ref.detectChanges();
          }
        }
        this.checkoutSubscriber?.unsubscribe();
      });
  }
  settingStdCartAccountDetals() {
    this.checkoutService.getUserAccountDataFromDb(this.userId);
    this.userAccountSubscriber = this.checkoutService.userAccountData.subscribe(
      (userData) => {
        if (
          userData.res != null &&
          userData.res !== undefined &&
          userData.res?.length !== 0
        ) {
          this.usergetDataObj = userData.res.defaultB2BUnit;
          this.contactAddress = userData.res?.defaultB2BUnit?.contactAddress;
          const accountId = this.usergetDataObj?.uid;
          //this.getCardDetailsForStdCart(accountId);
          this.checkoutService.getUserAccountDetailsDataFromDb(
            this.userId,
            accountId
          );
        }
      }
    );
    this.userAccountDetailsSubscriber =
      this.checkoutService.userAccountDetails$.subscribe((userDataget) => {
        if (userDataget) {
          this.getuserData = userDataget.res.shippingAddresses;
        }
      });
    this.ref.detectChanges();
  }
  stdCartCheckoutDetails() {
    this.stdChekoutSubscriber =
      this.addToCartService.checkoutDetails$.subscribe((res: any) => {
        if (res) {
          let stdCartDetails = [];
          this.productEntries = [];
          stdCartDetails = res?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === 'INDIRECT'
            );
            if (this.pageId != 'fluseasonviewpaymentpage') {
              this.dataLayerService.beginCheckoutDataLayer(
                stdCartDetails,
                'standard'
              );
            }

            this.standardCartDetails = Object.assign({}, ...stdCartDetails);
            this.orderContact = this.standardCartDetails.orderContact;
            for (const cartDetails of stdCartDetails) {
              for (let entries of cartDetails.entries) {
                this.estimatedDate = entries?.estimatedDeliveryDate;
                if (entries?.freightProduct) {
                  this.freightProduct = entries?.freightProduct;
                }
                const imagesFormat = _.clone(
                  _.findWhere(entries.product?.images, { format: 'thumbnail' })
                );
                if (imagesFormat) {
                  imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
                }
                this.images = { PRIMARY: imagesFormat };
                entries = { ...entries, ...this.images };
                this.productEntries.push(entries);
              }
            }
            if (this.standardCartDetails?.deliveryAddress) {
              this.selectedData = this.standardCartDetails?.deliveryAddress;
            } else {
              if (this.getuserData && this.getuserData?.length > 0) {
                this.selectedData = _.first(
                  this.getuserData.filter(
                    (item) => item.unitID === this.userAccountgetData?.uid
                  )
                );
              }
            }
            this.ref.detectChanges();
          }
        }
      });
  }
  indirectCartCheckoutDetails() {
    this.stdChekoutSubscriber =
      this.addToCartService.checkoutDetails$.subscribe((res: any) => {
        if (res) {
          let stdCartDetails = [];
          this.productEntries = [];
          stdCartDetails = res?.childCarts;
          if (stdCartDetails) {
            stdCartDetails = stdCartDetails.filter(
              (item) => item.cartType === 'INDIRECT'
            );
            if (this.pageId != 'fluseasonviewpaymentpage') {
              this.dataLayerService.beginCheckoutDataLayer(
                stdCartDetails,
                'standard'
              );
            }

            this.standardCartDetails = Object.assign({}, ...stdCartDetails);
            this.orderContact = this.standardCartDetails.orderContact;
            for (const cartDetails of stdCartDetails) {
              for (let entries of cartDetails.entries) {
                this.estimatedDate = entries?.estimatedDeliveryDate;
                if (entries?.freightProduct) {
                  this.freightProduct = entries?.freightProduct;
                }
                const imagesFormat = _.clone(
                  _.findWhere(entries.product?.images, { format: 'thumbnail' })
                );
                if (imagesFormat) {
                  imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
                }
                this.images = { PRIMARY: imagesFormat };
                entries = { ...entries, ...this.images };
                this.productEntries.push(entries);
              }
            }
            if (this.standardCartDetails?.deliveryAddress) {
              this.selectedData = this.standardCartDetails?.deliveryAddress;
            } else {
              if (this.getuserData && this.getuserData?.length > 0) {
                this.selectedData = _.first(
                  this.getuserData.filter(
                    (item) => item.unitID === this.userAccountgetData?.uid
                  )
                );
              }
            }
            this.ref.detectChanges();
          }
        }
      });
  }
  getCardDetailsForStdCart(accountId) {
    this.selectedcardHolderName = '';
    this.selectedcardNumber = '';
    this.selectedcardType = '';
    this.selectedexpDate = '';
    this.selectedPaymentMethod = 'BILL_ME_LATER';

    this.pfService.getCardFromDb(accountId);

    this.cardDetailsPfSubscriber = this.pfService.cardDetails.subscribe(
      (data) => {
        if (data) {
          this.userCardDataArray = [];
          if (this.userTemporaryCard) {
            this.userCardDataArray.push(this.userTemporaryCard);
          }
          this.userCardData = data.sanofiPaymentsResponseList;
          if (this.langSelected === 'fr') {
            if (this.userCardData.length > 0) {
              this.userCardData.forEach((element, index) => {
                const card = `${
                  element.cardType
                } Carte(XXXX-XXXX-XXXX-${element.cardNumber.substring(6, 10)})`;
                const objCard = {
                  name: card,
                  code: index + 1,
                  cardHolderName: element.creditCardName,
                  cardNumber: element.cardNumber,
                  cardType: element.cardType,
                  expDate: element.expDate,
                  creditCardDefault: element.creditCardDefault,
                };
                this.userCardDataArray.push(objCard);
              });
            }
          } else {
            if (this.userCardData.length > 0) {
              this.userCardData.forEach((element, index) => {
                const card = `${
                  element.cardType
                } Card(XXXX-XXXX-XXXX-${element.cardNumber.substring(6, 10)})`;
                const objCard = {
                  name: card,
                  code: index + 1,
                  cardHolderName: element.creditCardName,
                  cardNumber: element.cardNumber,
                  cardType: element.cardType,
                  expDate: element.expDate,
                  creditCardDefault: element.creditCardDefault,
                  cardExpired: element.cardExpired,
                };
                this.userCardDataArray.push(objCard);
              });
            }
          }
          if (!this.userTemporaryCard) {
            this.chosenCard = this.userCardDataArray.find(
              (item) => item.creditCardDefault === true
            ).code;
          } else {
            this.chosenCard = 0;
          }
          let sltdCardDeatils = this.userCardDataArray.filter(
            (item) => item.code === this.chosenCard
          );
          sltdCardDeatils = Object.assign({}, ...sltdCardDeatils);
          this.selectedcardHolderName = sltdCardDeatils.cardHolderName;
          this.selectedcardNumber = sltdCardDeatils.cardNumber;
          this.selectedcardType = sltdCardDeatils.cardType;
          this.selectedexpDate = sltdCardDeatils.expDate;
          this.cardExpiredFlag = sltdCardDeatils.cardExpired;
          this.ref.detectChanges();
        }
      }
    );
  }

  updateOrderContact(): void {
    const params = {
      email: this.editStdForm.controls.emailId.value,
      mobileNo: this.editStdForm.controls.mobile.value
        ? this.editStdForm.controls.mobile.value
        : '',
      name: this.editStdForm.controls.firstName.value,
      phoneNo: this.editStdForm.controls.phone.value
        ? this.editStdForm.controls.phone.value
        : '',
      role: this.editStdForm.controls.role.value
        ? this.editStdForm.controls.role.value
        : '',
      standardCart: true,
    };
    if (this.chosenEmail === 'Add Other E-mail Address') {
      params['addEmail'] = this.editStdForm.controls.newemailId.value;
      params.email = '';
      this.editStdForm.controls['newemailId'].setValidators([
        Validators.required,
        Validators.pattern('^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]);
      this.editStdForm.controls['newemailId'].updateValueAndValidity();
      this.editStdForm.controls['emailId'].clearValidators();
      this.editStdForm.controls['emailId'].updateValueAndValidity();
    } else {
      this.editStdForm.controls['emailId'].setValidators(Validators.required);
      this.editStdForm.controls['emailId'].updateValueAndValidity();
      this.editStdForm.controls['newemailId'].clearValidators();
      this.editStdForm.controls['newemailId'].updateValueAndValidity();
    }
    if (this.editStdForm.valid) {
      this.checkoutService.updateContact(params, this.userId, this.cartId);
      this.updateContactSubscriber =
        this.checkoutService.updatedContact.subscribe((res) => {
          if (res) {
            this.orderContact = res;
            this.ref.detectChanges();
          }
        });
      this.editNotClicked = true;
    }
  }
  placeOrder() {
    if (this.selectedPaymentMethod === 'BILL_ME_LATER') {
      this.selectedcardHolderName = '';
      this.selectedcardNumber = '';
      this.selectedcardType = '';
      this.selectedexpDate = '';
    }
    const questionaireData = JSON.parse(this.indirectService.getLocalData("questionnaire"));
    const params = {
      cardHolderName: this.selectedcardHolderName,
      cardNumber: this.selectedcardNumber,
      cardType: this.selectedcardType,
      expDate: this.selectedexpDate,
      paymentMethod: 'BILL_ME_LATER',
      purchaseOrderNumber: this.orderNo ? this.orderNo : '',
      seasonId: !this.stdCartType ? this.activeSeasonId : '',
      standardCart: this.stdCartType,
      cartType: 'INDIRECT',
      questionnaireAnswers: questionaireData
    };
    this.standardCartDetails.hasIban = this.usergetDataObj?.hasIban;
    this.indirectCheckoutService.updateOrder(this.userId, this.cartId, params);
    this.modalService.dismissAll();
    this.updatedStdOrderSubscriber =
      this.indirectCheckoutService.updatedStdOrderDetails$.subscribe((res) => {
        if (res && this.stdCartType) {
          this.router.goByUrl('/indirect/place-order-confirmation');
          this.sharedService.setSessionStorage(
            'cartData',
            this.standardCartDetails
          );
        }
      });

    this.updatedInfluenzaOrderDetailsSubscriber =
      this.checkoutService.updatedOrderDetails.subscribe((res) => {
        if (!this.stdCartType && res) {
          this.addToCartService.getAllCarts(this.userId);
          this.influenzaService.checkoutInfluenza?.next(false);
          this.router.goByUrl('/influenza/orderConfirmation');
          this.sharedService.setSessionStorage(
            'cartData',
            this.standardCartDetails
          );
        }
      });
  }

  getContacts(): void {
    this.baseService
      .get('users/' + this.userId + '/contacts')
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.contacts = res;
        }
      });
  }

  editContact() {
    let altPhone = this.orderContact?.phoneNo.replace('+', '');
    altPhone = altPhone.split(' ').join('');
    if (altPhone != null && altPhone.length != 10) {
      this.editStdForm.controls['phone'].markAsDirty();
    }
    let altMobile;
    if (this.orderContact?.mobileNo != undefined) {
      altMobile = this.orderContact?.mobileNo.split(' ').join('');
    }
    let chosenRole = this.userRoles.find(
      (role) => role.name === this.orderContact?.role
    ).code;
    this.editNotClicked = false;
    this.editStdForm.patchValue({
      firstName: this.orderContact?.name,
      emailId: this.orderContact?.email,
      phone: altPhone ? altPhone : '',
      mobile: altMobile ? altMobile : '',
      role: chosenRole ? chosenRole : '',
    });
    this.chosenEmail = this.orderContact?.email ? this.orderContact.email : '';
  }

  changeEmail() {
    let chosenContact = this.contacts.find(
      (contact) => contact.email === this.chosenEmail
    );
    let chosenRole = this.userRoles.find(
      (role) => role.name === chosenContact?.role
    ).code;

    this.editStdForm.patchValue({
      firstName: chosenContact?.name,
      emailId: chosenContact?.email,
      phone: chosenContact?.phoneNo,
      role: chosenRole,
    });
  }

  onCancelClick() {
    this.editNotClicked = true;
  }
  getPaymentDetailsOfUser(event) {
    this.selectedPaymentMethod = event.name;
    if (event.code === 2) {
      this.router.goByUrl('/my-account/profile');
      this.checkoutService.tabSelection('Payment Settings');
    }
    if (event.code === 0) {
      this.selectedcardHolderName = '';
      this.selectedcardNumber = '';
      this.selectedcardType = '';
      this.selectedexpDate = '';
      this.selectedPaymentMethod = 'BILL_ME_LATER';
      this.displayPaymentMethod = this.paymentsArray.find(
        (item) => item.code === 0
      ).name;
    }
    if (event.code === 1) {
      this.selectedPaymentMethod = '0000';
      this.displayPaymentMethod = this.paymentsArray.find(
        (item) => item.code === 1
      ).name;
      if (this.cardExpiredFlag) {
        this.translation
          .translate('addToCartItems.cardExpMsg')
          .subscribe((res) => {
            this.globalMessage.add(res, GlobalMessageType.MSG_TYPE_ERROR);
          });
      }
    }
  }
  getCardDetailsOfUser(event) {
    this.selectedcardHolderName = event.cardHolderName;
    this.selectedcardNumber = event.cardNumber;
    this.selectedcardType = event.cardType;
    this.selectedexpDate = event.expDate;
    this.cardExpiredFlag = event.cardExpired;
    if (this.cardExpiredFlag) {
      this.translation
        .translate('addToCartItems.cardExpMsg')
        .subscribe((res) => {
          this.globalMessage.add(res, GlobalMessageType.MSG_TYPE_ERROR);
        });
    }
  }
  openModal(event) {
    const modref = this.modalService.open(AddCardComponent);
    modref.componentInstance.isCheckbox = true;
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }
  navigateToCart() {
    this.router.goByUrl('/indirect/cart');
  }
  checkUser(): void {
    if (this.orderNo == undefined || this.orderNo == '') {
      this.globalMessage.add(
        "Merci d'indiquer une référence de commande qui vous est propre afin de poursuivre",
        GlobalMessageType.MSG_TYPE_ERROR
      );
    } else {
      this.checkout();
    }
  }
  checkout(): void {
    if (this.cardExpiredFlag) {
      this.translation
        .translate('formErrors.globalMessage')
        .subscribe((res) => {
          this.globalMessage.add(res, GlobalMessageType.MSG_TYPE_ERROR);
        });
    } else {
      if (
        this.selectedcardNumber ||
        this.displayPaymentMethod === 'Bill Me Later' ||
        this.displayPaymentMethod === 'Facturez-moi plus tard'
      ) {
        this.modalService.open(this.continueCheckout);
      } else {
        this.translation
          .translate('addToCartItems.invalidPayment')
          .subscribe((res) => {
            this.globalMessage.add(res, GlobalMessageType.MSG_TYPE_ERROR);
          });
      }
    }
  }

  productDetails(productDetails): void {
    let updatedCurrentProd = [];
    const productAndCartItemMerged = {
      ...productDetails,
      ...productDetails.product,
      price: productDetails.basePrice,
    };
    updatedCurrentProd.push(productAndCartItemMerged);
    if (this.stdCartType) {
      const modalRef = this.modalService.open(ProductDetailsComponent, {
        windowClass: 'product-details-modal',
      });
      modalRef.componentInstance.productDetails = productAndCartItemMerged;
      modalRef.componentInstance.standardCartCode =
        this.standardCartDetails.code;
      modalRef.componentInstance.unitSection = 'NoUnits';
    } else {
      // this.pdpService.getPDPCategory(productDetails.product.code);
      // this.prodCodeSubscriber = this.pdpService.productCode$.subscribe(
      //   (res) => {
      //     if (res && res?.code === productDetails.product.code) {
      //       this.modalService.dismissAll();
      //       const modalRef = this.modalService.open(
      //         ProductDetailsInfluenzaComponent,
      //         { windowClass: 'product-details-inf-modal' }
      //       );
      //       modalRef.componentInstance.productDetails = res;
      //       modalRef.componentInstance.standardCartCode = res.code;
      //     }
      //   }
      // );
      const modalRef = this.modalService.open(
        ProductDetailsInfluenzaComponent,
        {
          windowClass: 'product-details-inf-modal',
        }
      );
      modalRef.componentInstance.productDetails = productAndCartItemMerged;
      modalRef.componentInstance.standardCartCode =
        productDetails.product?.code;
    }
  }
  billingAccount() {
    window.open('/my-account/profile');
//     this.router.goByUrl('/my-account/profile');
    this.checkoutService.tabSelection('Account information');
    return false;
  }
  editOrder(): void {
    this.modalService.dismissAll();
  }
  delEmail() {
    this.editStdForm.controls['newemailId'].setValue('');
    this.chosenEmail = this.orderContact.email;
  }

  private getUserRoles() {
    this.baseService
      .get('/enumeration?enumerationType=UserRole')
      .subscribe((res) => {
        if (res !== null && res !== undefined && res?.length !== 0) {
          this.userRoles = res;
        }
      });
  }

  ngOnDestroy() {
    this.influenzaCartIdSubscriber?.unsubscribe();
    this.influenzaCreateCartSubscriber?.unsubscribe();
    this.addCardSubscriber?.unsubscribe();
    this.activeSeasonSubscriber?.unsubscribe();
    this.checkoutSubscriber?.unsubscribe();
    this.userAccountSubscriber?.unsubscribe();
    this.userAccountDetailsSubscriber?.unsubscribe();
    this.stdChekoutSubscriber?.unsubscribe();
    this.cardDetailsPfSubscriber?.unsubscribe();
    this.updateContactSubscriber?.unsubscribe();
    this.updatedStdOrderSubscriber?.unsubscribe();
    this.updatedInfluenzaOrderDetailsSubscriber?.unsubscribe();
    this.emailSubscriber?.unsubscribe();
    this.prodCodeSubscriber?.unsubscribe();
    this.asmAgentSub?.unsubscribe();
    this.subs.dispose();
    this.pageSub?.unsubscribe();
  }
}
