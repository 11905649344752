import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import { CmsConfig, ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SearchBoxModule } from '../search-box/search-box.module';
import { CheckoutModule } from '../shared/modules/checkout/checkout.module';
import { IndirectCheckoutModule } from '../shared/modules/indirect-checkout/indirect-checkout.module';
import { QuickOrderModule } from '../shared/modules/quick-order/quick-order.module';
import { MainPipeModule } from '../shared/pipes/mainpipe.module';
import { CustomAddToCartComponent } from './custom-add-to-cart/custom-add-to-cart.component';
import { EmptyCartComponent } from './custom-add-to-cart/empty-cart/empty-cart.component';
import { ProductDetailsComponent } from './custom-add-to-cart/product-details/product-details.component';
import { StandardCartComponent } from './custom-add-to-cart/standard-cart/standard-cart.component';
import { EditOrdersComponent } from './edit-orders/edit-orders.component';
import { IndirectCartCheckoutComponent } from './indirect-cart-checkout/indirect-cart-checkout.component';
import { IndirectCartConfirmationComponent } from './indirect-cart-confirmation/indirect-cart-confirmation.component';
import { MiniCartComponent } from './mini-cart/mini-cart.component';
import { SavedOrdersComponent } from './saved-orders/saved-orders.component';
import { StandardCartCheckoutComponent } from './standard-cart-checkout/standard-cart-checkout.component';
import { StandardCartConfirmationComponent } from './standard-cart-confirmation/standard-cart-confirmation.component';

@NgModule({
  declarations: [
    CustomAddToCartComponent,
    StandardCartComponent,
    EmptyCartComponent,
    ProductDetailsComponent,
    MiniCartComponent,
    SavedOrdersComponent,
    EditOrdersComponent,
    StandardCartCheckoutComponent,
    StandardCartConfirmationComponent,
    IndirectCartCheckoutComponent,
    IndirectCartConfirmationComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgSelectModule,
    IndirectCheckoutModule,
    CheckoutModule,
    SearchBoxModule,
    QuickOrderModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SavedCartsComponent: {
          component: SavedOrdersComponent,
        },
        savedOrderDetailsPageComponent: {
          component: EditOrdersComponent,
        },
        PlaceOrderConfirmationComponent: {
          component: StandardCartConfirmationComponent
        }
      },
    } as CmsConfig),
    MainPipeModule
  ],
  providers: [
    provideOutlet({
      id: 'EmptyCartMiddleContent',
      position: OutletPosition.REPLACE,
      component: CustomAddToCartComponent,
    }),
    provideOutlet({
      id: 'MiniCart',
      position: OutletPosition.REPLACE,
      component: MiniCartComponent,
    }),
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class CustomAddToCartModule { }
