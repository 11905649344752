import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgbModule,
  NgbPopoverModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import {
  I18nModule,
  provideConfig
} from '@spartacus/core';
import { CustomUserRegistrationModule } from 'src/app/custom-user-registration/custom-user-registration.module';
import { MainPipeModule } from '../../pipes/mainpipe.module';
import { PhoneFormatPipeModule } from '../../pipes/phone-format/phone-format.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { TermsConditionsModule } from '../terms-conditions/terms-conditions.module';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions/terms-conditions.component';
import { IndirectCheckoutComponent } from './indirect-checkout.component';
import {CheckoutModule} from '../checkout/checkout.module';
import {IndirectTotalPricesComponent} from './indirect-total-prices/indirect-total-prices.component';
@NgModule({
  declarations: [IndirectCheckoutComponent, IndirectTotalPricesComponent],
  exports: [IndirectCheckoutComponent, IndirectTotalPricesComponent],
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    PhoneFormatPipeModule,
    CustomUserRegistrationModule,
    I18nModule,
    NgbPopoverModule,
    NgbModule,
    TermsConditionsModule,
    MainPipeModule,
    FileUploadModule,
    CheckoutModule,
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
      cmsComponents: {
        checkoutPage: {
          component: TermsConditionsComponent
        }
      }
    }),
  ],
})
export class IndirectCheckoutModule {}
