import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { translationChunksConfig } from '@spartacus/assets';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { SelectWhsPopupComponent } from './select-whs-popup.component';


@NgModule({
  declarations: [SelectWhsPopupComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SwitchWhsPopupComponent: {
          component: SelectWhsPopupComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
    FormsModule,
    I18nModule,
  ],
  exports: [SelectWhsPopupComponent],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class SelectWhsPopupModule {
}
