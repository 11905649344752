import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomTruncatePipe } from './custom-truncate.pipe';
import { HyphenToForwardSlashPipe } from './hyphen-to-forwardslash.pipe';
import { InvoiceCurrencyPipe } from './invoicecurrency';
import { LocalCurrencyPipe } from './localcurency';

@NgModule({
  declarations: [
    LocalCurrencyPipe,
    CustomTruncatePipe,
    InvoiceCurrencyPipe,
    HyphenToForwardSlashPipe,
  ],
  imports: [CommonModule],
  exports: [
    LocalCurrencyPipe,
    CustomTruncatePipe,
    InvoiceCurrencyPipe,
    HyphenToForwardSlashPipe,
  ],
})
export class MainPipeModule {}
