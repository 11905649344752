import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NeedToGoBackComponent } from './need-to-go-back/need-to-go-back.component';



@NgModule({
  declarations: [NeedToGoBackComponent],
  imports: [
    CommonModule
  ],
  exports:[NeedToGoBackComponent]
})
export class NeedToGoBackModule { }
