import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewImportantBannerComponent } from './overview-important-banner/overview-important-banner.component';



@NgModule({
  declarations: [OverviewImportantBannerComponent],
  imports: [
    CommonModule
  ],
  exports: [OverviewImportantBannerComponent]
})
export class OverviewBannerModule { }
