import { OccConfig, OccEndpoints } from '@spartacus/core';

export const CustomPdpConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        products: '/${users}/${userId}/products/${productCode}/',
      } as OccEndpoints,
    },
  },
};
