import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaqTitleComponent } from './faq-title/faq-title.component';

@NgModule({
  declarations: [FaqTitleComponent],
  imports: [
    CommonModule
  ],
  exports: [FaqTitleComponent]
})
export class FaqTitleModule { }
