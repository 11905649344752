<div class="modal-header relative">
  <h4 class="modal-title-top20">{{'addToCartItems.prodDetails'|cxTranslate}}</h4>
  <div class="cls-btn">
    <img src="../../assets/icons/close.png" (click)="close()" alt="">
  </div>
</div>
<div class="modal-body miniCart-popover">
  <div class="row row-20 product-details pb-3" id="leftCont-rightNav">
    <section class="row row-20">
      <section class="container-pdp">
        <aside aria-label="Product Details" id="prodImg"
          class="col-xs-12 col-sm-4 col-md-4 right-navigation float-right">
          <a *ngIf="productDetails?.product?.images != undefined || productDetails?.product?.images?.length > 0">

            <img class="image-product" src="{{productDetails?.PRIMARY?.url}}" alt="">

          </a>
          <a *ngIf="productDetails?.product?.images === undefined || productDetails?.product?.images?.length === 0">
            <img class="image-set-in-card product-img" [src]="fallbackImage" alt="">
          </a>
        </aside>
        <section class="col-xs-12 col-sm-8 col-md-8 left-section pb-2 float-left">
          <h3 class="pdp-title" [innerHTML]="productDetails?.product?.name"></h3>
          <p class="desc-class" [innerHTML]="description"></p>
          <br>
          <section class="batch-info">
            <p class="desc-title" *ngIf="productDetails?.product?.batchInfo?.batchNumber">
              {{'plp.batchNumber'|cxTranslate}}
              <span class="desc-details">{{productDetails?.product?.batchInfo?.batchNumber}}</span>
            </p>
            <p class="desc-title" *ngIf="productDetails?.product?.batchInfo?.batchNumber">
              {{'plp.expiryDate'|cxTranslate}} :
              <span class="desc-details">{{productDetails?.product?.batchInfo?.expiryDate}}</span>
            </p>
            <p *ngIf="productDetails?.product?.batchInfo?.batchNumber">{{'plp.batchInfo'|cxTranslate}}</p>
            <p class="desc-title">{{'plp.cipCode'|cxTranslate}} :
              <span class="desc-details"
                *ngIf="productDetails?.product.cipCode !== undefined">{{productDetails?.product?.cipCode}}</span>
            </p>
            <hr class="line">
            <section class="sap-allocation-area"
              *ngIf="!productDetails?.product?.discontinued && productDetails?.product?.available && !productDetails?.product?.flu">
              <section *ngIf="productDetails?.product?.sapAllocation === true">
                <b class="prod-allocation">{{'plp.productAllocation'|cxTranslate}}</b>
                <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
              </section>
            </section>
            <section class="third-col">
              <section class="row unit-calc" *ngIf="unitSection==='NoUnits'">
                <section class="col-md-4 col-xs-6 bottom-spacing price-area" *ngIf=" !productDetails?.product?.flu">
                  <b>Prix unitaire HT</b>
                  <section class="number">
                    <span
                      *ngIf="productDetails?.basePrice == undefined || productDetails?.basePrice?.value === undefined">-</span>
                    <span *ngIf="productDetails?.basePrice && productDetails?.basePrice?.value !== undefined">
                      <span>{{productDetails?.basePrice?.value | localCurrency}}</span>
                      {{'plp.cad'|cxTranslate}}
                    </span>
                  </section>
                </section>
              </section>
              <p class="message-overlay"
                 *ngIf="productDetails?.product?.messageDangerousGoods">{{'plp.messageDangerousGoods' | cxTranslate}}</p>
              <p class="message-overlay"
                 *ngIf="productDetails?.product?.messageColdChain">{{'plp.messageColdChain' | cxTranslate}}</p>
              <section class="row unit-calc" *ngIf="unitSection==='Update' || unitSection==='AddToCart'">
                <section class="col-md-4 col-xs-6 bottom-spacing price-area" *ngIf=" !productDetails?.product?.flu">
                  <b>{{'plp.yourPrice'|cxTranslate}}</b>
                  <section class="number">
                    <span
                      *ngIf="productDetails?.basePrice == undefined || productDetails?.basePrice?.value === undefined">-</span>
                    <span *ngIf="productDetails?.basePrice && productDetails?.basePrice?.value !== undefined">
                      <span>{{productDetails?.basePrice?.value | localCurrency}}</span>
                      {{'plp.cad'|cxTranslate}}
                    </span>
                  </section>
                </section>

                <!-- No price -->
                <section class="col-md-6 col-xs-6 units-area no-price"
                  *ngIf="productDetails?.basePrice == undefined || productDetails?.basePrice == null || (productDetails?.basePrice.value == 0.0 && productDetails?.product?.productType != 'BEYFORTUS')">
                  <p>{{'plp.noPrice' | cxTranslate}}</p>
                </section>

                <section class="col-md-4 col-xs-6 bottom-spacing units-area">
                  <section
                    *ngIf="productDetails?.basePrice != undefined || productDetails?.basePrice != null || productDetails?.basePrice.value != 0.0 || productDetails?.basePrice.value != 0">
                    <section *ngIf="productDetails?.product?.available">
                      <b class="prodUnits">{{'plp.units'|cxTranslate}}</b>

                      <section class="unit-area">
                        <span class="disabled " aria-hidden="true"
                          id="{{'minusIcon'+productDetails?.product?.code+'modal'}}">
                          <img src="../../assets/icons/less.svg"
                            id="{{'unitMinus'+productDetails?.product?.code+'modal'}}"
                            [ngClass]="{'disabled-img':productDetails?.quantity===0}" alt="decrease-quantity" (click)="performQuantityComputation(
                                                          productDetails,
                                                          2,
                                                          productDetails?.product?.code+'modal',
                                                          productDetails?.product?.stepQuantity,
                                                          productDetails?.product?.minQuantity,
                                                          productDetails?.product?.maxQuantity
                                                        )">
                          <img src="../../assets/icons/less-disable.svg"
                            id="{{'unitMinusDisable'+productDetails?.product?.code+'modal'}}"
                            alt="decrease-quantity-disabled" [ngClass]="{'disabled-img':productDetails?.quantity>0}">
                        </span>
                        <input type="number" class="prod-quantity text-center" value={{productDetails?.quantity}}
                          onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" (keyup)="performQuantityComputation(
                                                          productDetails,
                                                          null,
                                                          productDetails?.product?.code+'modal',
                                                          productDetails?.product?.stepQuantity,
                                                          productDetails?.product?.minQuantity,
                                                          productDetails?.product?.maxQuantity
                                                    )" id="{{'prodQuantity'+productDetails?.product?.code+'modal'}}"
                          min="1" max="9999" />
                        <span class="" aria-hidden="true" id="{{'plusIcon'+productDetails?.product?.code+'modal'}}">
                          <img src="../../assets/icons/more.svg"
                            id="{{'unitPlus'+productDetails?.product?.code+'modal'}}" alt="increase-quantity" (click)="performQuantityComputation(
                                                          productDetails,
                                                          1,
                                                          productDetails?.product?.code+'modal',
                                                          productDetails?.product?.stepQuantity,
                                                          productDetails?.product?.minQuantity,
                                                          productDetails?.product?.maxQuantity
                                                        )">
                          <img src="../../assets/icons/more-disable.svg"
                            id="{{'unitPlusDisable'+productDetails?.product?.code+'modal'}}" class="disabled-img"
                            alt="increase-quantity-disabled">
                        </span>
                      </section>
                    </section>
                  </section>
                </section>

                <section class="col-md-4 col-sm-4 col-xs-12 bottom-spacing button-area"
                  *ngIf="productDetails?.basePrice != undefined || productDetails?.basePrice != null || productDetails?.basePrice.value != 0.0 || productDetails?.basePrice.value != 0">
                  <button *ngIf="unitSection==='Update';else addToCart" class="blue-button login-btn ml10"
                    data-di-id="di-id-2fea5a72-b2e23002" [ngbPopover]=popTitleSuccess placement="bottom"
                    triggers="manual" #p2="ngbPopover" placement="bottom" popoverClass="updatecart-msg"
                    (click)="updateCart(productDetails?.product?.code+'modal',p2)">
                    {{'addToCartItems.updateCart'|cxTranslate}}</button>

                  <section class="added-success-container">
                    <ng-template #popTitleSuccess>
                      <div class="cart-value-added">
                        <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
                          alt="success">
                        <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                          {{'addToCartItems.addUpdateSuccess'|cxTranslate}}</span>
                      </div>
                    </ng-template>
                    <ng-template #popTitleAddSuccess>
                      <div class="cart-value-added">
                        <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
                          alt="success">
                        <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                          {{'addToCartItems.addEntrySuccess'|cxTranslate}}</span>
                      </div>
                    </ng-template>
                  </section>


                  <ng-template #addToCart>
                    <button class="blue-button login-btn ml10" data-di-id="di-id-2fea5a72-b2e23002"
                      [ngbPopover]=popTitleAddSuccess placement="bottom" triggers="manual" #p3="ngbPopover"
                      placement="bottom" popoverClass="addtocart-msg"
                      (click)="addToSessionCart(productDetails?.product?.code+'modal',productDetails?.product?.code, p3)">
                      {{'addToCartItems.addCart'|cxTranslate}}</button>
                  </ng-template>
                </section>
                <section class="bottom-spacing availability-text"
                  *ngIf="!productDetails?.product?.discontinued && !productDetails?.product?.sapAllocation && !productDetails?.product?.flu">
                </section>
              </section>
              <hr class="line">
              <!-- Packing List Section -->
              <section>
                <p class="desc-title">
                  {{'pdp.packagingDimensions'|cxTranslate}}:
                  <span class="desc-details">{{productDetails?.product?.packagingDimensions}}</span>
                </p>
              </section>
              <!-- The End -->
              <section *ngIf="!productDetails?.product?.discontinued && productDetails?.product?.available"
                class="products-accordion clear-fix pt2">
                <section class="panel-group">
                  <section class="panel panel-default" *ngIf="volumePrices?.length > 0">
                    <section class="panel-heading panel-endborder">
                      <section class="first-level-acc">
                        <label><img src="../../assets/icons/price-master.png" class="accordion-img" alt="">
                          {{'plp.volumePricing'|cxTranslate}}</label>
                        <span data-toggle="collapse" data-parent="#accordion" href="#pdp-list1" class="acc-plus-icon fa"
                          aria-expanded="panelVolumeExpanded" (click)="panelVolumeExpanded = !panelVolumeExpanded"
                          [ngClass]="panelVolumeExpanded?'fa-minus':'fa-plus collapsed'"></span>
                      </section>
                    </section>
                    <section id="pdp-list1" class="panel-collapse collapse" aria-expanded="panelVolumeExpanded"
                      (click)="panelVolumeExpanded = !panelVolumeExpanded" [ngClass]="panelVolumeExpanded?'in':''">
                      <section class="panel-body">
                        <section class="discount-list" id="vol-price-pdp">
                          <table aria-describedby="pdpTable">
                            <thead>
                              <th id=""></th>
                            </thead>
                            <tbody *ngFor="let prices of volumePrices; let i=index">
                              <tr>
                                <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                                  {{'plp.units'|cxTranslate}}:
                                </td>
                                <td>{{prices?.value | localCurrency}}</td>
                                <td><span *ngIf="i>0" class="save-price">{{'plp.save'|cxTranslate}}
                                    {{volumePrices[0].value
                                    -
                                    volumePrices[i].value |
                                    currency}}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section>
              <section class=" products-accordion clear-fix pt2 ">
                <section class="panel-group ">
                  <!-- update2 -->
                  <section class="panel panel-default " *ngIf="productDetails?.product?.productUpdates ">
                    <section class="panel-heading ">
                      <section class="first-level-acc ">
                        <label><img src="../../assets/icons/info.png " class="accordion-img "
                            alt=" ">{{'plp.productUpdate'|cxTranslate}}
                        </label> <span data-toggle="collapse " data-parent="#accordion " href="#pdp-list3 "
                          class="acc-plus-icon fa " aria-expanded="panelProductExpanded "
                          (click)="panelProductExpanded=! panelProductExpanded "
                          [ngClass]="panelProductExpanded? 'fa-minus': 'fa-plus collapsed' "></span>
                      </section>
                    </section>
                    <section id="pdp-list3 " class="panel-collapse collapse " aria-expanded="panelProductExpanded "
                      (click)="panelProductExpanded=! panelProductExpanded "
                      [ngClass]="panelProductExpanded? 'in': '' ">
                      <section class="panel-body productupdate-p ">
                        {{productDetails?.product?.productUpdates}}</section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
        <aside aria-label="Product Details Pdf " class="col-sm-3 right-navigation float-left resources-section "
          *ngIf="monographLink ">
          <section>
            <section class=" prod-detail-pdfs ">
              <p class=" pdfs-title ">
                {{'plp.resources'|cxTranslate}}</p>
              <p class=" pdfs-list ">

                <img class=" pdf-icon " src=" ../../assets/icons/pdf.png " alt=" ">
                <a alt=" " [href]="monographLink" target=" _blank "
                  class=" file-link blue ">{{'plp.monograph'|cxTranslate}}</a>
              </p>
            </section>
          </section>
        </aside>
      </section>

    </section>
  </div>
</div>
