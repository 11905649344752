import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { NumberTwo } from '../../../shared/utils/constants/global.constants';
import { CustomRegistrationService } from '../../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng,
} from '../../registration.constants';

const specialCharsRegex = '[^!$%*()=]+$';
const sameAsBillingAddress = 'shippingAddress.sameAsBillingAddress';
const yellowFeverForShippingAddr = 'shippingAddress.yellowFever';

@Component({
  selector: 'app-shipping-information',
  templateUrl: './shipping-information.component.html',
  styleUrls: ['./shipping-information.component.scss'],
})
export class ShippingInformationComponent implements OnInit, OnDestroy {
  @Input() formDetails: FormGroup;
  @Input() multiFormData: FormGroup[];

  shippingForm: FormGroup;
  billingFormDetails: any;
  multipleForms: FormGroup[] = [];
  provinces: any[] = [];
  practiceOrFacility: any[] = [];
  yellowFever: boolean[] = [true, false];
  isShippingAddressSame: boolean[] = [true, false];
  errorMessageText: string = globalErrorMsgEng;
  provincSub: Subscription;
  practiceOrFaciltySub: Subscription;
  emailRegex =
    /^([A-Za-z\d\._-]+)@([A-Za-z\d\._-]+)\.([A-Za-z\.]{2,8})(\.[A-Za-z\.]{2,8})?$/g;

  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly user: UserAccountFacade,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly globalMessage: GlobalMessageService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.accountRegService.getPracticeOrFacilityType({
      enumerationType: 'PracticeOrFacilityType',
      fields: 'DEFAULT',
    });
    this.accountRegService.getProvinces({
      fields: 'DEFAULT',
    });
    const maxlengthChar = 256;
    const charlengthSix = 6;
    const maxlengthTwelve = 12;
    const maxlengthSeven = 7;
    this.shippingForm = new FormGroup({
      shippingAddress: new FormGroup({
        facilityName: new FormControl(null, [
          Validators.required,
          Validators.pattern(specialCharsRegex),
          Validators.maxLength(maxlengthChar),
        ]),
        legalName: new FormControl(null, [
          Validators.required,
          Validators.pattern(specialCharsRegex),
          Validators.maxLength(maxlengthChar),
        ]),
        line2: new FormControl(null, [
          Validators.pattern('[a-zA-Z0-9 ]+$'),
          Validators.maxLength(maxlengthChar),
        ]),
        line1: new FormControl(null, [
          Validators.required,
          Validators.pattern(specialCharsRegex),
          Validators.maxLength(maxlengthChar),
        ]),
        town: new FormControl(null, [
          Validators.required,
          Validators.pattern('[a-zA-Z ]+$'),
          Validators.maxLength(maxlengthChar),
        ]),
        region: new FormGroup({
          isocode: new FormControl(null, Validators.required),
          countryIso: new FormControl('CA'),
        }),
        postalCode: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] [0-9][A-Z][0-9]$'
          ),
          Validators.maxLength(maxlengthSeven),
        ]),
        phone: new FormControl(null, [
          Validators.required,
          Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
          Validators.maxLength(maxlengthTwelve),
        ]),
        extension: new FormControl(null, [
          Validators.pattern('[0-9]+$'),
          Validators.maxLength(charlengthSix),
        ]),
        fax: new FormControl(null, [
          Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
          Validators.maxLength(maxlengthTwelve),
        ]),
        confirmEmail: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$'
          ),
          Validators.minLength(charlengthSix),
          Validators.maxLength(maxlengthChar),
        ]),
        email: new FormControl(null, [
          Validators.required,
          Validators.pattern(
            '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$'
          ),
          Validators.minLength(charlengthSix),
          Validators.maxLength(maxlengthChar),
        ]),
        practiceOrFacilityType: new FormControl(null, Validators.required),
        yellowFever: new FormControl(this.yellowFever[1], Validators.required),
        billingAddress: new FormControl(
          this.isShippingAddressSame[1],
          Validators.required
        ),
        shippingAddress: new FormControl(
          this.isShippingAddressSame[0],
          Validators.required
        ),
        sameAsBillingAddress: new FormControl(
          this.isShippingAddressSame[1],
          Validators.required
        ),
      }),
    });

    this.shippingForm
      .get(sameAsBillingAddress)
      .valueChanges.subscribe((value) => {
        if (value) {
          const formValues = this.formDetails.value;
          this.shippingForm.patchValue(
            {
              shippingAddress: {
                facilityName: formValues.billingAddress.facilityName,
                legalName: formValues.billingAddress.legalName,
                line1: formValues.billingAddress.line1,
                line2: formValues.billingAddress.line2,
                town: formValues.billingAddress.town,
                region: {
                  isocode: formValues.billingAddress.region.isocode,
                  countryIso: formValues.billingAddress.region.countryIso,
                },
                postalCode: formValues.billingAddress.postalCode,
                phone: formValues.billingAddress.phone,
                extension: formValues.billingAddress.extension,
                fax: formValues.billingAddress.fax,
                email: formValues.billingAddress.email,
                confirmEmail: formValues.billingAddress.confirmEmail,
                practiceOrFacilityType:
                  formValues.billingAddress.practiceOrFacilityType,
                yellowFever: formValues.billingAddress.yellowFever,
              },
            },
            { emitEvent: false }
          );
          this.shippingForm
            .get(yellowFeverForShippingAddr)
            .disable({ emitEvent: false });
        } else {
          this.shippingForm.patchValue(
            {
              shippingAddress: {
                facilityName: '',
                legalName: '',
                line1: '',
                line2: '',
                town: '',
                region: {
                  isocode: null,
                  countryIso: 'CA',
                },
                postalCode: null,
                phone: '',
                extension: '',
                fax: '',
                email: '',
                confirmEmail: '',
                practiceOrFacilityType: null,
                yellowFever: this.yellowFever[1],
                billingAddress: false,
                shippingAddress: true,
              },
            },
            { emitEvent: false }
          );
          this.shippingForm
            .get(yellowFeverForShippingAddr)
            .enable({ emitEvent: false });
        }
      });

    this.populateDataFromMultiForm();

    this.provincSub = this.accountRegService.provinceData$.subscribe((res) => {
      if (res !== false) {
        this.provinces = res?.regions;
      }
    });

    this.practiceOrFaciltySub =
      this.accountRegService.practiceOrFacilityTypeData$.subscribe(
        (response) => {
          if (response !== false) {
            this.practiceOrFacility = response;
          }
        }
      );
  }

  populateDataFromMultiForm() {
    if (!!this.multiFormData) {
      const len = this.multiFormData.length;
      if (len === 1) {
        this.formDetails = this.multiFormData[len - 1];
        const formValues = this.multiFormData[len - 1].value;
        this.formFillup(formValues.billingAddress);
        if (!!this.shippingForm.get(sameAsBillingAddress).value) {
          this.shippingForm
            .get(yellowFeverForShippingAddr)
            .disable({ emitEvent: false });
        }
      } else if (len === NumberTwo) {
        this.formDetails = this.multiFormData[len - NumberTwo];
        const formValues = this.multiFormData[len - 1].value;
        this.formFillup(formValues.shippingAddress);
        if (!!this.shippingForm.get(sameAsBillingAddress).value) {
          this.shippingForm
            .get(yellowFeverForShippingAddr)
            .disable({ emitEvent: false });
        }
      } else {
        // no change
      }
    }
  }

  formFillup(form: any) {
    this.shippingForm.setValue(
      {
        shippingAddress: {
          facilityName: form.facilityName,
          legalName: form.legalName,
          line1: form.line1,
          line2: form.line2,
          town: form.town,
          region: {
            isocode: form.region.isocode,
            countryIso: form.region.countryIso,
          },
          postalCode: form.postalCode,
          phone: form.phone,
          extension: form.extension,
          fax: form.fax,
          email: form.email,
          confirmEmail: form.confirmEmail,
          practiceOrFacilityType: form.practiceOrFacilityType,
          yellowFever: form.yellowFever,
          billingAddress: form.billingAddress,
          shippingAddress: true,
          sameAsBillingAddress: form.shippingAddress,
        },
      },
      { emitEvent: false }
    );
  }

  proceedToContacts() {
    this.shippingForm.markAllAsTouched();
    const cummulativeFormData = { addresses: [], contacts: [] };
    if (
      this.shippingForm.valid &&
      this.shippingForm.get('shippingAddress.email').value ===
        this.shippingForm.get('shippingAddress.confirmEmail').value
    ) {
      if (this.shippingForm.get(sameAsBillingAddress).value === true) {
        this.formDetails.patchValue(
          {
            billingAddress: {
              shippingAddress:
                this.shippingForm.get(sameAsBillingAddress).value,
            },
          },
          { emitEvent: false }
        );
        cummulativeFormData.addresses.push(
          this.formDetails.get('billingAddress').value
        );
        this.multipleForms.push(this.formDetails);
      } else {
        cummulativeFormData.addresses.push(
          this.formDetails.get('billingAddress').value
        );
        cummulativeFormData.addresses.push(
          this.shippingForm.get('shippingAddress').value
        );
        this.multipleForms.push(this.formDetails);
        this.multipleForms.push(this.shippingForm);
      }
      const shippingDetails = {
        header: 'contacts',
        forms: this.multipleForms,
        totalFormData: cummulativeFormData,
      };
      this.accountRegService.sectionChange.next(shippingDetails);
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  back() {
    if (!!this.multiFormData === false) {
      this.multiFormData = [];
      this.multiFormData.push(this.formDetails);
    }
    const shippingDetails = {
      header: 'billingDetails',
      form: this.formDetails,
      forms: this.multiFormData,
    };
    this.accountRegService.sectionChange.next(shippingDetails);
  }

  ngOnDestroy() {
    this.provincSub?.unsubscribe();
    this.practiceOrFaciltySub?.unsubscribe();
  }
}
