import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-indirect-total-prices',
  templateUrl: './indirect-total-prices.component.html',
  styleUrls: ['./indirect-total-prices.component.scss'],
})
export class IndirectTotalPricesComponent implements OnInit {
  @Input() abstractOrder: any;
  constructor() {}

  ngOnInit(): void {
  }

}
