import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../../shared/services/data-layer.service';
import {
  Sixty,
  Thousand,
  TwentyFour,
} from '../../shared/utils/constants/global.constants';
import { OpenStatementsService } from '../open-statements.service';
import { StatementDisplayComponent } from '../statement-display/statement-display.component';
import * as config from "../../shared/config/app.config";
import {UserAccountFacade} from "@spartacus/user/account/root";
import {BaseService} from "../../shared/services/base.service";

const statementCssClass = 'col-md-10 col-sm-9';
const accountOverviewCssClass = 'col-md-12 col-sm-12';
const dateFormat = 'yyyy-MM-dd';

@Component({
  selector: 'app-open-statements',
  templateUrl: './open-statements.component.html',
  styleUrls: ['./open-statements.component.scss'],
})
export class OpenStatementsComponent implements OnInit, OnDestroy {
  @Input() hideAside: boolean;
  showSection = false;
  statementWidth: string = statementCssClass;
  statementsData: any;
  showPortal = false;
  details: any;
  showModal = false;
  accountId = null;
  userAcccountSub: Subscription;
  statementSub: Subscription;
  reloadSub: Subscription;

  constructor(
    private readonly stService: OpenStatementsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private readonly modalService: NgbModal,
    private readonly dataLayerService: DataLayerService,
    private readonly user: UserAccountFacade,
    private readonly baseService: BaseService
  ) {}
  public windowReference = null;

  ngOnInit(): void {
    if (!!this.hideAside) {
      this.statementWidth = accountOverviewCssClass;
      this.showSection = this.hideAside;
    } else {
      this.statementWidth = statementCssClass;
      this.showSection = false;
    }
    this.stService.getAccountId();
    this.userAcccountSub = this.stService.userAccountIdDetails.subscribe(
      (res) => {
        this.accountId = res.defaultB2BUnit.uid;
      }
    );
    const params = {
      accountId: this.accountId,
      fields: 'DEFAULT',
    }
    this.user.get().subscribe((customer: any) => {
      if (customer) {
        this.statementSub = this.baseService.get(`${config.GET_OPEN_STATEMENTS}?fields=DEFAULT&emulatedUser=${customer.userName}`, params).subscribe((res) => {
                if (res) {
                  this.statementsData = JSON.parse(JSON.stringify(res));
                  this.cdr.detectChanges();
                }
              });
      }
    });



    this.reloadSub = this.stService.reloadComponent.subscribe((res) => {
      this.showPortal = !res;
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  openNewModalPopup(lastDayOfMonths, startDate, systemId: string) {
    this.details = {
      fromDte: startDate,
      toDte: lastDayOfMonths,
      systemUid: systemId,
    };
    const modalRef = this.modalService.open(StatementDisplayComponent, {
      size: 'lg',
      scrollable: false,
    });
    modalRef.componentInstance.toShow = true;
    modalRef.componentInstance.statement = this.details;
  }

  generateSavePdf(lastDayOfMonths, startDate, system) {
    this.showPortal = true;
    this.details = {
      fromDte: startDate,
      toDte: lastDayOfMonths,
      systemUid: system,
    };
    this.stService.loadStatements.next(this.details);
    const pdfDataLayer = {
      localizedDownloadedMedia: {
        name: 'statement',
      },
      downloadedMediaType: 'pdf',
    };
    this.dataLayerService.downloadDataLayer(pdfDataLayer);
  }

  viewCurrent(system: string) {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setDate(0);
    const lastDayOfMonths = this.datePipe.transform(currentDate, dateFormat);

    const fromDateTimeStamp = new Date(
      new Date(this.statementsData?.dateMap[0].value).getTime() +
        TwentyFour * Sixty * Sixty * Thousand
    );
    const fromDate = this.datePipe.transform(fromDateTimeStamp, dateFormat);
    this.openNewModalPopup(lastDayOfMonths, fromDate, system);
  }

  viewPDF(system: string) {
    if (!!this.statementsData) {
      const currentDate = new Date();
      currentDate.setDate(1);
      currentDate.setDate(0);
      const lastDayOfMonths = this.datePipe.transform(currentDate, dateFormat);
      const fromDateTimeStamp = new Date(
        new Date(this.statementsData?.dateMap[0].value).getTime() +
          TwentyFour * Sixty * Sixty * Thousand
      );
      const fromDate = this.datePipe.transform(fromDateTimeStamp, dateFormat);
      this.generateSavePdf(lastDayOfMonths, fromDate, system);
    }
  }

  ngOnDestroy() {
    this.userAcccountSub?.unsubscribe();
    this.statementSub?.unsubscribe();
    this.reloadSub?.unsubscribe();
  }
}
