import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  public catalogs = new BehaviorSubject<any>(null);
  catalogs$ = this.catalogs.asObservable();
  public catalogTypes = new BehaviorSubject<any>(null);
  catalogTypes$ = this.catalogTypes.asObservable();

  constructor(
    public baseService: BaseService,
    readonly user: UserAccountFacade
  ) {}

  public getCatalogs() {
    const filter = {
      fields: 'DEFAULT',
    };
    const query = 'frProductCatalog/Online/categories/m1';
    this.baseService.get(config.CATALOGS + query, filter).subscribe((res) => {
      this.catalogTypes.next(res);
    });
  }

  public getCatalogTypes(type?) {
    this.user.get().subscribe((customer: any) => {
      if (customer) {
        this.baseService
          .get(`${config.CATALOGSTYPE}${type}?fields=DEFAULT&emulatedUser=${customer.userName}`)
          .subscribe((res) => {
            this.catalogs.next(res);
          });
      }
    });
  }
}
