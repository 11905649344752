import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileAdBannerComponent } from './profile-ad-banner/profile-ad-banner.component';



@NgModule({
  declarations: [ProfileAdBannerComponent],
  exports:[ProfileAdBannerComponent],
  imports: [
    CommonModule
  ]
})
export class ProfileAdBannerModule { }
