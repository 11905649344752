import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CmsService, Occ} from '@spartacus/core';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {filter, switchMap} from 'rxjs/operators';
import {SelectWhsPopupService} from 'src/app/shared/modules/switch-whs-popup/services/select-whs-popup.service';
import {Subscription} from 'rxjs';
import B2BUnit = Occ.B2BUnit;

@Component({
  selector: 'app-custom-indirect-banner',
  templateUrl: './custom-indirect-banner.component.html',
})
export class CustomIndirectBannerComponent implements OnInit, OnDestroy {

  b2bUnits: B2BUnit[];
  isShowBannerSub: Subscription;

  constructor(private readonly cmsData: CmsService,
              private readonly cdr: ChangeDetectorRef,
              private readonly user: UserAccountFacade,
              private readonly selectWhsPopupService: SelectWhsPopupService) {
  }


  ngOnInit(): void {
    this.isShowBannerSub = this.user.get().pipe(
      filter(customer => !!customer),
      switchMap(customer => this.selectWhsPopupService.getUserAccountDataFromDb(customer?.uid)),
      switchMap((b2bUnit: any) => this.selectWhsPopupService.getRegisteredWhsForB2BUnit(b2bUnit?.defaultB2BUnit?.uid))
    ).subscribe(registeredWhs => {
      this.b2bUnits = registeredWhs;
      this.cdr.detectChanges();
    });
  }

  isShowBanner(): boolean {
    return this.b2bUnits && window.location.href.indexOf('productType:INDIRECT') !== -1;
  }

  ngOnDestroy(): void {
    this.isShowBannerSub.unsubscribe();
  }
}
