
  <section
    class="
      col-xs-12 col-sm-8 col-md-8
      left-section
      Registration-nav-section
      section-height
    "
  >
    <div
      class="col-xs-12 col-sm-12 col-md-12 Registration-split recovery-split"
    >
      <h3 class="login-help-title">
        {{ "forgotUserPassword.YourPasswordHasBeenChanged" | cxTranslate }}
      </h3>
      <form [formGroup]="loginForm">
        <div class="col-xs-12 col-sm-8 col-md-8 p0">
          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label for="Password" class="Fields-label">{{
              "forgotUserPassword.username" | cxTranslate
            }}</label>
            <input
              id="userId"
              name="userId"
              type="text"
              class="Fields-input"
              formControlName="userId"
              autocomplete="off"
              (ngModelChange)="validateUsername()"
            />
            <span
              role="alert"
              aria-live="assertive"
              *ngIf="
                (loginForm.get('userId').hasError('required') &&
                  loginForm.get('userId').touched) ||
                userIdRequired
              "
              class="error-message"
              >{{ "forgotUserPassword.requiredField" | cxTranslate }}</span
            >
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label for="confirmPassword" class="Fields-label">{{
              "forgotUserPassword.password" | cxTranslate
            }}</label>
            <input
              name="password"
              type="password"
              class="Fields-input"
              formControlName="password"
              autocomplete="off"
              (ngModelChange)="validatePassword()"
            />
            <span
              role="alert"
              aria-live="assertive"
              *ngIf="
                (loginForm.get('password').hasError('required') &&
                  loginForm.get('password').touched) ||
                passwordRequired
              "
              class="error-message"
              >{{ "forgotUserPassword.requiredField" | cxTranslate }}</span
            >
          </div>

          <div class="Fields-btn-group">
            <button
              type="submit"
              class="btn btn-default blue-button"
              (click)="onSubmit()"
            >
              {{ "forgotUserPassword.Login" | cxTranslate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
