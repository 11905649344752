import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AuthConfigService,
  OAuthLibWrapperService,
  WindowRef,
} from '@spartacus/core';
import { VscaOAuthService } from './vsca-oauth-service';

/**
 * Wrapper service on the library OAuthService. Normalizes the lib API for services.
 * Use this service when you want to access low level OAuth library methods.
 */
@Injectable({
  providedIn: 'root',
})
export class VscaOAuthLibWrapperService extends OAuthLibWrapperService {
  constructor(
    protected oAuthService: VscaOAuthService,
    protected authConfigService: AuthConfigService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    protected winRef: WindowRef
  ) {
    super(oAuthService, authConfigService, platformId, winRef);
  }
  vscaAuthorizeWithPasswordFlowForbaseSiteId(
    userId: string,
    password: string,
    baseSiteId: string
  ) {
    return this.oAuthService.fetchTokenUsingPasswordFlowAndBaseSiteId(
      userId,
      password,
      baseSiteId
    );
  }
}
