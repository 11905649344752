<section class="container alert-Influenza-Banner" *ngIf="component.data$ | async as data">
  <section class="row">
    <section class="col-md-11 col-lg-11 col-xl-11">
      <p class="infoBanner-Influenza">
        <span class="influenza-Msg-Banner" [innerHTML]="data.content"></span>
      </p>
    </section>
    <section class="col-md-1 col-lg-1 col-xl-1 alert-close">
      <p class="close-banner"></p>
    </section>
  </section>
</section>
