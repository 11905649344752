<!-- https://github.com/angular/angular/issues/24567 -->

<ng-container *ngIf="isExternalUrl(); else isLocalUrl">
  <a
    (click)="openLinkPopup(url)"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #isLocalUrl>
  <!-- SAP_LJaeger: changed [attr.target]="target" to [target]="target" to open links in new tab -->
  <a
    [routerLink]="routerUrl"
    [queryParams]="queryParams"
    [fragment]="fragment"
    [target]="target"
    [attr.id]="id"
    [attr.class]="class"
    [attr.style]="style"
    [attr.title]="title"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
