import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NeedAssistanceComponent } from './need-assistance/need-assistance.component';



@NgModule({
  declarations: [NeedAssistanceComponent],
  imports: [
    CommonModule
  ],
  exports: [NeedAssistanceComponent]
})
export class NeedAssistanceModule { }
