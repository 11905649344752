import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomRegistrationService } from '../custom-registration.service';

@Component({
  selector: 'app-account-registration',
  templateUrl: './account-registration.component.html',
  styleUrls: ['./account-registration.component.scss'],
})
export class AccountRegistrationComponent implements OnInit {
  header: string;
  formData: any;
  twoFormsData: any[];
  creditInvoice: string;
  compName = 'AccountRegistrationConfirmationBannerComponent';

  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly user: UserAccountFacade,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.header = 'billingDetails';
    this.creditInvoice = 'invoiceTable';

    this.accountRegService.sectionChange.subscribe((sectionDetails) => {
      this.header = sectionDetails.header;
      if (!!sectionDetails?.form) {
        this.formData = sectionDetails.form;
      }
      if (!!sectionDetails?.forms) {
        this.twoFormsData = sectionDetails.forms;
      }
      if (sectionDetails.header === 'contacts') {
        this.formData = sectionDetails?.totalFormData;
      }
      this.cdr.detectChanges();
    });
  }
}
