import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { translationChunksConfig } from '@spartacus/assets';
import {
  AuthService,
  CmsConfig,
  ConfigModule,
  I18nModule,
  OccProductSearchAdapter,
  ProductSearchAdapter,
  SearchConfig,
  provideConfig,
} from '@spartacus/core';
import {
  ActiveFacetsModule,
  FacetModule,
  ListNavigationModule,
  MediaModule, PageSlotModule,
  PaginationModule,
  ProductImagesModule
} from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { MainPipeModule } from '../shared/pipes/mainpipe.module';
import { CustomIndirectBannerComponent } from './custom-indirect-banner/custom-indirect-banner.component';
import { CustomProductCardComponent } from './custom-product-card/custom-product-card.component';
import { CustomProductPageComponent } from './custom-product-page/custom-product-page.component';
import { CustomProductRefinementComponent } from './custom-product-refinement/custom-product-refinement.component';
import { TabComponent } from './tabs/tab.component';
import { TabsComponent } from './tabs/tabs.component';

const PRODUCT_TYPE = 'DEFAULT';
let userId = '';
let users = '';
let userPartValue = '';
@Injectable()
export class CustomOccProductSearchAdapter extends OccProductSearchAdapter {
  protected getSearchEndpoint(
    query: string,
    searchConfig: SearchConfig
  ): string {
    const { userPart, prodType, ...restSearchConfig } =
      searchConfig as any;

    return decodeURIComponent(this.occEndpoints.buildUrl('productSearch', {
      urlParams: {
        userPart: userPartValue,
        prodType: PRODUCT_TYPE,
      },
      queryParams: {
        query,
        ...restSearchConfig,
      },
    }));
  }
}

@NgModule({
  declarations: [
    CustomProductPageComponent,
    CustomProductRefinementComponent,
    CustomProductCardComponent,
    TabsComponent,
    TabComponent,
    CustomIndirectBannerComponent,
  ],
  exports: [CustomProductCardComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ListNavigationModule,
    FacetModule,
    PaginationModule,
    ProductImagesModule,
    ActiveFacetsModule,
    MediaModule,
    RouterModule,
    I18nModule,
    NgSelectModule,
    ConfigModule.withConfig({
      pagination: {
        addStart: false,
        addEnd: false,
        addPrevious: true,
        addNext: true,
        nextLabel: '>',
        previousLabel: '<',
        addFirst: false,
        addLast: false,
        addDots: false,
        rangeCount: false,
      },
      cmsComponents: {
        ProductGridComponent: {
          component: CustomProductPageComponent,
        },
        SearchResultsGridComponent: {
          component: CustomProductPageComponent,
        },
        ProductRefinementComponent: {
          component: CustomProductRefinementComponent,
        },
        AccountOverviewIndirectParagraphComponent: {
          component: CustomIndirectBannerComponent
        }
      },
      view: {
        defaultPageSize: 18,
      },
    } as CmsConfig),
    MainPipeModule,
    PageSlotModule,
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
    provideConfig({
      backend: {
        occ: {
          endpoints: {
            productSearch:
              '${userPart}/products/search?fields=products(${prodType},images(DEFAULT)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
          },
        },
      },
    }),
    {
      provide: ProductSearchAdapter,
      useClass: CustomOccProductSearchAdapter,
    },
  ],
})
export class CustomProdPgModule {
  emulateSub: Subscription;
  userSub: Subscription;
  isLoggedIn: Observable<boolean> = this.authService.isUserLoggedIn();
  isEmulated: Observable<boolean> = this.csAgentAuthService
    .isCustomerEmulated()
  constructor(
    user: UserAccountFacade,
    protected csAgentAuthService: CsAgentAuthService,
    protected authService: AuthService
  ) {
    this.emulateSub = this.isEmulated.subscribe((res) => {
        if (res) {
          this.userSub = user.get().subscribe((resp) => {
            if (resp) {
              userId = resp.uid;
              users = 'users/';
              userPartValue = users + userId;
            }
          });
        } else {
          userPartValue = '';
        }
      });
  }
}
