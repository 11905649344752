<section class="row makePaymentView" id="productList-area">
  <aside class="col-md-2 col-sm-3 float-left" *ngIf="!showSection">
    <section class="vertical-banner"></section>
  </aside>
  <section class="{{ statementWidth }} col-xs-12 table-s-section float-right"
    [ngClass]="{ 'acc-overview-sp': showSection === true }">
    <section class="credits-section" [ngClass]="{ 'acc-overview-sp': showSection === true }">
      <section class="row">
        <section class="col-sm-6 col-md-6 col-xs-12 op_ovice" [ngClass]="{ 'acc-overview-sp': showSection === true }">
          <h3 class="oi-table-title" [ngClass]="{ 'acc-overview-sp': showSection === true }">
            {{ "statements.statementList.title" | cxTranslate }}
          </h3>
        </section>
        <section class="col-12 col-md-12 col-sm-12 col-xs-12 text-right openstatement-btn">
          <button type="button" (click)="viewCurrent('is2k')" class="btn btn-primary btnpaymentinvoicecredit"
            [ngClass]="{ 'acc-overview-sp': showSection === true }">
            {{ "statements.statementList.view_text_influenza" | cxTranslate }}
          </button>
          <button type="button" (click)="viewPDF('is2k')" class="btn btn-primary btnpaymentinvoicecredit pdf btnIphone"
            [ngClass]="{ 'acc-overview-sp': showSection === true }">
            <img src="../../assets/icons/pdf_white.png" alt="pdf" class="pdf" />
            {{ "statements.statementList.pdf_text_influenza" | cxTranslate }}
          </button>
        </section>
        <section class="col-12 col-md-12 col-sm-12 col-xs-12 text-right openstatement-btn button-genmed">
          <button type="button" (click)="viewCurrent('cep')" class="btn btn-primary btnpaymentinvoicecredit"
                  [ngClass]="{ 'acc-overview-sp': showSection === true }">
            {{ "statements.statementList.view_text_gm" | cxTranslate }}
          </button>
          <button type="button" (click)="viewPDF('cep')" class="btn btn-primary btnpaymentinvoicecredit pdf btnIphone"
                  [ngClass]="{ 'acc-overview-sp': showSection === true }">
            <img src="../../assets/icons/pdf_white.png" alt="pdf" class="pdf" />
            {{ "statements.statementList.pdf_text_gm" | cxTranslate }}
          </button>
        </section>
      </section>
    </section>

    <section class="payment-section" [ngClass]="{ 'acc-overview-sp': showSection === true }">
      <h3 class="oi-table-title" [ngClass]="{ 'acc-overview-sp': showSection === true }">
        {{ "statements.statementList.title_statements" | cxTranslate }}
      </h3>
      <table aria-describedby="creditsTable" id="applyCreditsTable" class="credits-table table table-striped">
        <thead>
          <tr>
            <th scope="col" class="statement" [ngClass]="{ 'acc-overview-sp': showSection === true }">
              {{
              "statements.statementList.statement_table_label" | cxTranslate
              }}&nbsp;
            </th>
            <th scope="col" class="statement"
                [ngClass]="{ 'acc-overview-sp': showSection === true }">{{"statements.statementList.statement_table_label_vaccines" | cxTranslate}}</th>
            <th scope="col"
                [ngClass]="{ 'acc-overview-sp': showSection === true }">{{"statements.statementList.statement_table_label_gm" | cxTranslate}}</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of statementsData?.dateMap; let i = index">
          <td>
            <span>{{ data?.value | date: "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <table class="not-table-striped">
              <tr><a title="view statement link" (click)="openNewModalPopup(data.value, data.key, 'is2k')" class="view-link">{{
                "statements.statementList.statement_table_voir" | cxTranslate }}</a></tr>
              <tr><a title="download PDF link" (click)="generateSavePdf(data.value, data.key,'is2k')"><img
                src="../../assets/icons/pdf.png" alt="pdf" class="pdf-design"/>{{ "statements.statementList.statement_table_pdf"
                | cxTranslate }}</a></tr>
            </table>
          </td>
          <td>
            <table class="not-table-striped">
              <tr>
                <a title="view statement link" (click)="openNewModalPopup(data.value,data.key, 'cep')" class="view-link">{{
                  "statements.statementList.statement_table_voir" | cxTranslate }}</a></tr>
              <tr>
                <a title="download PDF link" (click)="generateSavePdf(data.value, data.key,'cep')"><img
                  src="../../assets/icons/pdf.png" alt="pdf" class="pdf-design"/>{{ "statements.statementList.statement_table_pdf"
                  | cxTranslate }}</a></tr>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      <table aria-describedby="statementsTable" id="applyStatementsTable" class="credits-table table table-striped">
        <tr class="sample-header">
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        <tr *ngFor="let data of statementsData?.dateMap; let i = index">
          <td class="two-column-lay header">
            {{ "statements.statementList.statement_table_label" | cxTranslate }}
          </td>
          <td class="two-column-lay">
            <span>{{ data?.value | date: "dd/MM/yyyy" }}</span>
          </td>
          <td class="two-column-lay header"></td>
          <td class="two-column-lay">
            <a title="view statement link" (click)="openNewModalPopup(data.value,data.key,'is2k')" class="view-link">{{
              "statements.statementList.view_text_gm" | cxTranslate }}</a>
          </td>
          <td class="two-column-lay header"></td>
          <td class="two-column-lay">
            <a title="download PDF link" (click)="generateSavePdf(data.value, data.key,'is2k')"><img
                src="../../assets/icons/pdf.png" alt="pdf" class="pdf-design" />{{ "statements.statementList.pdf_text_gm" |
              cxTranslate }}</a>
          </td>
        </tr>
      </table>
    </section>
  </section>
</section>
<section id="temp-pdf" *ngIf="showPortal">
  <app-statement-display [statement]="details" [toShow]="showModal"></app-statement-display>
</section>
