import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FileUploadComponent } from '../file-upload/file-upload.component'
import { translationChunksConfig } from '@spartacus/assets';
import { CmsConfig, ConfigModule, I18nModule, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [
      FileUploadComponent
  ],
  exports:[
    FileUploadComponent
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    I18nModule,
  ],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],

})
export class FileUploadModule {}
