import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GlobalMessageService,
  GlobalMessageType,
  LanguageService,
  RoutingService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { CustomRegistrationService } from '../../custom-registration.service';
import {
  globalErrorMsgEng,
  globalErrorMsgFr,
  siteLanguagesEng,
} from '../../registration.constants';

const maxlengthChar = 256;
const maxlengthTwelve = 12;
const nameRegex = '[^!$%*()=]+$';
const faxRegex = '^[0-9]{3}-[0-9]{3}-[0-9]{4}$';
const emailRegexExp =
  '^([A-Za-z0-9\\._-]+)@([A-Za-z0-9\\._-]+)\\.([A-Za-z\\.]{2,8})(\\.[A-Za-z\\.]{2,8})?$';

const primaryDataEmail = 'primaryData.email';
const primaryDataFax = 'primaryData.fax';
const invoiceContactEmail = 'invoiceNewContactData.email';
const invoiceContactFax = 'invoiceNewContactData.fax';
const invoiceContactPrimary = 'invoiceNewContactData.primaryContact2';
const shippingContactPrimary = 'shippingNewContactData.primaryContact3';
const shippingContactEmail = 'shippingNewContactData.email';
const shippingContactFax = 'shippingNewContactData.fax';

@Component({
  selector: 'app-contacts-information',
  templateUrl: './contacts-information.component.html',
  styleUrls: ['./contacts-information.component.scss'],
})
export class ContactsInformationComponent implements OnInit, OnDestroy {
  @Input() formDetails: any;
  header: string;
  formData: any;
  creditInvoice: string;
  contactsForm: FormGroup;
  contactTitle: any[] = [];
  contactRole: any[] = [];
  isPrimaryContact: boolean[] = [true, false];
  isInvoiceContact: boolean[] = [true, false];
  isShippingContact: boolean[] = [true, false];
  preferredContactMethod: string[] = ['INT', 'FAX'];
  accountRegisSub: Subscription;
  contactTitleSub: Subscription;
  contactRoleSub: Subscription;
  errorMessageText: string = globalErrorMsgEng;

  emailRegex =
    /^([A-Za-z\d\._-]+)@([A-Za-z\d\._-]+)\.([A-Za-z\.]{2,8})(\.[A-Za-z\.]{2,8})?$/g;

  validationType: object;
  constructor(
    private readonly accountRegService: CustomRegistrationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly sharedService: SharedService,
    private readonly user: UserAccountFacade,
    private readonly router: RoutingService,
    private readonly languageService: LanguageService,
    private readonly globalMessage: GlobalMessageService
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.languageService.getActive().subscribe((langRes) => {
      this.errorMessageText =
        langRes === siteLanguagesEng ? globalErrorMsgEng : globalErrorMsgFr;
    });
    this.validationType = {
      firstName: [
        Validators.required,
        Validators.pattern(nameRegex),
        Validators.maxLength(maxlengthChar),
      ],
      lastName: [
        Validators.required,
        Validators.pattern(nameRegex),
        Validators.maxLength(maxlengthChar),
      ],
      title: [Validators.required],
      role: [Validators.required],
      preferredContactMethod2: [Validators.required],
      email: [Validators.required, Validators.pattern(emailRegexExp)],
      fax: [
        Validators.pattern(faxRegex),
        Validators.maxLength(maxlengthTwelve),
      ],
      phone: [
        Validators.pattern(faxRegex),
        Validators.maxLength(maxlengthTwelve),
      ],
    };
    this.accountRegService.getContactTitle({
      enumerationType: 'ContactTitle',
      fields: 'DEFAULT',
    });
    this.accountRegService.getContactRole({
      enumerationType: 'UserRole',
      fields: 'DEFAULT',
    });

    this.contactsForm = new FormGroup({
      primaryContact: new FormControl(this.isPrimaryContact[0]),
      invoiceContact: new FormControl(this.isInvoiceContact[1]),
      shippingContact: new FormControl(false),
      primaryData: new FormGroup({
        firstName: new FormControl(null, [
          Validators.required,
          Validators.pattern(nameRegex),
          Validators.maxLength(maxlengthChar),
        ]),
        lastName: new FormControl(null, [
          Validators.required,
          Validators.pattern(nameRegex),
          Validators.maxLength(maxlengthChar),
        ]),
        title: new FormControl(null, [Validators.required]),
        role: new FormControl(null, [Validators.required]),
        preferredContactMethod: new FormControl(
          this.preferredContactMethod[0],
          [Validators.required]
        ),
        email: new FormControl(null, [
          Validators.required,
          Validators.pattern(emailRegexExp),
        ]),
        fax: new FormControl(null, [
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]),
        phone: new FormControl(null, [
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]),
      }),
      invoiceNewContactData: new FormGroup({
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        title: new FormControl(null),
        role: new FormControl(null),
        preferredContactMethod2: new FormControl(
          this.preferredContactMethod[0]
        ),
        email: new FormControl(null),
        fax: new FormControl(null, [
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]),
        phone: new FormControl(null),
        primaryContact2: new FormControl(this.isPrimaryContact[0]),
        invoiceContact: new FormControl(this.isInvoiceContact[0]),
        shippingContact: new FormControl(false),
      }),
      shippingNewContactData: new FormGroup({
        firstName: new FormControl(null),
        lastName: new FormControl(null),
        title: new FormControl(null),
        role: new FormControl(null),
        preferredContactMethod3: new FormControl(
          this.preferredContactMethod[0]
        ),
        email: new FormControl(null),
        fax: new FormControl(null, [
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]),
        phone: new FormControl(null),
        primaryContact3: new FormControl(this.isPrimaryContact[0]),
        invoiceContact: new FormControl(false),
        shippingContact: new FormControl(this.isShippingContact[0]),
      }),
    });

    this.contactTitleSub = this.accountRegService.contactTitleData$.subscribe(
      (response) => {
        if (response !== false) {
          this.contactTitle = response;
        }
      }
    );

    this.contactRoleSub = this.accountRegService.contactRoleData$.subscribe(
      (response) => {
        if (response !== false) {
          this.contactRole = response;
        }
      }
    );

    this.contactsForm
      .get('primaryData.preferredContactMethod')
      .valueChanges.subscribe((value) => {
        this.primaryPreferredContactEvaluation(value);
      });

    this.contactsForm
      .get('invoiceNewContactData.preferredContactMethod2')
      .valueChanges.subscribe((value) => {
        this.invoicePreferredContactEvaluation(value);
      });

    this.contactsForm
      .get(invoiceContactPrimary)
      .valueChanges.subscribe((value) => {
        if (value === this.isPrimaryContact[0]) {
          this.removeValidators(
            this.contactsForm.get('invoiceNewContactData') as FormGroup
          );
        } else if (value === this.isPrimaryContact[1]) {
          this.addValidators(
            this.contactsForm.get('invoiceNewContactData') as FormGroup
          );
        } else {
          // no change
        }
      });

    this.contactsForm.get('invoiceContact').valueChanges.subscribe((value) => {
      if (value === this.isPrimaryContact[1]) {
        this.removeValidators(
          this.contactsForm.get('invoiceNewContactData') as FormGroup
        );
      } else if (
        value === true &&
        this.contactsForm.get(invoiceContactPrimary).value === false
      ) {
        this.addValidators(
          this.contactsForm.get('invoiceNewContactData') as FormGroup
        );
      } else {
        // no change
      }
    });

    this.contactsForm.get('shippingContact').valueChanges.subscribe((value) => {
      if (value === this.isPrimaryContact[1]) {
        this.removeValidators(
          this.contactsForm.get('shippingNewContactData') as FormGroup
        );
      } else if (
        value === true &&
        this.contactsForm.get(shippingContactPrimary).value === false
      ) {
        this.addValidators(
          this.contactsForm.get('shippingNewContactData') as FormGroup
        );
      } else {
        // no change
      }
    });

    this.contactsForm
      .get(shippingContactPrimary)
      .valueChanges.subscribe((value) => {
        if (value === this.isPrimaryContact[0]) {
          this.removeValidators(
            this.contactsForm.get('shippingNewContactData') as FormGroup
          );
        } else if (value === this.isPrimaryContact[1]) {
          this.addValidators(
            this.contactsForm.get('shippingNewContactData') as FormGroup
          );
        } else {
          // no change
        }
      });

    this.contactsForm
      .get('shippingNewContactData.preferredContactMethod3')
      .valueChanges.subscribe((value) => {
        this.shippingPreferredContactEvaluation(value);
      });
  }

  public removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      if (!!this.validationType[key]) {
        form.get(key).clearValidators();
        form.get(key).updateValueAndValidity();
      }
    }
  }

  public addValidators(form: FormGroup) {
    for (const key in form.controls) {
      if (!!this.validationType[key]) {
        form.get(key).setValidators(this.validationType[key]);
        form.get(key).updateValueAndValidity();
      }
    }
  }

  primaryPreferredContactEvaluation(value) {
    if (value === this.preferredContactMethod[0]) {
      this.contactsForm
        .get(primaryDataEmail)
        .setValidators([
          Validators.required,
          Validators.pattern(emailRegexExp),
        ]);
      this.contactsForm.get(primaryDataFax).clearValidators();
      this.contactsForm
        .get(primaryDataFax)
        .setValidators([
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]);
      this.contactsForm.get(primaryDataEmail).updateValueAndValidity();
      this.contactsForm.get(primaryDataFax).updateValueAndValidity();
    } else if (value === this.preferredContactMethod[1]) {
      this.contactsForm
        .get(primaryDataFax)
        .setValidators([
          Validators.required,
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]);
      this.contactsForm.get(primaryDataEmail).clearValidators();
      this.contactsForm
        .get(primaryDataEmail)
        .setValidators([Validators.pattern(emailRegexExp)]);
      this.contactsForm.get(primaryDataFax).updateValueAndValidity();
      this.contactsForm.get(primaryDataEmail).updateValueAndValidity();
    } else {
      // no change
    }
  }

  invoicePreferredContactEvaluation(value) {
    if (value === this.preferredContactMethod[0]) {
      this.contactsForm
        .get(invoiceContactEmail)
        .setValidators([
          Validators.required,
          Validators.pattern(emailRegexExp),
        ]);
      this.contactsForm.get(invoiceContactFax).clearValidators();
      this.contactsForm
        .get(invoiceContactFax)
        .setValidators([
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]);
      this.contactsForm.get(invoiceContactEmail).updateValueAndValidity();
      this.contactsForm.get(invoiceContactFax).updateValueAndValidity();
    } else if (value === this.preferredContactMethod[1]) {
      this.contactsForm
        .get(invoiceContactFax)
        .setValidators([
          Validators.required,
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]);
      this.contactsForm.get(invoiceContactEmail).clearValidators();
      this.contactsForm
        .get(invoiceContactEmail)
        .setValidators([Validators.pattern(emailRegexExp)]);
      this.contactsForm.get(invoiceContactFax).updateValueAndValidity();
      this.contactsForm.get(invoiceContactEmail).updateValueAndValidity();
    } else {
      // no change
    }
  }

  shippingPreferredContactEvaluation(value) {
    if (value === this.preferredContactMethod[0]) {
      this.contactsForm
        .get(shippingContactEmail)
        .setValidators([
          Validators.required,
          Validators.pattern(emailRegexExp),
        ]);
      this.contactsForm.get(shippingContactFax).clearValidators();
      this.contactsForm
        .get(shippingContactFax)
        .setValidators([
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]);
      this.contactsForm.get(shippingContactEmail).updateValueAndValidity();
      this.contactsForm.get(shippingContactFax).updateValueAndValidity();
    } else if (value === this.preferredContactMethod[1]) {
      this.contactsForm
        .get(shippingContactFax)
        .setValidators([
          Validators.required,
          Validators.pattern(faxRegex),
          Validators.maxLength(maxlengthTwelve),
        ]);
      this.contactsForm.get(shippingContactEmail).clearValidators();
      this.contactsForm
        .get(shippingContactEmail)
        .setValidators([Validators.pattern(emailRegexExp)]);
      this.contactsForm.get(shippingContactFax).updateValueAndValidity();
      this.contactsForm.get(shippingContactEmail).updateValueAndValidity();
    } else {
      // no change
    }
  }

  back() {
    const shippingDetails = {
      header: 'shippingDetails',
      form: this.formDetails,
    };
    this.accountRegService.sectionChange.next(shippingDetails);
  }

  createAccount() {
    this.contactsForm.markAllAsTouched();
    if (this.contactsForm.valid) {
      this.organizeData();
      const formData = { ...this.formDetails };
      if (!!formData?.addresses && formData?.addresses.length > 1) {
        delete formData?.addresses[1]?.sameAsBillingAddress;
      }
      this.accountRegisSub = this.accountRegService
        .postAccountRegistration(formData)
        .subscribe((res) => {
          if (res === null) {
            const contactDetails = { header: 'confirmation' };
            !!localStorage.getItem('accountConfirmationData')
              ? localStorage.removeItem('accountConfirmationData')
              : null;
            localStorage.setItem(
              'accountConfirmationData',
              JSON.stringify(contactDetails)
            );
            this.sharedService.setSessionStorage(
              'accountConfirmationData',
              contactDetails
            );
            this.router.goByUrl('/register/confirm');
          }
        });
    } else {
      window.scroll(0, 0);
      this.globalMessage.add(
        this.errorMessageText,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  organizeData() {
    this.formDetails.contacts = [];
    const pContacts = {
      primaryContact: false,
      invoiceContact: false,
      shippingContact: false,
    };
    pContacts.primaryContact = this.contactsForm.get('primaryContact').value;
    pContacts.invoiceContact =
      !!this.contactsForm.get('invoiceContact').value &&
      !this.contactsForm.get(invoiceContactPrimary).value
        ? false
        : this.contactsForm.get('invoiceContact').value;
    pContacts.shippingContact =
      !!this.contactsForm.get('shippingContact').value &&
      !this.contactsForm.get(shippingContactPrimary).value
        ? false
        : this.contactsForm.get('shippingContact').value;
    const primaryObj = {
      ...pContacts,
      ...this.contactsForm.get('primaryData').value,
    };
    primaryObj.email = this.contactsForm.get(primaryDataEmail).value;
    primaryObj.fax = this.contactsForm.get(primaryDataFax).value;
    this.formDetails.contacts.push(primaryObj);
    const propertyNames = Object.keys(
      this.contactsForm.get('primaryData').value
    );
    if (!!propertyNames && propertyNames.length > 0) {
      const arr = ['preferredContactMethod'];
      for (const elem of arr) {
        const ind = propertyNames.indexOf(elem);
        if (ind >= 0) {
          propertyNames.splice(ind, 1);
        }
      }
    }
    this.organizeInvoiceContactData(propertyNames);
    this.organizeShippingContactData(propertyNames);
  }

  organizeInvoiceContactData(propertyNames) {
    if (
      !!this.contactsForm.get('invoiceContact').value &&
      !this.contactsForm.get(invoiceContactPrimary).value
    ) {
      const obj = {
        primaryContact: false,
        shippingContact: false,
        invoiceContact: true,
        preferredContactMethod: '',
      };
      for (const key of propertyNames) {
        obj[key] = this.contactsForm.get(`invoiceNewContactData.${key}`).value;
      }
      obj.preferredContactMethod = this.contactsForm.get(
        'invoiceNewContactData.preferredContactMethod2'
      ).value;
      obj['email'] = this.contactsForm.get(invoiceContactEmail).value;
      obj['fax'] = this.contactsForm.get(invoiceContactFax).value;
      this.formDetails.contacts.push(obj);
    }
  }

  organizeShippingContactData(propertyNames) {
    if (
      !!this.contactsForm.get('shippingContact').value &&
      !this.contactsForm.get(shippingContactPrimary).value
    ) {
      const obj = {
        primaryContact: false,
        shippingContact: true,
        invoiceContact: false,
        preferredContactMethod: '',
      };
      for (const key of propertyNames) {
        obj[key] = this.contactsForm.get(`shippingNewContactData.${key}`).value;
      }
      obj.preferredContactMethod = this.contactsForm.get(
        'shippingNewContactData.preferredContactMethod3'
      ).value;
      obj['email'] = this.contactsForm.get(shippingContactEmail).value;
      obj['fax'] = this.contactsForm.get(shippingContactFax).value;
      this.formDetails.contacts.push(obj);
    }
  }

  ngOnDestroy() {
    this.contactTitleSub?.unsubscribe();
    this.contactRoleSub?.unsubscribe();
    this.accountRegisSub?.unsubscribe();
  }
}
