<section class="row invoicesView">
    <aside class="col-md-3 col-sm-3 col-lg-2 col-xs-12 invoice-filter pr-0">
      <fieldset id="filter-by">

        <legend class="filterByTitle-main order-history-search">
          {{ "invoiceCredit.searchBy" | cxTranslate }}
        </legend>

        <div class="grey-line"></div>

        <!-- Timeframe -->
        <div class="filterByGroup mb-3">
          <div class="filterByTitle">
            <label for="invoice">{{
              "invoiceCredit.timeFrame" | cxTranslate
            }} </label>
          </div>
          <ng-select
            name="timeFrame"
            [items]="timeFrameList"
            [searchable]="false"
            [clearable]="false"
            bindLabel="name"
            bindValue="code"
            class="dropdown-credit"
            (ngModelChange)="clearDateRange()"
            [(ngModel)]="timeFrame"
            [disabled]="true" 
            [readonly] = false
          >
          </ng-select>
        </div>
        <br><br><br>
        <div class="grey-line"></div>
        <!--Date Range-->
        <div class="filterByGroup">
          <div class="filterByTitle mb-2">
            {{ "invoiceCredit.dateRange" | cxTranslate }}
          </div>
          <div class="delivary-details">
            <div>
              <fieldset class="form-horizontal mb0">
                <legend class="control-group mb-2">
                  <label for="fromDate" aria-labelledby="dataRange fromDate">{{
                    "invoiceCredit.from" | cxTranslate
                  }}</label>
                </legend>
                <div class="headSearch-holder">
                  <label class="searchbox orders-filter time-frame">
                    <img src="../../../assets/icons/calendar.svg" alt="" />
                    <input
                      type="text"
                      placeholder="{{ 'invoiceCredit.dateFormat' | cxTranslate }}"
                      class="input-filter"
                      [(ngModel)]="fromDate"
                      (ngModelChange)="validateDate()"
                      bsDatepicker
                      [minDate]="minSelectionDate"
                      [maxDate]="maxSelectionDate"
                      [daysDisabled]="[0, 6]"
                      [bsConfig]="{ containerClass: 'order-list-date' }"
                      (click)="clearToDate()"
                    />
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="delivary-details">
            <div>
              <fieldset class="form-horizontal mb0">
                <legend class="control-group mb-2">
                  <label for="toDate" aria-labelledby="dataRange toDate">{{
                    "invoiceCredit.to" | cxTranslate
                  }}</label>
                </legend>
                <div class="headSearch-holder">
                  <label class="searchbox orders-filter time-frame">
                    <img src="../../../assets/icons/calendar.svg" alt="" />
                    <input
                      type="text"
                      placeholder="{{ 'invoiceCredit.dateFormat' | cxTranslate }}"
                      class="input-filter"
                      [(ngModel)]="toDate"
                      (ngModelChange)="validateDate()"
                      bsDatepicker
                      [maxDate]="maxSelectionDate"
                      [maxDate]="toDate"
                      [minDate]="minSelectionDate"
                      [minDate]="fromDate"
                      [daysDisabled]="[0, 6]"
                      [bsConfig]="{ containerClass: 'order-list-date' }"
                    />
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <span class="dateError" *ngIf="dateError">{{
            "invoiceCredit.invalidDateErrMsg" | cxTranslate
          }}</span>
          <span class="dateError" *ngIf="singleDateError">{{
            "invoiceCredit.selectBothDate" | cxTranslate
          }}</span>
          <!-- <span class="dateError" *ngIf="differenceInDaysError">{{
            "invoiceCredit.differenceBetweenDates" | cxTranslate
            }}</span> -->
        </div>
        <!--End-->

        <div class="grey-line"></div>

        <!-- Invoice number -->
        <div class="filterByGroup">
          <div class="filterByTitle mb-2">
            <label for="invoice">{{ "invoiceCredit.creditInvoice" | cxTranslate }}</label>
          </div>
          <div class="headSearch-holder">
            <label class="searchbox orders-filter time-frame">
              <input
                placeholder="{{'invoiceCredit.searchInput' | cxTranslate}}"
                type="text"
                autocomplete="off"
                aria-describedby="initialDescription"
                aria-controls="results"
                aria-autocomplete="both"
                class="input-filter"
                [(ngModel)]="creditOrInvoiceNumber"
              />
              <button role="presentation" class="search-icon">
                <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
              </button>
            </label>
          </div>
        </div>

        <!-- Apply / Cancel filters -->
        <div class="account-order-filter-btn">
          <button
            type="submit"
            class="btn btn-default blue-button text-invoice-button-initial"
            data-di-id="di-id-362f2c77-5f23e741"
            (click)="applyFilter()"
            [disabled]="differenceInDaysError"
          >
            {{ "invoiceCredit.applyFilters" | cxTranslate }}
          </button>
          <button
            type="button"
            id="clearOrderFiltersBtn"
            class="btn btn-default white-button text-invoice-button-initial"
            data-di-id="#clearOrderFiltersBtn"
            (click)="clearFilters()"
          >
            {{ "invoiceCredit.clearFilters" | cxTranslate }}
          </button>
        </div>
      </fieldset>
      <div class="line-intersection"></div>
      <!-- <section>
        <div>
          <app-need-to-go-back></app-need-to-go-back>
        </div>
        <div class="line-intersection"></div>
      </section> -->
      <section class="hide-sm">
        <div class="banner-img">
          <!-- <app-flu-dashboard-banner
            bannerComponent="OrderHistoryBannerComponent"
          ></app-flu-dashboard-banner> -->
        </div>
      </section>
    </aside>

    <section class="col-md-9 col-sm-9 col-lg-10 invoice-body">
        <!-- INVOICES -->
        <section *ngIf="openCreditInvoice?.length === 0 || paymentList?.length === 0" class="payment-section">
            <h3 class="no-order-tittle">
                {{ "invoiceCredit.invoiceTitle" | cxTranslate }}
            </h3>
            <section class="no-credit-invoice">
                {{ "invoiceCredit.no_open_invoices" | cxTranslate }}
            </section>
        </section>
        <section *ngIf="paymentList.length > 0" class="payment-section">
            <section class="row">
                <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
                    <h3 class="oi-table-title">
                        {{ "invoiceCredit.invoiceTitle" | cxTranslate }}
                    </h3>
                    <!-- <p>
                        {{ "invoiceCredit.card_accept_msg" | cxTranslate }}
                        <img src="assets/icons/mastercard.png" alt="amex" />
                        <img src="assets/icons/visa.png" alt="visa" />
                    </p> -->
                </section>
            </section>
            <table aria-describedby="invoicesTable" id="applyCreditsTable" class="payments-table table table-striped">
                <thead>
                    <tr>
                        <!-- <th scope="col" class="col-width">
                            {{ "invoiceCredit.payment_option_open_inv" | cxTranslate }}
                        </th> -->
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'refDocNo', paymentList, 'normal')">
                            {{ "invoiceCredit.invoice" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width"
                            (click)="sortColumn($event, 'docDate', paymentList, 'Date')">
                            {{ "invoiceCredit.invoice_date" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'dueDate', paymentList, 'Date')">
                            {{ "invoiceCredit.due_date" | cxTranslate }}
                            <i class="my-icon fas fa-caret-up"></i>
                        </th>
                        <th scope="col" class="col-width " (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                            {{ "invoiceCredit.invoice_balance" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'salesOrder', paymentList, 'Number')">
                            {{ "invoiceCredit.orderNumber" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <!-- <th scope="col" class="col-width">
                            {{ "invoiceCredit.payment_amount" | cxTranslate }}
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of paymentList; let i = index">
                        <!-- <td class="pay checkbox-pay">
                            <input type="checkbox" class="payment-checkbox" (change)="
                  parsePaymentType(data, $event.target.checked, invoiceItems, i)
                " [(ngModel)]="data.checked" />
                            <span class="payment-checkbox-name">{{
                                paymentOptionInvoicesDefault
                                }}</span>
                        </td> -->
                        <td class="pay">
                            <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                                href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refDocNo, data.systemId)">
                                {{ data.refDocNo }}
                            </a>
                            <a *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')"
                                href="javascript: void(0);" class="underline" (click)="invoicePdf(data.dochdrtxt, data.systemId)">
                                {{ data.dochdrtxt }}
                            </a>
                            <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                {{ data.refDocNo }}
                            </span>
                            <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                {{ data.dochdrtxt }}
                            </span>
                            <span *ngIf="data.docType === 'YR'">
                                {{ data.refhdr }}
                            </span>
                        </td>
                        <td class="pay">{{ data.docDate }}</td>
                        <td class="pay">
                            <span [ngClass]="!!data.dueDate && dateComparison(data.dueDate) ? 'red':''">{{ data.dueDate
                                }}
                                <ng-template #popSaveContent>
                                    <span class="popover-content">{{
                                        "invoiceCredit.past_due_date" | cxTranslate
                                        }}</span>
                                </ng-template>
                                <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover" placement="top"
                                    (click)="toggle(p1)" container="body" popoverClass="popover-links-invoice"
                                    *ngIf="!!data.dueDate && dateComparison(data.dueDate)">
                                    <img src="assets/icons/warning_red.png" alt="warningIcon"
                                        class="warningIconEvent" />
                                </span>
                            </span>
                        </td>
                        <td class="pay col-width-align">{{ data.amount | invoiceCurrency }}</td>
                        <td class="pay">{{ data.salesOrder }}</td>
                        <!-- <td class="pay">
                            <input type="text" [disabled]="!data.checked || disablePaymentAmount"
                                [(ngModel)]="data.paymentAmount" (ngModelChange)="onInvoiceValueChange($event, i)"
                                (focus)="onFocus(data.paymentAmount, i)"
                                (blur)="onBlur(data.paymentAmount, i, data.amount)" class="border-radi amount-to-pay" />
                            <div class="invoice-error" *ngIf="data.checked && data.invalidAmount">{{
                                "invoiceCredit.invalid_amt"
                                | cxTranslate }}</div>
                            <div class="invoice-error" *ngIf="errorMessageStatus[i]">{{
                                "invoiceCredit.value_greater_than_zero" | cxTranslate
                                }}</div>
                        </td> -->
                    </tr>
                </tbody>
            </table>

            <section class="invoices-table-2column">
                <div class="row invoices-row-table" *ngFor="let data of paymentList; let i = index">
                    <div class="column invoices-col-headerrwd">
                        <section class="">
                            <!-- <p>
                                {{ "invoiceCredit.payment_option_open_inv" | cxTranslate }}
                            </p> -->
                            <p scope="col" (click)="sortColumn($event, 'refDocNo', paymentList, 'normal')">
                                {{ "invoiceCredit.invoice" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <p scope="col" (click)="sortColumn($event, 'docDate', paymentList, 'Date')">
                                {{ "invoiceCredit.invoice_date" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <p scope="col" (click)="sortColumn($event, 'dueDate', paymentList, 'Date')">
                                {{ "invoiceCredit.due_date" | cxTranslate }}
                                <i class="my-icon fas fa-caret-up"></i>
                            </p>
                            <p scope="col" class="" (click)="sortColumn($event, 'amount', paymentList, 'Number')">
                                {{ "invoiceCredit.invoice_balance" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <p scope="col" class="" (click)="sortColumn($event, 'salesOrder', paymentList, 'Number')">
                                {{ "invoiceCredit.orderNumber" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <!-- <p scope="col">
                                {{ "invoiceCredit.payment_amount" | cxTranslate }}
                            </p> -->
                        </section>
                    </div>
                    <div class="column desc-column">
                        <section>
                            <!-- <label for="selected_invoices" class="pay selectionBox">
                                <input type="checkbox" class="payment-checkbox" (change)="
                      parsePaymentType(data, $event.target.checked, invoiceItems, i)
                    " [(ngModel)]="data.checked" />
                                <span class="payment-checkbox-name">{{
                                    paymentOptionInvoicesDefault
                                    }}</span>
                            </label> -->
                            <p class="pay">
                                <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                                    href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refDocNo, data.systemId)">
                                    {{ data.refDocNo }}
                                </a>
                                <a *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')"
                                    href="javascript: void(0);" class="underline" (click)="invoicePdf(data.dochdrtxt, data.systemId)">
                                    {{ data.dochdrtxt }}
                                </a>
                                <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                    {{ data.refDocNo }}
                                </span>
                                <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                    {{ data.dochdrtxt }}
                                </span>
                                <span *ngIf="data.docType === 'YR'">
                                    {{ data.refhdr }}
                                </span>
                            </p>
                            <p class="pay">{{ data.docDate }}</p>
                            <p class="pay">
                                <span class="red">{{ data.dueDate }}
                                    <ng-template #popSaveContent>
                                        <span class="popover-content">{{
                                            "invoiceCredit.past_due_date" | cxTranslate
                                            }}</span>
                                    </ng-template>
                                    <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover"
                                        placement="top" (click)="toggle(p1)" container="body"
                                        popoverClass="popover-links-invoice"
                                        *ngIf="!!data.dueDate && dateComparison(data.dueDate)">
                                        <img src="assets/icons/warning_red.png" alt="warningIcon"
                                            class="warningIconEvent" />
                                    </span>
                                </span>
                            </p>
                            <p class="pay">{{ data.amount | invoiceCurrency }}</p>
                          <p class="pay">{{ data.salesOrder }}</p>
                            <!-- <p class="pay">
                                <input type="text" [disabled]="!data.checked" [(ngModel)]="data.paymentAmount"
                                    (ngModelChange)="onInvoiceValueChange($event, i)"
                                    (focus)="onFocus(data.paymentAmount, i)"
                                    (blur)="onBlur(data.paymentAmount, i, data.amount)"
                                    class="border-radi amount-to-pay amount-text" />
                                <span class="amount-value-error" *ngIf="data.checked && data.invalidAmount">{{
                                    "invoiceCredit.invalid_amt"
                                    | cxTranslate }}</span>
                                <span class="amount-value-error" *ngIf="errorMessageStatus[i]">{{
                                    "invoiceCredit.value_greater_than_zero" | cxTranslate
                                    }}</span>
                            </p> -->
                        </section>
                    </div>
                </div>
            </section>



            <button [routerLink]="['/', 'openInvoicePayment']" [ngClass]="invoiceItems.length != 0 ? '' : 'display'"
                type="button" class="btn btn-primary float-right btnpaymentinvoicecredit"
                (click)="initiateInvoicePaymentFlow()">
                {{ "invoiceCredit.continue_button_txt" | cxTranslate }}
            </button>
        </section>


        <!-- CREDITS -->
        <section *ngIf="openCreditInvoice?.length === 0 || creditList?.length === 0" class="payment-section">
          <h3 class="no-order-tittle">
              {{ "invoiceCredit.credits_title" | cxTranslate }}
          </h3>
          <section class="no-credit-invoice">
              {{ "invoiceCredit.no_open_credits" | cxTranslate }}
          </section>
        </section>
        <section *ngIf="creditList.length > 0" class="credits-section border-bottom">
            <section class="row">
                <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
                    <h3 class="oi-table-title">
                        {{ "invoiceCredit.credits_title" | cxTranslate }}
                    </h3>
                </section>
            </section>
            <table aria-describedby="creditsTable" id="applyCreditsTable" class="payments-table table table-striped">
                <thead>
                    <tr>
                        <!-- <th scope="col"></th> -->
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'refDocNo', creditList, 'normal')">
                            {{ "invoiceCredit.credit#" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'docNo', creditList, 'normal')">
                            {{ "invoiceCredit.invoice_ref_number" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'docDate', creditList, 'Date')">
                            {{ "invoiceCredit.credit_date" | cxTranslate }}
                            <i class="my-icon fas fa-caret-up"></i>
                        </th>
                        <th scope="col" class="text-align col-width"
                            (click)="sortColumn($event, 'amount', creditList, 'Number')">
                            {{ "invoiceCredit.credit_amount" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="text-align col-width"
                          (click)="sortColumn($event, 'salesOrder', creditList, 'Number')">
                        {{ "invoiceCredit.orderNumber" | cxTranslate }}
                        <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <!-- <th scope="col" class="col-width dropdown-width">
                            {{ "invoiceCredit.apply_to_invoice" | cxTranslate }}
                        </th>
                        <th scope="col" class="text-align col-width">
                            {{ "invoiceCredit.credit_applied" | cxTranslate }}
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of creditList; let i = index">
                        <!-- <td class="payment-chk-mob">
                            <section>
                                <input type="checkbox" class="payment-checkbox" (change)="
                    parsePaymentType(
                      data,
                      $event.target.checked,
                      creditItems,
                      i,
                      true
                    )
                  " [(ngModel)]="data.checked" />
                            </section>
                        </td> -->
                      <td>
                          <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                            href="javascript: void(0);" class="underline" (click)="creditPdf(data.refDocNo, data.systemId)">
                              {{ data.refDocNo }}
                          </a>
                      </td>
                        <td>
                            <span *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')">
                                {{ data.docNo }}
                            </span>
                            <span *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')">
                                {{ data.dochdrtxt }}
                            </span>
                            <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                {{ data.docNo }}
                            </span>
                            <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                {{ data.dochdrtxt }}
                            </span>
                            <span *ngIf="data.docType === 'YR'">
                                {{ data.refhdr }}
                            </span>
                        </td>
                        <td>
                            {{ data.docDate }}
                        </td>
                        <td class="amount-align">-{{ data.amount | invoiceCurrency }}</td>
                        <td class="amount-align">{{ data.salesOrder }}</td>
                        <!-- <td class="sort-by-region fst-dropdown custom-selectpay pad-btm cred-dropdown">
                            <ng-select [items]="data.creditDropDown" (change)="updateInvoiceSource($event, i)"
                                [searchable]="false" [clearable]="false" bindLabel="displayValue" bindValue="refDocNo"
                                placeholder="Select" [(ngModel)]="data.selectedInvoice" class="dropdown-credit"
                                [disabled]="!data.checked">
                            </ng-select>

                            <span class="amount-value-error credits" [ngClass]="
                              data.checked &&
                              data.errorMsg &&
                              data.checkedAndNotFilledInvoice &&
                              clicked
                                ? 'visible-ui'
                                : 'invisible-ui'
                            ">{{ "invoiceCredit.credits_invoice_select_msg" | cxTranslate }}
                            </span>
                        </td>

                        <td class="text-right">{{ data?.creditsApplied | localCurrency }}</td> -->
                    </tr>
                </tbody>
            </table>

            <section class="credits-table-2column">
                <div class="row credits-row-table" *ngFor="let data of creditList; let i = index">
                    <div class="column header-column credits-table-hcol">
                        <section class="col-header-rwd">
                            <!-- <p>Select</p> -->
                            <p>{{ "invoiceCredit.credit#" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.invoice_ref_number" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.credit_date" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.credit_amount" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.orderNumber" | cxTranslate }}</p>
                            <!-- <p class="applyinvoice-spacing-rwd">
                                {{ "invoiceCredit.apply_to_invoice" | cxTranslate }}
                            </p>
                            <p>
                                {{ "invoiceCredit.credit_applied" | cxTranslate }}
                            </p> -->
                        </section>
                    </div>
                    <div class="column desc-column">
                        <section>
                            <!-- <label for="selected_credits" class="selectionBox">
                                <input type="checkbox" class="payment-checkbox"
                                    (change)="parsePaymentType(data,$event.target.checked, creditItems,i,true )"
                                    [(ngModel)]="data.checked" />
                            </label> -->
                            <p style="margin-top: 22px;">
                                <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                                  href="javascript: void(0);" class="underline" (click)="creditPdf(data.docNo, data.systemId)">
                                    {{ data.refDocNo }}
                                </a>
                            </p>
                            <p>
                                <span *ngIf="data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF'">
                                    {{ data.docNo }}
                                </span>
                                <span *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')">
                                    {{ data.dochdrtxt }}
                                </span>
                                <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                    {{ data.docNo }}
                                </span>
                                <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                    {{ data.dochdrtxt }}
                                </span>
                                <span *ngIf="data.docType === 'YR'">
                                    {{ data.refhdr }}
                                </span>
                            </p>
                            <p>{{ data.docDate }}</p>
                            <p>-{{ data.amount | invoiceCurrency }}</p>
                            <p>{{ data.salesOrder }}</p>
                            <!-- <p class="cred-dropdown-mob">
                                <ng-select [items]="data.creditDropDown" (change)="updateInvoiceSource($event, i)"
                                    [searchable]="false" [clearable]="false" bindLabel="displayValue" bindValue="docNo"
                                    placeholder="Select" [(ngModel)]="data.selectedInvoice" class="dropdown-credit"
                                    [disabled]="!data.checked"></ng-select>

                                <span class="amount-value-error credits" [ngClass]="
                                      data.checked &&
                                      data.errorMsg &&
                                      data.checkedAndNotFilledInvoice &&
                                      clicked
                                        ? 'visible-ui'
                                        : 'invisible-ui'
                                    ">{{ "invoiceCredit.credits_invoice_select_msg" | cxTranslate }}</span>
                            </p> -->

                            <!-- <p>{{ data?.creditsApplied | localCurrency }}</p> -->
                        </section>
                    </div>
                </div>
            </section>

            <button [ngClass]="
          creditItems.length != 0 && !creditItems[0].errorMsg ? '' : 'display'
        " type="button" [disabled]="!isValid" class="btn btn-primary float-right btnpaymentinvoicecredit"
                (click)="initiateCreditPaymentFlow()">
                {{ "invoiceCredit.continue_button_txt" | cxTranslate }}
            </button>
        </section>


        <!-- START CLOSED INVOICES DATA -->

        <section *ngIf="closedInvoiceList?.length === 0 || closedInvoiceList?.length === 0" class="payment-section">
            <ng-template #accountNumberPopContent>
                <span class="popover-content">
                    {{"invoiceCredit.closedInvoicesPopoverMsg" | cxTranslate}}</span>
            </ng-template>
            <h3 class="no-order-tittle">
                  {{ "invoiceCredit.closedInvoicesTitle" | cxTranslate }}
                  <span [ngbPopover]="accountNumberPopContent" triggers="manual" #p1="ngbPopover"
                  placement="top" (click)="toggle(p1)" container="body"
                  popoverClass="popover-links-invoice"
                  triggers="mouseenter:mouseleave"
                 >
                 <img src="../../assets/icons/faqs_reg.png"
                  alt="faq_reg" class="faq warningIconEvent" />
              </span>
          </h3>
          <section class="no-credit-invoice">
              {{ "invoiceCredit.no_closed_invoices" | cxTranslate }}
          </section>
        </section>
        <section *ngIf="closedInvoiceList?.length > 0" class="payment-section">
            <section class="row">
                <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
                    <ng-template #accountNumberPopContent>
                    <span class="popover-content">
                        {{"invoiceCredit.closedInvoicesPopoverMsg" | cxTranslate}}</span>
                    </ng-template>
                    <h3 class="oi-table-title">
                        {{ "invoiceCredit.closedInvoicesTitle" | cxTranslate }} 
                        <span [ngbPopover]="accountNumberPopContent" triggers="manual" #p1="ngbPopover"
                            placement="top" (click)="toggle(p1)" container="body"
                            popoverClass="popover-links-invoice"
                            triggers="mouseenter:mouseleave"
                            >
                            <img src="../../assets/icons/faqs_reg.png"
                            alt="faq_reg" class="faq warningIconEvent" />
                        </span> 
                    </h3>
                    <!-- <p>
                        {{ "invoiceCredit.card_accept_msg" | cxTranslate }}
                        <img src="assets/icons/mastercard.png" alt="amex" />
                        <img src="assets/icons/visa.png" alt="visa" />
                    </p> -->
                </section>
            </section>
            <table aria-describedby="invoicesTable" id="applyCreditsTable" class="payments-table table table-striped">
                <thead>
                    <tr>
                        <!-- <th scope="col" class="col-width">
                            {{ "invoiceCredit.payment_option_open_inv" | cxTranslate }}
                        </th> -->
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'refDocNo', closedInvoiceList, 'normal')">
                            {{ "invoiceCredit.invoice" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width"
                            (click)="sortColumn($event, 'docDate', closedInvoiceList, 'Date')">
                            {{ "invoiceCredit.date_of_invoice" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'dueDate', closedInvoiceList, 'Date')">
                            {{ "invoiceCredit.date_of_payment" | cxTranslate }}
                            <i class="my-icon fas fa-caret-up"></i>
                        </th>
                        <th scope="col" class="col-width " (click)="sortColumn($event, 'amount', closedInvoiceList, 'Number')">
                            {{ "invoiceCredit.invoice_balance" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'salesOrder', closedInvoiceList, 'Number')">
                            {{ "invoiceCredit.orderNumber" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <!-- <th scope="col" class="col-width">
                            {{ "invoiceCredit.payment_amount" | cxTranslate }}
                        </th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of closedInvoiceList; let i = index">
                        <!-- <td class="pay checkbox-pay">
                            <input type="checkbox" class="payment-checkbox" (change)="
                  parsePaymentType(data, $event.target.checked, invoiceItems, i)
                " [(ngModel)]="data.checked" />
                            <span class="payment-checkbox-name">{{
                                paymentOptionInvoicesDefault
                                }}</span>
                        </td> -->
                        <td class="pay">
                            <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                                href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refDocNo, data.systemId)">
                                {{ data.refDocNo }}
                            </a>
                            <a *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')"
                                href="javascript: void(0);" class="underline" (click)="invoicePdf(data.dochdrtxt, data.systemId)">
                                {{ data.dochdrtxt }}
                            </a>
                            <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                {{ data.refDocNo }}
                            </span>
                            <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                {{ data.dochdrtxt }}
                            </span>
                            <span *ngIf="data.docType === 'YR'">
                                {{ data.refhdr }}
                            </span>
                        </td>
                        <td class="pay">{{ data.blineDate }}</td>
                        <td class="pay">
                          {{data.clearDate}}
                        </td>
                        <td class="pay col-width-align">{{ data.amount | invoiceCurrency }}</td>
                        <td class="pay">{{ data.salesOrder }}</td>
                        <!-- <td class="pay">
                            <input type="text" [disabled]="!data.checked || disablePaymentAmount"
                                [(ngModel)]="data.paymentAmount" (ngModelChange)="onInvoiceValueChange($event, i)"
                                (focus)="onFocus(data.paymentAmount, i)"
                                (blur)="onBlur(data.paymentAmount, i, data.amount)" class="border-radi amount-to-pay" />
                            <div class="invoice-error" *ngIf="data.checked && data.invalidAmount">{{
                                "invoiceCredit.invalid_amt"
                                | cxTranslate }}</div>
                            <div class="invoice-error" *ngIf="errorMessageStatus[i]">{{
                                "invoiceCredit.value_greater_than_zero" | cxTranslate
                                }}</div>
                        </td> -->
                    </tr>
                </tbody>
            </table>

            <section class="invoices-table-2column">
                <div class="row invoices-row-table" *ngFor="let data of closedInvoiceList; let i = index">
                    <div class="column invoices-col-headerrwd">
                        <section class="">
                            <!-- <p>
                                {{ "invoiceCredit.payment_option_open_inv" | cxTranslate }}
                            </p> -->
                            <p scope="col" (click)="sortColumn($event, 'refDocNo', closedInvoiceList, 'normal')">
                                {{ "invoiceCredit.invoice" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <p scope="col" (click)="sortColumn($event, 'docDate', closedInvoiceList, 'Date')">
                                {{ "invoiceCredit.invoice_date" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <p scope="col" (click)="sortColumn($event, 'dueDate', closedInvoiceList, 'Date')">
                                {{ "invoiceCredit.due_date" | cxTranslate }}
                                <i class="my-icon fas fa-caret-up"></i>
                            </p>
                            <p scope="col" class="" (click)="sortColumn($event, 'amount', closedInvoiceList, 'Number')">
                                {{ "invoiceCredit.invoice_balance" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <p scope="col" class="" (click)="sortColumn($event, 'salesOrder', closedInvoiceList, 'Number')">
                                {{ "invoiceCredit.orderNumber" | cxTranslate }}
                                <i class="my-icon fas fa-caret-down"></i>
                            </p>
                            <!-- <p scope="col">
                                {{ "invoiceCredit.payment_amount" | cxTranslate }}
                            </p> -->
                        </section>
                    </div>
                    <div class="column desc-column">
                        <section>
                            <!-- <label for="selected_invoices" class="pay selectionBox">
                                <input type="checkbox" class="payment-checkbox" (change)="
                      parsePaymentType(data, $event.target.checked, invoiceItems, i)
                    " [(ngModel)]="data.checked" />
                                <span class="payment-checkbox-name">{{
                                    paymentOptionInvoicesDefault
                                    }}</span>
                            </label> -->
                            <p class="pay">
                                <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                                    href="javascript: void(0);" class="underline" (click)="invoicePdf(data.refDocNo, data.systemId)">
                                    {{ data.refDocNo }}
                                </a>
                                <a *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')"
                                    href="javascript: void(0);" class="underline" (click)="invoicePdf(data.dochdrtxt, data.systemId)">
                                    {{ data.dochdrtxt }}
                                </a>
                                <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                    {{ data.refDocNo }}
                                </span>
                                <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                    {{ data.dochdrtxt }}
                                </span>
                                <span *ngIf="data.docType === 'YR'">
                                    {{ data.refhdr }}
                                </span>
                            </p>
                            <p class="pay">{{ data.clearDate }}</p>
                            <p class="pay">
                                <span class="red">{{ data.clearDate }}
                                    <ng-template #popSaveContent>
                                        <span class="popover-content">{{
                                            "invoiceCredit.past_due_date" | cxTranslate
                                            }}</span>
                                    </ng-template>
                                    <span [ngbPopover]="popSaveContent" triggers="manual" #p1="ngbPopover"
                                        placement="top" (click)="toggle(p1)" container="body"
                                        popoverClass="popover-links-invoice"
                                        *ngIf="!!data.dueDate && dateComparison(data.dueDate)">
                                        <img src="assets/icons/warning_red.png" alt="warningIcon"
                                            class="warningIconEvent" />
                                    </span>
                                </span>
                            </p>
                            <p class="pay">{{ data.amount | invoiceCurrency }}</p>
                          <p class="pay">{{ data.salesOrder }}</p>
                            <!-- <p class="pay">
                                <input type="text" [disabled]="!data.checked" [(ngModel)]="data.paymentAmount"
                                    (ngModelChange)="onInvoiceValueChange($event, i)"
                                    (focus)="onFocus(data.paymentAmount, i)"
                                    (blur)="onBlur(data.paymentAmount, i, data.amount)"
                                    class="border-radi amount-to-pay amount-text" />
                                <span class="amount-value-error" *ngIf="data.checked && data.invalidAmount">{{
                                    "invoiceCredit.invalid_amt"
                                    | cxTranslate }}</span>
                                <span class="amount-value-error" *ngIf="errorMessageStatus[i]">{{
                                    "invoiceCredit.value_greater_than_zero" | cxTranslate
                                    }}</span>
                            </p> -->
                        </section>
                    </div>
                </div>
            </section>



            <button [routerLink]="['/', 'openInvoicePayment']" [ngClass]="invoiceItems.length != 0 ? '' : 'display'"
                type="button" class="btn btn-primary float-right btnpaymentinvoicecredit"
                (click)="initiateInvoicePaymentFlow()">
                {{ "invoiceCredit.continue_button_txt" | cxTranslate }}
            </button>
        </section>

        <!--END CLOSED INVOICES DATA -->


         <!-- START CLOSED CREDITS NOTES -->
         <section *ngIf="closedCreditNotes?.length === 0 || closedCreditNotes?.length === 0" class="payment-section">
          <h3 class="no-order-tittle">
              {{ "invoiceCredit.closedCreditNotesTitle" | cxTranslate }}
          </h3>
          <section class="no-credit-invoice">
              {{ "invoiceCredit.no_closed_credits" | cxTranslate }}
          </section>
        </section>
        <section *ngIf="closedCreditNotes?.length > 0" class="credits-section">
            <section class="row">
                <section class="col-sm-12 col-md-12 col-md-12 col-xs-12 op_ovice">
                    <h3 class="oi-table-title">
                        {{ "invoiceCredit.closedCreditNotesTitle" | cxTranslate }}
                    </h3>
                </section>
            </section>
            <table aria-describedby="creditsTable" id="applyCreditsTable" class="payments-table table table-striped">
                <thead>
                    <tr>
                        <!-- <th scope="col"></th> -->
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'refDocNo', closedCreditNotes, 'normal')">
                            {{ "invoiceCredit.credit#" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'docNo', closedCreditNotes, 'normal')">
                            {{ "invoiceCredit.credit_note_date" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="col-width" (click)="sortColumn($event, 'docDate', closedCreditNotes, 'Date')">
                            {{ "invoiceCredit.date_of_consummation" | cxTranslate }}
                            <i class="my-icon fas fa-caret-up"></i>
                        </th>
                        <th scope="col" class="text-align col-width"
                            (click)="sortColumn($event, 'amount', closedCreditNotes, 'Number')">
                            {{ "invoiceCredit.credit_amount" | cxTranslate }}
                            <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <th scope="col" class="text-align col-width"
                          (click)="sortColumn($event, 'salesOrder', closedCreditNotes, 'Number')">
                        {{ "invoiceCredit.reference_invoice" | cxTranslate }}
                        <i class="my-icon fas fa-caret-down"></i>
                        </th>
                        <!-- <th scope="col" class="col-width dropdown-width">
                            {{ "invoiceCredit.apply_to_invoice" | cxTranslate }}
                        </th>
                        <th scope="col" class="text-align col-width">
                            {{ "invoiceCredit.credit_applied" | cxTranslate }}
                        </th> -->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let data of closedCreditNotes; let i = index">
                        <!-- <td class="payment-chk-mob">
                            <section>
                                <input type="checkbox" class="payment-checkbox" (change)="
                    parsePaymentType(
                      data,
                      $event.target.checked,
                      creditItems,
                      i,
                      true
                    )
                  " [(ngModel)]="data.checked" />
                            </section>
                        </td> -->
                      <td>
                          <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                            href="javascript: void(0);" class="underline" (click)="creditPdf(data.refDocNo, data.systemId)">
                              {{ data.refDocNo }}
                          </a>
                      </td>
                        <td>
                          {{ data.docDate }}
                            <!-- <span *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')">
                                {{ data.docNo }}
                            </span>
                            <span *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')">
                                {{ data.dochdrtxt }}
                            </span>
                            <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                {{ data.docNo }}
                            </span>
                            <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                {{ data.dochdrtxt }}
                            </span>
                            <span *ngIf="data.docType === 'YR'">
                                {{ data.refhdr }}
                            </span> -->
                        </td>
                        <td>
                             {{ data.clearDate }}
                        </td>
                        <td class="amount-align">-{{ data.amount | invoiceCurrency }}</td>
                        <td class="amount-align">{{ data.refDocNo }}</td>
                        <!-- <td class="sort-by-region fst-dropdown custom-selectpay pad-btm cred-dropdown">
                            <ng-select [items]="data.creditDropDown" (change)="updateInvoiceSource($event, i)"
                                [searchable]="false" [clearable]="false" bindLabel="displayValue" bindValue="refDocNo"
                                placeholder="Select" [(ngModel)]="data.selectedInvoice" class="dropdown-credit"
                                [disabled]="!data.checked">
                            </ng-select>

                            <span class="amount-value-error credits" [ngClass]="
                              data.checked &&
                              data.errorMsg &&
                              data.checkedAndNotFilledInvoice &&
                              clicked
                                ? 'visible-ui'
                                : 'invisible-ui'
                            ">{{ "invoiceCredit.credits_invoice_select_msg" | cxTranslate }}
                            </span>
                        </td>

                        <td class="text-right">{{ data?.creditsApplied | localCurrency }}</td> -->
                    </tr>
                </tbody>
            </table>

            <section class="credits-table-2column">
                <div class="row credits-row-table" *ngFor="let data of closedCreditNotes; let i = index">
                    <div class="column header-column credits-table-hcol">
                        <section class="col-header-rwd">
                            <!-- <p>Select</p> -->
                            <p>{{ "invoiceCredit.credit#" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.invoice_ref_number" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.credit_date" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.credit_amount" | cxTranslate }}</p>
                            <p>{{ "invoiceCredit.orderNumber" | cxTranslate }}</p>
                            <!-- <p class="applyinvoice-spacing-rwd">
                                {{ "invoiceCredit.apply_to_invoice" | cxTranslate }}
                            </p>
                            <p>
                                {{ "invoiceCredit.credit_applied" | cxTranslate }}
                            </p> -->
                        </section>
                    </div>
                    <div class="column desc-column">
                        <section>
                            <!-- <label for="selected_credits" class="selectionBox">
                                <input type="checkbox" class="payment-checkbox"
                                    (change)="parsePaymentType(data,$event.target.checked, creditItems,i,true )"
                                    [(ngModel)]="data.checked" />
                            </label> -->
                            <p style="margin-top: 22px;">
                                <a *ngIf="(data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF')"
                                  href="javascript: void(0);" class="underline" (click)="creditPdf(data.docNo, data.systemId)">
                                    {{ data.refDocNo }}
                                </a>
                            </p>
                            <p>
                                <span *ngIf="data.docType === 'RV' || data.docType === 'RD' || data?.docType === 'RF'">
                                    {{ data.docNo }}
                                </span>
                                <span *ngIf="data.docType === 'DZ' && data.dochdrtxt.startsWith('7')">
                                    {{ data.dochdrtxt }}
                                </span>
                                <span *ngIf="data.docType === 'DR' || data.docType === 'DG' || data?.docType === 'DY' || data?.docType === '$D'">
                                    {{ data.docNo }}
                                </span>
                                <span *ngIf="data.docType === 'DZ' && !data.dochdrtxt.startsWith('7')">
                                    {{ data.dochdrtxt }}
                                </span>
                                <span *ngIf="data.docType === 'YR'">
                                    {{ data.refhdr }}
                                </span>
                            </p>
                            <p>{{ data.docDate }}</p>
                            <p>-{{ data.amount | invoiceCurrency }}</p>
                            <p>{{ data.salesOrder }}</p>
                            <!-- <p class="cred-dropdown-mob">
                                <ng-select [items]="data.creditDropDown" (change)="updateInvoiceSource($event, i)"
                                    [searchable]="false" [clearable]="false" bindLabel="displayValue" bindValue="docNo"
                                    placeholder="Select" [(ngModel)]="data.selectedInvoice" class="dropdown-credit"
                                    [disabled]="!data.checked"></ng-select>

                                <span class="amount-value-error credits" [ngClass]="
                                      data.checked &&
                                      data.errorMsg &&
                                      data.checkedAndNotFilledInvoice &&
                                      clicked
                                        ? 'visible-ui'
                                        : 'invisible-ui'
                                    ">{{ "invoiceCredit.credits_invoice_select_msg" | cxTranslate }}</span>
                            </p> -->

                            <!-- <p>{{ data?.creditsApplied | localCurrency }}</p> -->
                        </section>
                    </div>
                </div>
            </section>

            <button [ngClass]="
          creditItems.length != 0 && !creditItems[0].errorMsg ? '' : 'display'
        " type="button" [disabled]="!isValid" class="btn btn-primary float-right btnpaymentinvoicecredit"
                (click)="initiateCreditPaymentFlow()">
                {{ "invoiceCredit.continue_button_txt" | cxTranslate }}
            </button>
        </section>

        <!-- END CLOSED CREDITS NOTES -->



    </section>

    <div class="line-intersection mobile-view"></div>
    <!-- <section class="mobile-view">
      <div>
        <app-need-to-go-back></app-need-to-go-back>
      </div>
      <div class="line-intersection"></div>
    </section> -->
    <section class="mobile-view">
      <div class="banner-img">
        <app-flu-dashboard-banner
          bannerComponent="OrderHistoryBannerComponent"
        ></app-flu-dashboard-banner>
      </div>
    </section>
</section>
