<section class="row">
  <section class="container sub-Total-Section">
    <section class="row">
      <section class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
        <span class="shipTo-Header">{{
          "addToCartItems.orderSubtotal" | cxTranslate
          }}</span>
      </section>
      <section class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6 text-right">
        <p>
          {{ orderDetails?.subTotal?.value | localCurrency }}
        </p>
      </section>
    </section>
  </section>
  <section class="container sub-Total-Section">
    <!-- <div class="row" *ngIf="standardCartDetails?.totalTax?.value > 0">
      <div class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
        <span class="">{{"addToCartItems.salesTax" | cxTranslate}}</span>
      </div>
      <div class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6 text-right">
        <p>
          {{ standardCartDetails?.totalTax?.value | localCurrency }}
        </p>
      </div>
    </div> -->
    <div class="row">
      <!-- Tax needs to be changed with correct field *ngIf="standardCartDetails?.totalPriceWithTax != null && standardCartDetails?.totalPriceWithTax?.value != 0" -->
      <ng-container *ngFor="let tax of orderDetails?.taxValues">
        <div *ngIf="orderDetails?.cartType === 'INFLUENZA' && orderDetails?.totalTax?.value !== 0 
        && orderDetails?.totalTax?.value !== undefined && tax?.value.value !== 0"
          class="col-8 col-sm-8 col-md-6 col-xl-8 col-lg-6">
          <p>
            {{ "checkout.tax" | cxTranslate}} ({{tax?.percentage}}%)
          </p>
        </div>
        <div *ngIf="orderDetails?.cartType === 'INFLUENZA' && orderDetails?.totalTax?.value !== 0 
        && orderDetails?.totalTax?.value !== undefined && tax?.value.value !== 0"
          class="col-4 col-sm-4 col-md-6 col-xl-4 col-lg-6 text-right">
          <p>
            {{tax?.value.value | localCurrency}}
          </p>
        </div>
      </ng-container>
    </div>
    <div class="row" *ngIf="orderDetails?.deliveryCost != null && orderDetails?.deliveryCost?.value != 0">
      <!-- Freight -->
      <div class="col-8 col-sm-8 col-md-6 col-xl-8 col-lg-6">
        <p>
          {{ "checkout.freight" | cxTranslate}}
        </p>
      </div>
      <div class="col-4 col-sm-4 col-md-6 col-xl-4 col-lg-6 text-right">
        <p>
          {{orderDetails?.deliveryCost?.value | localCurrency}}
        </p>
      </div>
    </div>
    <div class="row total-Cost"><!--Total-->
      <div class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
        <span class="shipTo-Header total-Amount-Span">
          {{"addToCartItems.total" | cxTranslate }}
        </span>
      </div>
      <div class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6 text-right">
        <p>
          {{orderDetails?.totalPriceWithTax?.value | localCurrency}}
        </p>
      </div>
    </div>
  </section>
</section>