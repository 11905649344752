import { Injectable } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import { WindowReferenceService } from './window-reference.service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private window;
  userSub: Subscription;

  constructor(
    private _windowRef: WindowReferenceService,
    private user: UserAccountFacade
  ) {
    this.window = this._windowRef.nativeWindow;
  }

  private pushData(obj) {
    if (obj) {
      this.window.dataLayer.push({ ecommerce: null });
      this.window.dataLayer.push(obj);
    }
  }

  // step 1
  viewListItemsDataLayer(data, type): void {
    if (data) {
      let entryItems = [];
      data?.products.forEach((entry) => {
        const entryItem = {
          item_id: entry?.code,
          item_name: entry?.name,
          affiliation: '<type of funnel>',
          coupon: '<coupon>',
          currency: entry?.price?.currencyIso,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_list_id: '<item_list_id>',
          item_list_name: '<item_list_name>',
          item_variant: '<item variant>',
          price: entry?.price?.value,
          quantity: '<quantity>',
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_item_list',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  //Step 1 - button submit
  addCartDataLayer(data, type, quantity, page): void {
    let entryItems = [];
    if (data.childCarts && type == 'new reservation') {
      data.childCarts[0].entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      let items = {
        event: 'add_to_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        add_to_cart_type: page,
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    } else {
      let quant;
      if (quantity != null) {
        quant = parseInt(quantity);
      } else {
        quant = data?.entry?.quantity;
      }
      const entryItem = {
        item_id: data?.entry?.product?.code,
        item_name: data?.entry?.product?.name,
        affiliation: '<type of funnel>',
        currency: data?.entry?.basePrice?.currencyIso,
        discount: '<discount value>',
        index: data?.entry?.entryNumber,
        item_brand: '<item brand>',
        item_category: data?.entry?.product?.categories[0].name,
        item_category2: '<item category 2>',
        item_variant: '<item variant>',
        price: data?.entry?.basePrice?.value,
        quantity: quant,
      };
      entryItems.push(entryItem);
      let items = {
        event: 'add_to_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        add_to_cart_type: page,
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  addReservationCartDataLayer(data, type, quantity, page): void {
    let entryItems = [];
    data.childCarts[0].entries.forEach((entry) => {
      const entryItem = {
        item_id: entry?.product?.code,
        item_name: entry?.product?.name,
        affiliation: '<type of funnel>',
        currency: entry?.basePrice?.currencyIso,
        discount: '<discount value>',
        index: entry?.entryNumber,
        item_brand: '<item brand>',
        item_category: entry?.product?.categories[0].name,
        item_category2: '<item category 2>',
        item_variant: '<item variant>',
        price: entry?.basePrice?.value,
        quantity: entry?.quantity,
      };
      entryItems.push(entryItem);
    });
    let items = {
      event: 'add_to_cart',
      interaction_type: 'ecommerce',
      process_type: type,
      add_to_cart_type: page,
      ecommerce: {
        items: entryItems,
      },
    };
    this.pushData(items);
  }

  // After reordering an order
  addUpdateCartDataLayer(data, type, quantity, page): void {
    let entryItems = [];
    if (data && type != 'new reservation') {
      data[0].entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      let items = {
        event: 'add_to_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        add_to_cart_type: page,
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  //Step 2
  viewCartDataLayer(data, type): void {
    if (data) {
      let entryItems = [];
      data[0]?.entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          currency: data[0]?.totalPrice?.currencyIso,
          value: data[0]?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  //For updating quantinty in the cart page
  viewCartUpdateQuantityDataLayer(data, type): void {
    if (data) {
      let entryItems = [];
      data?.entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          currency: data?.totalPrice?.currencyIso,
          value: data?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  viewUpdateCartDataLayer(data, type): void {
    if (data) {
      let entryItems = [];
      data?.carts[0]?.childCarts[0]?.entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'view_cart',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          currency: data?.carts[0]?.childCarts[0]?.totalPrice?.currencyIso,
          value: data?.carts[0]?.childCarts[0]?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  //Step 3
  shippingInfo(data, processType): void {
    if (data) {
      let entryItems = [];
      data[0]?.entries?.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.totalPrice.currencyIso,
          discount: '<discount value>',
          index: '<product position>',
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'add_shipping_info',
        interaction_type: 'ecommerce',
        process_type: processType,
        location_id: '<location_id>',
        multiple_locations: '<multiple_locations>',
        locations_number: '<locations_number>',
        ecommerce: {
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  //Step 4
  beginCheckoutDataLayer(data, processType): void {
    if (data) {
      let entryItems = [];
      data[0]?.entries?.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          coupon: '<coupon>',
          currency: entry?.basePrice?.currencyIso,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'begin_checkout',
        interaction_type: 'ecommerce',
        process_type: processType,
        location_id: '<location_id>',
        multiple_locations: '<multiple_locations>',
        locations_number: '<locations_number>',
        ecommerce: {
          currency: data[0]?.totalPrice?.currencyIso,
          value: data[0]?.totalPrice?.value,
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  //After checkout is successfull
  purchaseDataLayer(data, type): void {
    if (data) {
      let entryItems = [];
      data?.entries.forEach((entry) => {
        const entryItem = {
          item_id: entry?.product?.code,
          item_name: entry?.product?.name,
          affiliation: '<type of funnel>',
          currency: entry?.basePrice?.currencyIso,
          discount: '<discount value>',
          index: entry?.entryNumber,
          item_brand: '<item brand>',
          item_category: entry?.product?.categories[0].name,
          item_category2: '<item category 2>',
          item_variant: '<item variant>',
          price: entry?.basePrice?.value,
          quantity: entry?.quantity,
        };
        entryItems.push(entryItem);
      });
      const items = {
        event: 'purchase',
        interaction_type: 'ecommerce',
        process_type: type,
        location_id: '<location_id>',
        multiple_locations: '<multiple_locations>', // yes or no>,
        locations_number: '<locations_number>',
        ecommerce: {
          transaction_id: data?.code,
          transaction_type: data?.paymentType.code,
          transaction_update: data?.status,
          affiliation: '<type of funnel>',
          value: data?.totalPrice?.value,
          tax: data?.totalTax?.value,
          shipping: data?.deliveryCost?.value,
          currency: data?.totalTax?.currencyIso,
          coupon: '<type of applied discount>',
          items: entryItems,
        },
      };
      this.pushData(items);
    }
  }

  // opening pdp modal`
  viewItemDataLayer(data, type): void {
    let cat;
    data?.categories
      ? (cat = data.categories[0].name)
      : (cat = '<item category 1>');
    if (data) {
      const items = {
        event: 'view_item',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          items: [
            {
              item_id: data?.code,
              item_name: data?.name,
              affiliation: '<type of funnel>',
              coupon: '<coupon>',
              currency: data?.price?.currencyIso,
              item_brand: '<item brand>',
              item_category: cat,
              item_category2: '<item category 2>',
              item_variant: '<item variant>',
              price: data?.price?.value,
              quantity: '<quantity>',
            },
          ],
        },
      };
      this.pushData(items);
    }
  }

  selectItemDataLayer(data, type): void {
    let cat;
    data?.categories
      ? (cat = data.categories[0].name)
      : (cat = '<item category 1>');
    if (data) {
      const items = {
        event: 'select_item',
        interaction_type: 'ecommerce',
        process_type: type,
        ecommerce: {
          items: [
            {
              item_id: data?.code,
              item_name: data?.name,
              affiliation: '<type of funnel>',
              coupon: '<coupon>',
              currency: data?.price?.currencyIso,
              item_brand: '<item brand>',
              item_category: cat,
              item_category2: '<item category 2>',
              item_variant: '<item variant>',
              price: data?.price?.value,
              quantity: '<quantity>',
            },
          ],
        },
      };
      this.pushData(items);
    }
  }

  // clicking download button in resources plp item
  downloadDataLayer(data): void {
    if (data) {
      const items = {
        event: 'file_download',
        name: data?.localizedDownloadedMedia.name,
        interaction_type: 'download',
        document_type: data?.downloadedMediaType,
        document_format: data?.downloadedMediaType,
      };
      this.pushData(items);
    }
  }

  // after successfully logging in
  loginDataLayer(): void {
    this.userSub = this.user.get().subscribe((res) => {
      if (res !== undefined) {
        const userInfo: any = res;
        let userId;
        if (userInfo?.akamaiId) {
          userId = userInfo?.akamaiId;
        } else {
          userId = userInfo?.uid;
        }
        const items = {
          event: 'login',
          interaction_type: 'authentication',
          user_id: userId,
        };
        this.pushData(items);
        this.userSub.unsubscribe();
      }
    });
  }

  // after successfull registration
  registrationDataLayer(): void {
    this.userSub = this.user.get().subscribe((res) => {
      if (res !== undefined) {
        const userInfo: any = res;
        let userId;
        if (userInfo?.akamaiId) {
          userId = userInfo?.akamaiId;
        } else {
          userId = userInfo?.uid;
        }
        const items = {
          event: 'sign_up',
          interaction_type: 'authentication',
          user_id: userId,
        };
        this.pushData(items);
        this.userSub.unsubscribe();
      }
    });
  }
}
